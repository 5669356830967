import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  ActionSheet,
  Button,
  Dialog,
  Ellipsis,
  ImageViewer,
  Input,
  List,
  Popover,
  TextArea,
  Toast,
} from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import {
  IActionSheet,
  IApiResult,
  IOverlay,
} from '../../interfaces/app.interface';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { removePopupStore } from '../../stores/popup.store';
import * as appUtil from '../../utils/app.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as nstlApi from '../../apis/nstl.api';
import { FileButton } from '@mantine/core';
import { nanoid } from 'nanoid';
import {
  deleteNstlPrgrNstlPrgrId,
  postNstlPrgrNstlPrgrId,
} from '../../apis/nstl.api';
import { overlayStore } from '../../stores/overlay.store';

interface IPopupProps {
  id: string;
  dcmnDntfNmbr: string;
  splrId?: string;
  teamId?: string;
  commentData?: any | null;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 댓글 수정
 * @param data 구분, 내용(신규일 때는 null)
 * @param splrId 협력사 아이디
 * @param teamId 협력사(팀) 아이디
 * @param onClick <이벤트>
 * @constructor
 */
const ModifyComment = ({
  id,
  dcmnDntfNmbr = '',
  splrId = '',
  teamId = '',
  commentData,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 화면 위 검은 화면 저장소를 정의함
  const [overlay, setOverlay] = useRecoilState<IOverlay>(overlayStore);

  // 내용 입력을 정의함
  const [contentInput, setContentInput] = useState<string>('');
  const contentInputRef = useRef<any>(null);

  // 첨부파일을 정의함
  const [files, setFiles] = useState<{ id: string; new: boolean; file: any }[]>(
    [],
  );

  // 첨부파일 팝오버 메뉴를 정의함
  const filePopoverMenu: { key: string; icon: any; text: string }[] = [
    {
      key: 'remove',
      text: '삭제',
      icon: (
        <div className="flex justify-center items-center">
          <FontAwesomeIcon
            icon={['fas', 'trash']}
            className="w-4 h-4 text-gray-400"
          />
        </div>
      ),
    },
  ];

  // 파일이 이미지인지 확인함
  const isImageFile = (fileName: string): boolean => {
    // 이미지 파일의 확장자를 정의함
    let tmpImageFileExt: string[] = [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.bmp',
      '.tif',
    ];

    // 파일명에서 확장자를 추출함
    let tmpFileExt: string = _.padEnd(fileName, 5);

    // 파일의 확장자가 이미지인지 정의함
    let result: boolean = false;

    // 파일의 확장자가 이미지인지 확인함
    tmpImageFileExt.map((item: string) => {
      if (_.includes(tmpFileExt, item)) {
        result = true;
      }
    });

    return result;
  };

  // 내용 입력을 변경함
  const handleContentInput_onChange = (event: any) => {
    setContentInput(event);
  };

  // 첨부파일 팝오버 메뉴를 클릭함
  const handleFilePopoverMenu_onAction = (node: any, event: any) => {
    let tmpFiles: { id: string; new: boolean; file: any }[] = _.cloneDeep(
      files.filter((item: any) => item.id !== event.id),
    );

    // 첨부파일에 적용함
    setFiles(tmpFiles);

    Toast.show({
      position: 'bottom',
      content: '삭제하였습니다.',
    });
  };

  // 첨부파일 이미지를 클릭함
  const handleFileImage_onClick = (event: any) => {
    let tmpFiles: { id: string; new: boolean; file: any }[] = _.cloneDeep(
      files.filter((item: any) => item.id !== event.id),
    );

    // 첨부파일에 적용함
    setFiles(tmpFiles);

    Toast.show({
      position: 'bottom',
      content: '삭제하였습니다.',
    });
  };

  // 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    if (contentInput.trim() === '') {
      Toast.show({
        position: 'bottom',
        content: '내용을 입력하세요.',
      });

      contentInputRef.current.focus();
      return;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 정보를 저장함
    files
      .filter((item: any) => item.new === false)
      .map((item: any) => {
        tmpPreFileId.push(item.id);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 정보를 저장함
    files
      .filter((item: any) => item.new === true)
      .map((item: any) => {
        tmpNewFile.push(item.file);
      });

    Dialog.confirm({
      content: '댓글을 저장하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위의 검은 화면 출력 여부에 적용함
        setOverlay((pre: IOverlay) => ({
          ...pre,
          visible: true,
        }));

        if (commentData === null) {
          // 신규
          nstlApi
            .postNstlPrgr({
              dcmnDntfNmbr: dcmnDntfNmbr,
              splrId: splrId,
              teamId: teamId,
              dtl: contentInput,
              fileList: tmpNewFile,
            })
            .then((data: IApiResult) => {
              // 화면 위의 검은 화면 출력 여부에 적용함
              setOverlay((pre: IOverlay) => ({
                ...pre,
                visible: false,
              }));

              if (data.code === '200') {
                Toast.show({
                  position: 'bottom',
                  content: '저장하였습니다.',
                });

                // 부모창으로 결과를 넘김
                callback({
                  resultType: 'refresh',
                });

                // 삭제할 팝업 저장소에 적용함
                setRemovePopup(id);
              } else {
                Toast.show({
                  position: 'bottom',
                  content: '저장에 실패하였습니다.',
                });
              }
            })
            .catch((error: any) => {
              // 화면 위의 검은 화면 출력 여부에 적용함
              setOverlay((pre: IOverlay) => ({
                ...pre,
                visible: false,
              }));

              Toast.show({
                position: 'bottom',
                content: '저장에 실패하였습니다.',
              });
            });
        } else {
          // 수정
          nstlApi
            .postNstlPrgrNstlPrgrId({
              dcmnDntfNmbr: dcmnDntfNmbr,
              splrId: splrId,
              teamId: teamId,
              nstlPrgrId: commentData.nstlPrgrId,
              dtl: contentInput,
              bfFileIdList: tmpPreFileId,
              fileList: tmpNewFile,
            })
            .then((data: IApiResult) => {
              // 화면 위의 검은 화면 출력 여부에 적용함
              setOverlay((pre: IOverlay) => ({
                ...pre,
                visible: false,
              }));

              if (data.code === '200') {
                Toast.show({
                  position: 'bottom',
                  content: '저장하였습니다.',
                });

                // 부모창으로 결과를 넘김
                callback({
                  resultType: 'refresh',
                });

                // 삭제할 팝업 저장소에 적용함
                setRemovePopup(id);
              } else {
                Toast.show({
                  position: 'bottom',
                  content: '저장에 실패하였습니다.',
                });
              }
            })
            .catch((error: any) => {
              // 화면 위의 검은 화면 출력 여부에 적용함
              setOverlay((pre: IOverlay) => ({
                ...pre,
                visible: false,
              }));

              Toast.show({
                position: 'bottom',
                content: '저장에 실패하였습니다.',
              });
            });
        }
      },
    });
  };

  // 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    Dialog.confirm({
      content: '댓글을 삭제하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        nstlApi
          .deleteNstlPrgrNstlPrgrId({
            nstlPrgrId: commentData.nstlPrgrId,
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              Toast.show({
                position: 'bottom',
                content: '삭제하였습니다.',
              });

              // 부모창으로 결과를 넘김
              callback({
                resultType: 'remove',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '삭제에 실패하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            Toast.show({
              position: 'bottom',
              content: '삭제에 실패하였습니다.',
            });
          });
      },
    });
  };

  // 첨부파일 추가 버튼을 클릭함
  const handleAddFileButton_onClick = (event: any) => {
    if (event === null) {
      return;
    }

    let tmpFiles: { id: string; new: boolean; file: any }[] =
      _.cloneDeep(files);

    event.map((item: any) => {
      tmpFiles.push({
        id: nanoid(),
        new: true,
        file: item,
      });
    });

    // 첨부파일에 적용함
    setFiles(tmpFiles);
  };

  useEffect(() => {
    // 수정일 경우에 불러온 기본값을 적용함
    if (commentData !== null) {
      // 내용 입력에 적용함
      setContentInput(commentData.dtl);

      // 첨부파일을 정의함
      let tmpFiles: { id: string; new: boolean; file: any }[] = [];

      // 기존 첨부파일을 불러옴
      commentData.fileList.map((item: any) => {
        tmpFiles.push({
          id: item.fileId,
          new: false,
          file: item,
        });
      });

      // 첨부파일에 적용함
      setFiles(tmpFiles);
    }

    return () => {};
  }, []);

  useEffect(() => {
    console.log('> files:', files);

    return () => {};
  }, [files]);

  return (
    <div className="">
      {/* 편집 리스트 */}
      <List header="편집 정보" className="bg-gray-100/90">
        <List.Item title="내용">
          {/* 텍스트 에어리어 입력 */}
          <TextArea
            ref={contentInputRef}
            placeholder="내용을 입력하세요."
            value={contentInput}
            onChange={handleContentInput_onChange}
            rows={3}
          />
        </List.Item>
        <List.Item title="첨부파일">
          <div className="flex flex-wrap gap-1">
            {files.map(
              (
                item: { id: string; new: boolean; file: any },
                index: number,
              ) => {
                if (
                  (item.new === true && isImageFile(item.file.name) === true) ||
                  (item.new === false &&
                    isImageFile(item.file.rgnlFileName) === true)
                ) {
                  // 이미지
                  return (
                    // <Popover.Menu
                    //   key={index}
                    //   actions={filePopoverMenu}
                    //   mode="dark"
                    //   onAction={(node: any) =>
                    //     handleFilePopoverMenu_onAction(node, item)
                    //   }
                    //   trigger="click"
                    // >
                    <div
                      onClick={() => {
                        Dialog.confirm({
                          content: '이 첨부파일을 삭제하시겠습니까?',
                          confirmText: '예',
                          cancelText: '아니오',
                          onConfirm: () => {
                            handleFileImage_onClick(item);
                          },
                        });
                      }}
                      className="button-event border rounded-md overflow-hidden"
                    >
                      {item.new === true ? (
                        <img
                          src={URL.createObjectURL(item.file)}
                          className="w-16 h-16"
                        />
                      ) : (
                        <img
                          key={index}
                          src={[
                            appUtil.getThumbnailImageUrl(),
                            item.file.pldFileName,
                          ].join('')}
                          className="w-16 h-16"
                        />
                      )}
                    </div>
                    // </Popover.Menu>
                  );
                } else {
                  // 기타
                  return (
                    // <Popover.Menu
                    //   key={index}
                    //   actions={filePopoverMenu}
                    //   mode="dark"
                    //   onAction={(node: any) =>
                    //     handleFilePopoverMenu_onAction(node, item)
                    //   }
                    //   trigger="click"
                    // >
                    <div
                      onClick={() => {
                        Dialog.confirm({
                          content: '이 첨부파일을 삭제하시겠습니까?',
                          confirmText: '예',
                          cancelText: '아니오',
                          onConfirm: () => {
                            handleFileImage_onClick(item);
                          },
                        });
                      }}
                      className="button-event border rounded-md overflow-hidden"
                    >
                      <div className="p-1 flex justify-center items-center w-16 h-16">
                        <span className="text-xs text-gray-400">
                          {item.new === true ? (
                            <Ellipsis
                              direction="end"
                              content={item.file.name}
                            />
                          ) : (
                            <Ellipsis
                              direction="end"
                              content={item.file.rgnlFileName}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    // </Popover.Menu>
                  );
                }
              },
            )}

            {/* 첨부파일 선택 버튼 */}
            <FileButton onChange={handleAddFileButton_onClick} multiple={true}>
              {(props) => (
                <div
                  {...props}
                  className="button-event flex justify-center items-center w-16 h-16 bg-gray-100 border border-dashed border-gray-300 rounded-md overflow-hidden"
                >
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'plus']}
                      className="w-4 h-4 text-gray-400"
                    />
                  </div>
                </div>
              )}
            </FileButton>
          </div>
        </List.Item>
      </List>

      <div className="p-3 space-y-2">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleSaveButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">저장</span>
        </Button>

        {/* 버튼 */}
        {commentData !== null && (
          <Button
            size="large"
            shape="default"
            onClick={handleRemoveButton_onClick}
            className="w-full !bg-rose-700"
          >
            <span className="text-md text-white">삭제</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModifyComment;
