import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  ILoginUser,
  IOverlay,
  IPopup,
} from '../../interfaces/app.interface';
import { addPopupStore, removePopupStore } from '../../stores/popup.store';
import { overlayStore } from '../../stores/overlay.store';
import * as nstlApi from '../../apis/nstl.api';
import {
  Button,
  Collapse,
  Dialog,
  Ellipsis,
  Form,
  Input,
  List,
  Radio,
  Stepper,
  Tabs,
  TextArea,
  Toast,
} from 'antd-mobile';
import prettyBytes from 'pretty-bytes';
import _ from 'lodash';
import { FileButton } from '@mantine/core';
import { loginStore } from '../../stores/login.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MoreList } from '../../components/MoreList';
import { decode } from 'html-entities';
import { PopupBottomButtonArea } from '../../components/PopupBottomButtonArea';
import { PopupTabsBottomButtonArea } from '../../components/PopupTabsBottomButtonArea';
import { nanoid } from 'nanoid';
import { PerformanceRegistrationModifyComment } from '../PerformanceRegistrationModifyContent';
import FileDownloadList from '../../components/FileDownloadList/FileDownloadList.component';
import ImageFilePreviewList from '../../components/ImageFilePreviewList/ImageFilePreviewList.component';
import PerformanceRegistrationStockMovement from '../PerformanceRegistrationStockMovement/PerformanceRegistrationStockMovement.popup';
import { StickyContentHeader } from '../../components/StickyContentHeader';
import * as appUtil from '../../utils/app.util';
import * as workApi from '../../apis/work.api';
import {
  getSetupReportExcel,
  getSttDcmnDntfNmbrCmpnCode,
  getSttDtlAllDcmnDntfNmbr,
  postDeliveryCancelDcmnDntfNmbr,
  postDeliveryCompleteDcmnDntfNmbr,
  postPmNspcRgst,
  putPmNspcCancelDcmnDntfNmbr,
  putWorkEslNvntRqstDcmnDntfNmbr,
  putWorkNtwrDcmnDntfNmbr,
} from '../../apis/work.api';
import moment from 'moment';
import { scrollToPopupTop } from '../../utils/app.util';
import * as cmnApi from '../../apis/cmn.api';
import {
  getChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId,
  putChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId,
} from '../../apis/nstl.api';

interface IPopupProps {
  id: string;
  integrateData?: boolean;
  data?: any;
  selectedData: any;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 설치관리 > 실적등록 > 상세
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PerformanceRegistrationDetail = ({
  id,
  integrateData = false,
  data,
  selectedData,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 화면 위 검은 화면 저장소를 정의함
  const [overlay, setOverlay] = useRecoilState<IOverlay>(overlayStore);

  // 브라우저 저장소 아이디를 정의함
  const localStorageIdref = useRef<string>('');

  // 선택한 탭을 정의함
  const [selectedTab, setSelectedTab] = useState<string>('work-history');

  // ESL 설치 품목 동적 폼을 정의함
  const [dynamicWorkEslVoList, setDynamicWorkEslVoList] = useState<any>({});

  // 네트워크 설치 정보 동적 폼을 정의함
  const [dynamicWorkNtwrVoList, setDynamicWorkNtwrVoList] = useState<any>({});

  // 재고이동 요청 동적 폼을 정의함
  const [dynamicStockMovementVoList, setDynamicStockMovementVoList] =
    useState<any>({});

  // 일반 첨부파일 동적 폼을 정의함
  const [dynamicNormalComment, setDynamicNormalComment] = useState<any>({});

  // 설치 확인서 동적 폼을 정의함
  const [dynamicInstallComment, setDynamicInstallComment] = useState<any>({});

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 설치일정 정보를 정의함
  const [networkInstallDate, setNetworkInstallDate] = useState<any>(null);

  // 첨부파일 목록을 정의함
  const [fileList, setFileList] = useState<any>([]);

  // 사전점검결과 목록을 정의함
  const [checkListResult, setCheckListResult] = useState<any>([]);

  // 결과등록 탭 > 소프트웨어 > 소프트웨어 버전 입력을 정의함
  const [softwareVersionInput, setSoftwareVersionInput] = useState<string>('');

  // 결과등록 탭 > 소프트웨어 > 소프트웨어 버전 입력을 정의함
  const [dueDateInput, setDueDateInput] = useState<string>('');

  // 브라우저 저장소의 임시 저장 정보를 정의함
  const loadedLocalStorageRef = useRef<any>(null);

  // 브라우저 저장소의 설치 품목 동적 폼의 품목 개수를 정의함
  const loadedLocalStorageDynamicWorkEslVoListLengthRef = useRef<number>(0);

  // 작업 상태를 정의함
  const workState: any[] = useMemo(() => {
    return [
      {
        value: 'PRCD',
        iconTitle: '작업대기',
        backgroundColor: 'bg-orange-500',
        borderColor: 'border-orange-500',
      },
      {
        value: 'WORK_CMPL',
        iconTitle: '작업완료',
        backgroundColor: 'bg-lime-600',
        borderColor: 'border-lime-600',
      },
      {
        value: 'NSPC_CMPL',
        iconTitle: '검수완료',
        backgroundColor: 'bg-indigo-500',
        borderColor: 'border-indigo-500',
      },
      {
        value: 'PM_NSPC_CMPL',
        iconTitle: 'PM검수완료',
        backgroundColor: 'bg-gray-600',
        borderColor: 'border-gray-600',
      },
    ];
  }, []);

  // 상세 내용을 불러옴
  const getDetailData = () => {
    let tmpParam: any = {};

    // 화면에 따라서 조회 인자 방식을 다르게 함
    switch (integrateData) {
      // 실적등록 화면
      case false:
        tmpParam = {
          dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
          splrId: selectedData.splrId || '',
          teamId: selectedData.teamId || '',
        };
        break;

      // PM검수 화면
      case true:
        tmpParam = {
          dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
          splrId: 'ALL',
          teamId: 'ALL',
          nstlSttsCode: 'PM_NSPC_CMPL',
        };
        break;

      default:
        break;
    }

    workApi.getSttDtlAllDcmnDntfNmbr(tmpParam).then((data: IApiResult) => {
      if (data.data !== undefined) {
        console.log('> detail:', data.data);

        // 작업내역의 네트워크 작업 정보에 값이 없을 경우, 부모창의 목록에서 선택한 협력사/팀 정보로 기본값을 적용함
        if (data.data.workNtwrVoList.length === 0) {
          data.data.workNtwrVoList = [
            {
              splrId: selectedData.splrId || '',
              teamId: selectedData.teamId || '',
              teamName: selectedData.teamName || '',
            },
          ];
        }

        // 상세 내용에 적용함
        setDetailData(data.data);

        // 설치일정 정보에 적용함
        setNetworkInstallDate(
          data.data?.workDtlPrtnVoList?.filter(
            (filterItem: any) => filterItem.splrTypeCode === 'NTWR_CNST',
          )[0],
        );
      }
    });
  };

  // 작업실적 탭 > 일반 첨부파일 > 양식 다운로드 버튼을 클릭함
  const handleExcelDownloadButton_onClick = (event: any) => {
    workApi
      .getSetupReportExcel({
        dcmnDntfNmbr: selectedData.dcmnDntfNmbr,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ESL_설치확인서_엑셀양식.xlsx');
        document.body.appendChild(link);
        link.click();
      });
  };

  // 작업실적 탭 > 일반 첨부파일 > 작성 버튼을 클릭함
  const handleModifyNormalCommentButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '일반 첨부파일 등록',
      content: (
        <PerformanceRegistrationModifyComment
          id={tmpId}
          data={dynamicNormalComment}
          dcmnDntfNmbr={selectedData.dcmnDntfNmbr}
          splrId={selectedData.splrId}
          teamId={selectedData.teamId}
          callback={(result: any) => {
            // 일반 첨부파일 동적 폼에 적용함
            setDynamicNormalComment(result);
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 작업실적 탭 > 설치 확인서 > 작성 버튼을 클릭함
  const handleModifyInstallCommentButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '설치 확인서 등록',
      content: (
        <PerformanceRegistrationModifyComment
          id={tmpId}
          data={dynamicInstallComment}
          callback={(result: any) => {
            // 설치 확인서 동적 폼에 적용함
            setDynamicInstallComment(result);
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 작업실적 탭 > 재고이동 요청 버튼을 클릭함
  const handleInventoryTransferRequestButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '재고이동 요청',
      content: (
        <PerformanceRegistrationStockMovement
          id={tmpId}
          data={dynamicStockMovementVoList}
          callback={(result: any) => {
            // 재고이동 요청 동적 폼에 적용함
            setDynamicStockMovementVoList(result);
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 작업실적 탭 > 사전점검저장 버튼을 클릭함
  const handleCheckListSaveButton_onClick = () => {
    if (checkListResult.length === 0) {
      return;
    }

    let tmpCheckListResult: any = _.cloneDeep(checkListResult[0]);

    tmpCheckListResult.checkList.map((item: any) => {
      delete item.cntn;
      delete item.sortRdr;
    });

    // // 설치 정보를 불러옴
    // let tmpWorkDtlPrtnVoList = detailData?.workDtlPrtnVoList?.filter(
    //   (filterItem: any) =>
    //     filterItem.splrTypeCode === selectedData.splrTypeCode &&
    //     filterItem.splrId === selectedData.splrId &&
    //     filterItem.teamId === selectedData.teamId,
    // )[0];

    Dialog.confirm({
      title: '사전점검 항목을 저장하시겠습니까?',
      // content: '',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        nstlApi
          .putChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId({
            cntrNmbr: selectedData.cntrNmbr,
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr,
            splrTypeCode: selectedData.splrTypeCode,
            prtnDate: moment(tmpCheckListResult.team.workStrtDttm).format(
              'YYYYMMDD',
            ),
            splrId: selectedData.splrId,
            teamId: selectedData.teamId,
            resultRegistVOs: tmpCheckListResult.checkList,
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              Toast.show({
                position: 'bottom',
                content: '사전점검 항목을 저장하였습니다.',
              });

              // // 삭제할 팝업 저장소에 적용함
              // setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 사전점검저장 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          });
      },
    });
  };

  // 작업실적 탭 > 임시저장 버튼을 클릭함
  const handleTempSaveButton_onClick = () => {
    // 임시로 저장할 정보를 생성함
    let tmpData: any = {
      workEslVoList: dynamicWorkEslVoList,
      workNtwrVoList: dynamicWorkNtwrVoList,
      stockMovementVoList: dynamicStockMovementVoList,
      normalComment: dynamicNormalComment,
      installComment: dynamicInstallComment,
    };

    // 브라우저 저장소에 저장함
    localStorage.setItem(
      localStorageIdref.current,
      JSON.stringify(tmpData) || '',
    );

    Toast.show({
      position: 'bottom',
      content: '임시 저장하였습니다.',
    });
  };

  // 결과등록 탭 > 소프트웨어 > 소프트웨어 버전 입력을 변경함
  const handleSoftwareVersionInput_onChange = (event: any) => {
    setSoftwareVersionInput(event);
  };

  // 결과등록 탭 > 소프트웨어 > 소프트웨어 버전 입력을 변경함
  const handleDueDateInput_onChange = (event: any) => {
    setDueDateInput(event);
  };

  // ESL 설치 품목 수량을 배열로 변환함
  const getDynamicWorkEslVoListToArray = (): any[] => {
    let result: any[] = [];

    detailData?.workEslVoList?.map((item: any, index: number) => {
      // 품목의 수량을 생성함
      result.push({
        itemNmbr: item.itemNmbr,
        rcvdQntt: +(dynamicWorkEslVoList[item.itemNmbr]?.quotaCnt || 0)
          .toString()
          .replace(/[^0-9]/g, ''),
        nstlQntt: +(dynamicWorkEslVoList[item.itemNmbr]?.installCnt || 0)
          .toString()
          .replace(/[^0-9]/g, ''),
        freQntt: +(dynamicWorkEslVoList[item.itemNmbr]?.extraCnt || 0)
          .toString()
          .replace(/[^0-9]/g, ''),
        lostQntt: +(dynamicWorkEslVoList[item.itemNmbr]?.lossCnt || 0)
          .toString()
          .replace(/[^0-9]/g, ''),
        brkgQntt: +(dynamicWorkEslVoList[item.itemNmbr]?.badCnt || 0)
          .toString()
          .replace(/[^0-9]/g, ''),
        porQntt: +(dynamicWorkEslVoList[item.itemNmbr]?.breakCnt || 0)
          .toString()
          .replace(/[^0-9]/g, ''),
        rtrnNrmlQntt: 0,
        rtrnDfctQntt: 0,
      });
    });

    return result;
  };

  // ESL 설치 품목 재고이동 수량을 배열로 변환함
  const getDynamicStockMovementVoListToArray = (): any[] => {
    let result: any[] = [];

    detailData?.workEslVoList?.map((item: any) => {
      // 추가요청 항목에 값이 있으면 아래의 소스를 실행함
      if (dynamicStockMovementVoList[item.itemNmbr]?.addCnt || 0 > 0) {
        // 품목의 수량을 생성함
        result.push({
          itemCode: item.itemNmbr,
          stckTypeCode: 'STCK_AD',
          stckSttsCode: '',
          ttl: '재고추가',
          quantity: +(dynamicStockMovementVoList[item.itemNmbr]?.addCnt || 0)
            .toString()
            .replace(/[^0-9]/g, ''),
        });
      }

      // 반납(일반)요청 항목에 값이 있으면 아래의 소스를 실행함
      if (dynamicStockMovementVoList[item.itemNmbr]?.returnNormalCnt || 0 > 0) {
        // 품목의 수량을 생성함
        result.push({
          itemCode: item.itemNmbr,
          stckTypeCode: 'STCK_RTRN',
          stckSttsCode: 'CMN',
          ttl: '반납_일반',
          quantity: +(
            dynamicStockMovementVoList[item.itemNmbr]?.returnNormalCnt || 0
          )
            .toString()
            .replace(/[^0-9]/g, ''),
        });
      }

      // 반납(불량)요청 항목에 값이 있으면 아래의 소스를 실행함
      if (dynamicStockMovementVoList[item.itemNmbr]?.returnBadCnt || 0 > 0) {
        // 품목의 수량을 생성함
        result.push({
          itemCode: item.itemNmbr,
          stckTypeCode: 'STCK_RTRN',
          stckSttsCode: 'EROR',
          ttl: '반납_불량',
          quantity: +(
            dynamicStockMovementVoList[item.itemNmbr]?.returnBadCnt || 0
          )
            .toString()
            .replace(/[^0-9]/g, ''),
        });
      }
    });

    return result;
  };

  // ESL 작업완료 버튼을 클릭함
  const handleEslWorkCompleteButton_onClick = () => {
    Dialog.show({
      content: '작업완료로 처리하시겠습니까?',
      actions: [
        {
          key: 'NVNT_Y',
          text: '재고이동 요청 포함',
        },
        {
          key: 'NVNT_N',
          text: '재고이동 요청 미포함',
        },
        {
          key: 'CANCEL',
          text: '취소',
          danger: true,
        },
      ],
      closeOnAction: true,
      onAction: (event: any) => {
        if (event.key !== 'CANCEL') {
          // 작업완료로 처리함
          const runSave = () => {
            // 화면 위 검은 화면 저장소에 적용함
            setOverlay({
              visible: true,
              content: (
                <div className="px-3 py-1 bg-black/50">
                  <span className="text-base text-white font-bold">
                    작업완료 중
                  </span>
                </div>
              ),
            });

            // 작업 내역 - 재고이전 포함 작업완료(ESL)
            workApi
              .putWorkEslNvntRqstDcmnDntfNmbr({
                dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
                splrId: selectedData.splrId || '',
                teamId: selectedData.teamId || '',
                bfrNstlSttsCode: selectedData.nstlSttsCode,
                nstlSttsCode: 'WORK_CMPL',
                cmnDtl: dynamicNormalComment.content,
                cmnfileList:
                  dynamicNormalComment.fileList?.map(
                    (fileItem: any) => fileItem.id,
                  ) || [],
                nstlCnfrDtl: dynamicInstallComment.content,
                nstlCnfrfileList:
                  dynamicInstallComment.fileList?.map(
                    (fileItem: any) => fileItem.id,
                  ) || [],
                nvntFlag: event.key,
                workEslParamList: getDynamicWorkEslVoListToArray(),
                workNvntRqstList: getDynamicStockMovementVoListToArray(),
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  // 부모창으로 값을 전달함
                  callback('refresh-list');

                  Toast.show({
                    position: 'bottom',
                    content: '처리하였습니다.',
                  });

                  // 브라우저 저장소를 삭제함
                  localStorage.removeItem(localStorageIdref.current);

                  // 삭제할 팝업 저장소에 적용함
                  setRemovePopup(id);
                } else {
                  Toast.show({
                    position: 'bottom',
                    content: '처리 중 오류가 발생하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 작업완료 Error:', error);

                Toast.show({
                  position: 'bottom',
                  content: `처리 중 오류가 발생하였습니다. (${error.message})`,
                });
              })
              .finally(() => {
                // 화면 위 검은 화면 저장소에 적용함
                setOverlay({
                  visible: false,
                  content: '',
                });
              });
          };

          // 재고이동 요청 미포함으로 선택하면 한번 더 확인함
          if (event.key === 'NVNT_N') {
            Dialog.confirm({
              content: '재고이동 없이 처리하시겠습니까?',
              confirmText: '예',
              cancelText: '아니오',
              onConfirm: () => {
                runSave();
              },
            });
          } else {
            runSave();
          }
        }
      },
    });
  };

  // 네트워크 작업완료 버튼을 클릭함
  const handleNetworkWorkCompleteButton_onClick = () => {
    if (dynamicWorkNtwrVoList.gwIp.trim() === '') {
      Toast.show({
        position: 'bottom',
        content: 'G/W IP를 입력하세요.',
      });

      return;
    }

    // if (dynamicWorkNtwrVoList.serverIp.trim() === '') {
    //   Toast.show({
    //     position: 'bottom',
    //     content: 'Server IP를 입력하세요.',
    //   });
    //
    //   return;
    // }

    console.log({
      dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
      splrId: selectedData.splrId || '',
      teamId: selectedData.teamId || '',
      bfrNstlSttsCode: selectedData.nstlSttsCode,
      nstlSttsCode: 'WORK_CMPL',
      cmnDtl: dynamicNormalComment.content,
      cmnfileList:
        dynamicNormalComment.fileList?.map((fileItem: any) => fileItem.id) ||
        [],
      nstlCnfrDtl: dynamicInstallComment.content,
      nstlCnfrfileList:
        dynamicInstallComment.fileList?.map((fileItem: any) => fileItem.id) ||
        [],
      workNtwrParamList: [
        {
          ntwrWorkCode: 'GTWY_IP',
          ttl: dynamicWorkNtwrVoList.gwIp || '',
        },
        {
          ntwrWorkCode: 'SRVR_IP',
          ttl: dynamicWorkNtwrVoList.serverIp || '',
        },
        {
          ntwrWorkCode: 'MAC_ADDR',
          ttl: dynamicWorkNtwrVoList.macAddress || '',
        },
        {
          ntwrWorkCode: 'GTWY_FW_VER',
          ttl: dynamicWorkNtwrVoList.gwFwVersion || '',
        },
      ],
    });

    Dialog.confirm({
      content: '작업완료로 처리하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위 검은 화면 저장소에 적용함
        setOverlay({
          visible: true,
          content: (
            <div className="px-3 py-1 bg-black/50">
              <span className="text-base text-white font-bold">
                작업완료 중
              </span>
            </div>
          ),
        });

        // 네트워크 작업 내역 및 결과를 저장함
        workApi
          .putWorkNtwrDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            splrId: selectedData.splrId || '',
            teamId: selectedData.teamId || '',
            bfrNstlSttsCode: selectedData.nstlSttsCode,
            nstlSttsCode: 'WORK_CMPL',
            cmnDtl: dynamicNormalComment.content,
            cmnfileList:
              dynamicNormalComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
            nstlCnfrDtl: dynamicInstallComment.content,
            nstlCnfrfileList:
              dynamicInstallComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
            workNtwrParamList: [
              {
                ntwrWorkCode: 'GTWY_IP',
                ttl: dynamicWorkNtwrVoList.gwIp || '',
              },
              {
                ntwrWorkCode: 'SRVR_IP',
                ttl: dynamicWorkNtwrVoList.serverIp || '',
              },
              {
                ntwrWorkCode: 'MAC_ADDR',
                ttl: dynamicWorkNtwrVoList.macAddress || '',
              },
              {
                ntwrWorkCode: 'GTWY_FW_VER',
                ttl: dynamicWorkNtwrVoList.gwFwVersion || '',
              },
            ],
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 브라우저 저장소를 삭제함
              localStorage.removeItem(localStorageIdref.current);

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 작업완료 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          })
          .finally(() => {
            // 화면 위 검은 화면 저장소에 적용함
            setOverlay({
              visible: false,
              content: '',
            });
          });
      },
    });
  };

  // 납품 작업완료 버튼을 클릭함
  const handleDeliverWorkCompleteButton_onClick = () => {
    Dialog.confirm({
      content: '납품내역을 작업완료로 처리하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위 검은 화면 저장소에 적용함
        setOverlay({
          visible: true,
          content: (
            <div className="px-3 py-1 bg-black/50">
              <span className="text-base text-white font-bold">
                작업완료 중
              </span>
            </div>
          ),
        });

        // 납품 작업 내역 및 결과를 저장함
        workApi
          .postDeliveryCompleteDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            dueDate: dueDateInput
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 브라우저 저장소를 삭제함
              localStorage.removeItem(localStorageIdref.current);

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 작업완료 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          })
          .finally(() => {
            // 화면 위 검은 화면 저장소에 적용함
            setOverlay({
              visible: false,
              content: '',
            });
          });
      },
    });
  };

  // ESL 작업완료 취소 버튼을 클릭함
  const handleEslWorkCompleteCancelButton_onClick = () => {
    Dialog.confirm({
      title: '작업완료를 취소하시겠습니까?',
      content:
        '작업완료 때 작성한 일반 첨부파일, 설치 확인서는 복원되지 않습니다.',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        workApi
          .putWorkEslNvntRqstDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            splrId: selectedData.splrId || '',
            teamId: selectedData.teamId || '',
            bfrNstlSttsCode: selectedData.nstlSttsCode,
            nstlSttsCode: 'PRCD',
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> ESL 작업완료 취소 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          });
      },
    });
  };

  // 네트워크 작업완료 취소 버튼을 클릭함
  const handleNetworkWorkCompleteCancelButton_onClick = () => {
    Dialog.confirm({
      title: '작업완료를 취소하시겠습니까?',
      content:
        '작업완료 때 작성한 일반 첨부파일, 설치 확인서는 복원되지 않습니다.',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        workApi
          .putWorkNtwrDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            splrId: selectedData.splrId || '',
            teamId: selectedData.teamId || '',
            bfrNstlSttsCode: selectedData.nstlSttsCode,
            nstlSttsCode: 'PRCD',
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 네트워크 작업완료 취소 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          });
      },
    });
  };

  // 납품 작업완료 취소 버튼을 클릭함
  const handleDeliverWorkCompleteCancelButton_onClick = () => {
    Dialog.confirm({
      title: '작업완료를 취소하시겠습니까?',
      content:
        '작업완료 때 작성한 일반 첨부파일, 설치 확인서는 복원되지 않습니다.',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        workApi
          .postDeliveryCancelDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 납품 작업완료 취소 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          });
      },
    });
  };

  // ESL 검수완료 버튼을 클릭함
  const handleEslNspcCompleteButton_onClick = () => {
    Dialog.confirm({
      content: '검수완료로 처리하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위 검은 화면 저장소에 적용함
        setOverlay({
          visible: true,
          content: (
            <div className="px-3 py-1 bg-black/50">
              <span className="text-base text-white font-bold">
                검수완료 중
              </span>
            </div>
          ),
        });

        workApi
          .putWorkEslNvntRqstDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            splrId: selectedData.splrId || '',
            teamId: selectedData.teamId || '',
            bfrNstlSttsCode: selectedData.nstlSttsCode,
            nstlSttsCode: 'NSPC_CMPL',
            cmnDtl: dynamicNormalComment.content,
            cmnfileList:
              dynamicNormalComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
            nstlCnfrDtl: dynamicInstallComment.content,
            nstlCnfrfileList:
              dynamicInstallComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 브라우저 저장소를 삭제함
              localStorage.removeItem(localStorageIdref.current);

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 검수완료 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          })
          .finally(() => {
            // 화면 위 검은 화면 저장소에 적용함
            setOverlay({
              visible: false,
              content: '',
            });
          });
      },
    });
  };

  // 네트워크 검수완료 버튼을 클릭함
  const handleNetworkNspcCompleteButton_onClick = () => {
    Dialog.confirm({
      content: '검수완료로 처리하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위 검은 화면 저장소에 적용함
        setOverlay({
          visible: true,
          content: (
            <div className="px-3 py-1 bg-black/50">
              <span className="text-base text-white font-bold">
                검수완료 중
              </span>
            </div>
          ),
        });

        workApi
          .putWorkNtwrDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            splrId: selectedData.splrId || '',
            teamId: selectedData.teamId || '',
            bfrNstlSttsCode: selectedData.nstlSttsCode,
            nstlSttsCode: 'NSPC_CMPL',
            cmnDtl: dynamicNormalComment.content,
            cmnfileList:
              dynamicNormalComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
            nstlCnfrDtl: dynamicInstallComment.content,
            nstlCnfrfileList:
              dynamicInstallComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 브라우저 저장소를 삭제함
              localStorage.removeItem(localStorageIdref.current);

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 검수완료 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          })
          .finally(() => {
            // 화면 위 검은 화면 저장소에 적용함
            setOverlay({
              visible: false,
              content: '',
            });
          });
      },
    });
  };

  // 검수완료 취소 버튼을 클릭함
  const handleNspcCompleteCancelButton_onClick = () => {
    let tmpParam: any = {};

    // 화면에 따라서 검수완료 취소 방식을 다르게 함
    switch (integrateData) {
      // 실적등록 화면
      case false:
        tmpParam = {
          dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
          splrId: selectedData.splrId || '',
          teamId: selectedData.teamId || '',
          bfrNstlSttsCode: selectedData.nstlSttsCode,
          nstlSttsCode: 'WORK_CMPL',
        };
        break;

      // PM검수 화면
      case true:
        tmpParam = {
          dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
          splrId: 'ALL',
          teamId: 'ALL',
          bfrNstlSttsCode: selectedData.nstlSttsCode,
          nstlSttsCode: 'WORK_CMPL',
        };
        break;

      default:
        break;
    }

    Dialog.confirm({
      title: '검수완료를 취소하시겠습니까?',
      content:
        '검수완료 때 작성한 일반 첨부파일, 설치 확인서는 복원되지 않습니다.',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        workApi
          .putWorkEslNvntRqstDcmnDntfNmbr(tmpParam)
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> 검수완료 취소 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          });
      },
    });
  };

  // PM검수완료 버튼을 클릭함
  const handlePmNspcCompleteButton_onClick = () => {
    if (softwareVersionInput === '') {
      Toast.show({
        position: 'bottom',
        content: '소프트웨어 버전을 입력하세요.',
      });

      return;
    }

    if (dueDateInput === '') {
      Toast.show({
        position: 'bottom',
        content: '작업완료일을 입력하세요.',
      });

      return;
    }

    Dialog.confirm({
      content: 'PM검수완료로 처리하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위 검은 화면 저장소에 적용함
        setOverlay({
          visible: true,
          content: (
            <div className="px-3 py-1 bg-black/50">
              <span className="text-base text-white font-bold">
                PM검수완료 중
              </span>
            </div>
          ),
        });

        workApi
          .postPmNspcRgst({
            bfrNstlSttsCode: selectedData.nstlSttsCode,
            nstlSttsCode: 'PM_NSPC_CMPL',
            splrId: selectedData.dcmnDntfNmbr || '',
            teamId: selectedData.dcmnDntfNmbr || '',
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
            vrsnNfrm: softwareVersionInput,
            dueDate: dueDateInput,
            cmnDtl: dynamicNormalComment.content,
            cmnfileList:
              dynamicNormalComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
            nstlCnfrDtl: dynamicInstallComment.content,
            nstlCnfrfileList:
              dynamicInstallComment.fileList?.map(
                (fileItem: any) => fileItem.id,
              ) || [],
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 브라우저 저장소를 삭제함
              localStorage.removeItem(localStorageIdref.current);

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              // Toast.show({
              //   position: 'bottom',
              //   content: '처리 중 오류가 발생하였습니다.',
              // });

              Dialog.show({
                title: '처리 중 오류가 발생하였습니다.',
                content: decode(data.message || ''),
                closeOnMaskClick: true,
                closeOnAction: true,
                actions: [
                  {
                    key: 'okay',
                    text: '확인',
                  },
                ],
              });
            }
          })
          .catch((error: any) => {
            console.log('> PM검수완료 Error:', error);

            // Toast.show({
            //   position: 'bottom',
            //   content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            // });

            Dialog.show({
              title: '처리 중 오류가 발생하였습니다.',
              content: decode(error.message || ''),
              closeOnMaskClick: true,
              closeOnAction: true,
              actions: [
                {
                  key: 'okay',
                  text: '확인',
                },
              ],
            });
          })
          .finally(() => {
            // 화면 위 검은 화면 저장소에 적용함
            setOverlay({
              visible: false,
              content: '',
            });
          });
      },
    });
  };

  // PM검수완료 취소 버튼을 클릭함
  const handlePmNspcCompleteCancelButton_onClick = () => {
    Dialog.confirm({
      title: 'PM검수완료를 취소하시겠습니까?',
      content:
        'PM검수완료 때 작성한 일반 첨부파일, 설치 확인서는 복원되지 않습니다.',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        workApi
          .putPmNspcCancelDcmnDntfNmbr({
            dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
          })
          .then((data: IApiResult) => {
            if (data.code === '200') {
              // 부모창으로 값을 전달함
              callback('refresh-list');

              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: '처리 중 오류가 발생하였습니다.',
              });
            }
          })
          .catch((error: any) => {
            console.log('> PM검수완료 취소 Error:', error);

            Toast.show({
              position: 'bottom',
              content: `처리 중 오류가 발생하였습니다. (${error.message})`,
            });
          });
      },
    });
  };

  // 닫기 버튼을 클릭함
  const handleClosePopupButton_onClick = () => {
    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  // 체크 목록 결과의 데이터를 불러옴
  const getCheckListResultData = () => {
    // nstlApi
    //   .getChckCntrNmbrSplrTypeCode({
    //     cntrNmbr: selectedData.cntrNmbr,
    //     splrTypeCode: selectedData.splrTypeCode,
    //     useYn: true,
    //   })
    //   .then((data: IApiResult) => {
    //     if (data.code === '200') {
    //       console.log('> cntrNmbr:', selectedData.cntrNmbr);
    //       console.log('> splrTypeCode:', selectedData.splrTypeCode);
    //       console.log('> check:', data);
    //       setCheckList(data.data);
    //     }
    //   });

    // 설치 정보를 불러옴
    let tmpWorkDtlPrtnVoList: any[] = [];

    if (integrateData === true) {
      // PM 검수 화면에서는 모든 설치팀의 체크 목록을 불러옴
      // 설치 정보를 불러옴
      if (detailData?.workDtlPrtnVoList !== undefined) {
        tmpWorkDtlPrtnVoList = detailData?.workDtlPrtnVoList;
      }
    } else {
      // 해당 설치팀의 체크 목록을 불러옴
      // 설치 정보를 불러옴
      let tmp = detailData?.workDtlPrtnVoList?.filter(
        (filterItem: any) =>
          filterItem.splrTypeCode === selectedData.splrTypeCode &&
          filterItem.splrId === selectedData.splrId &&
          filterItem.teamId === selectedData.teamId,
      );

      if (tmp.length > 0) {
        tmpWorkDtlPrtnVoList.push(
          detailData?.workDtlPrtnVoList?.filter(
            (filterItem: any) =>
              filterItem.splrTypeCode === selectedData.splrTypeCode &&
              filterItem.splrId === selectedData.splrId &&
              filterItem.teamId === selectedData.teamId,
          )[0],
        );
      }
    }

    let tmpCheckListResult: any = [];

    tmpWorkDtlPrtnVoList.map((subItem: any) => {
      // 작업별 체크 결과 목록을 불러옴
      nstlApi
        .getChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId({
          cntrNmbr: detailData.cntrNmbr,
          dcmnDntfNmbr: detailData.dcmnDntfNmbr,
          prtnDate: moment(subItem.workStrtDttm).format('YYYYMMDD'),
          splrId: subItem.splrId,
          splrTypeCode: subItem.splrTypeCode,
          teamId: subItem.teamId,
        })
        .then((data: IApiResult) => {
          if (data.code === '200') {
            if (data.data.length > 0) {
              tmpCheckListResult.push({
                team: subItem,
                checkList: data.data,
              });
            }

            if (tmpWorkDtlPrtnVoList.length === tmpCheckListResult.length) {
              // 사전점검결과 목록에 적용함
              setCheckListResult(tmpCheckListResult);
            }
          }
        });
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('popup', id, true);

    console.log('> selectedData:', selectedData);

    return () => {};
  }, []);

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (loginUser.id === '') {
      return;
    }

    // 브라우저 저장소 아이디에 적용함
    localStorageIdref.current = `performanceRegistration-${
      selectedData.dcmnDntfNmbr
    }-${loginUser.cmpnCode || 'none'}-${selectedData.teamId || 'none'}`;

    // 브라우저 저장소에서 불러옴
    if (localStorage.getItem(localStorageIdref.current)) {
      let tmpData: any = JSON.parse(
        localStorage.getItem(localStorageIdref.current) || '',
      );

      // 불러온 브라우저 저장소의 임시 저장 정보를 기억함
      loadedLocalStorageRef.current = tmpData;

      // 불러온 브라우저 저장소의 설치 품목 동적 폼의 품목 개수를 기억함
      loadedLocalStorageDynamicWorkEslVoListLengthRef.current = Object.keys(
        tmpData.workEslVoList,
      ).length;

      // 품목 수량을 숫자로 변경함
      tmpData.workEslVoList.map((subItem: any) => {
        subItem.quotaCnt = +(
          subItem.quotaCnt.toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.installCnt = +(
          subItem.installCnt.toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.extraCnt = +(
          subItem.extraCnt.toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.lossCnt = +(
          subItem.lossCnt.toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.breakCnt = +(
          subItem.breakCnt.toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.badCnt = +(
          subItem.badCnt.toString().replace(/[^0-9]/g, '') || 0
        );
      });

      // 설치 품목 동적 폼에 적용함
      setDynamicWorkEslVoList(tmpData.workEslVoList);

      // 네트워크 설치 정보 동적 폼에 적용함
      setDynamicWorkNtwrVoList(tmpData.workNtwrVoList);

      // 재고이동 요청 동적 폼에 적용함
      setDynamicStockMovementVoList(tmpData.stockMovementVoList);

      // 일반 첨부파일 동적 폼에 적용함
      setDynamicNormalComment(tmpData.normalComment);

      // 설치 확인서 동적 폼에 적용함
      setDynamicInstallComment(tmpData.installComment);

      Toast.show({
        position: 'bottom',
        content: '임시 정보를 불러왔습니다.',
      });
    }

    // 상세 내용을 불러옴
    getDetailData();

    return () => {};
  }, [loginUser.id]);

  // 상세 내용을 불러왔을 때 실행함
  useEffect(() => {
    if (detailData === null) {
      return;
    }

    // 브라우저 저장소에 저장된 임시 저장 정보가 있으면, 임시 저장 정보를 사용함
    if (localStorage.getItem(localStorageIdref.current)) {
      let tmpData: any = JSON.parse(
        localStorage.getItem(localStorageIdref.current) || '',
      );

      // 불러온 브라우저 저장소의 임시 저장 정보를 기억함
      loadedLocalStorageRef.current = tmpData;

      // 불러온 브라우저 저장소의 설치 품목 동적 폼의 품목 개수를 기억함
      loadedLocalStorageDynamicWorkEslVoListLengthRef.current = Object.keys(
        tmpData.workEslVoList,
      ).length;

      // 품목 수량을 숫자로 변경함
      tmpData.workEslVoList.map((subItem: any) => {
        subItem.quotaCnt = +(
          (subItem.quotaCnt || '').toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.installCnt = +(
          (subItem.installCnt || '').toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.extraCnt = +(
          (subItem.extraCnt || '').toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.lossCnt = +(
          (subItem.lossCnt || '').toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.breakCnt = +(
          (subItem.breakCnt || '').toString().replace(/[^0-9]/g, '') || 0
        );
        subItem.badCnt = +(
          (subItem.badCnt || '').toString().replace(/[^0-9]/g, '') || 0
        );
      });

      // 설치 품목 동적 폼에 적용함
      setDynamicWorkEslVoList(tmpData.workEslVoList);

      // 네트워크 설치 정보 동적 폼에 적용함
      setDynamicWorkNtwrVoList(tmpData.workNtwrVoList);

      // 재고이동 요청 동적 폼에 적용함
      setDynamicStockMovementVoList(tmpData.stockMovementVoList);

      // 일반 첨부파일 동적 폼에 적용함
      setDynamicNormalComment(tmpData.normalComment);

      // 설치 확인서 동적 폼에 적용함
      setDynamicInstallComment(tmpData.installComment);
    } else {
      // 설치 품목 동적 폼에 적용함
      let tmpDynamicWorkEslVoList: any = {};

      detailData.workEslVoList.map((item: any) => {
        tmpDynamicWorkEslVoList[item.itemNmbr] = {
          quotaCnt: +(
            (item.rcvdQntt || '').toString().replace(/[^0-9]/g, '') || 0
          ),
          installCnt: +(
            (item.nstlQntt || '').toString().replace(/[^0-9]/g, '') || 0
          ),
          extraCnt: +(
            (item.freQntt || '').toString().replace(/[^0-9]/g, '') || 0
          ),
          lossCnt: +(
            (item.lostQntt || '').toString().replace(/[^0-9]/g, '') || 0
          ),
          badCnt: +(
            (item.brkgQntt || '').toString().replace(/[^0-9]/g, '') || 0
          ),
          breakCnt: +(
            (item.porQntt || '').toString().replace(/[^0-9]/g, '') || 0
          ),
        };
      });

      setDynamicWorkEslVoList(tmpDynamicWorkEslVoList);

      // 네트워크 설치 정보 동적 폼에 적용함
      let tmpDynamicWorkNtwrVoList: any = {
        gwIp:
          _.find(detailData.workNtwrVoList, { ntwrWorkCode: 'GTWY_IP' })?.ttl ||
          '',
        serverIp:
          _.find(detailData.workNtwrVoList, { ntwrWorkCode: 'SRVR_IP' })?.ttl ||
          '',
        macAddress:
          _.find(detailData.workNtwrVoList, { ntwrWorkCode: 'MAC_ADDR' })
            ?.ttl || '',
        gwFwVersion:
          _.find(detailData.workNtwrVoList, { ntwrWorkCode: 'GTWY_FW_VER' })
            ?.ttl || '',
      };
      setDynamicWorkNtwrVoList(tmpDynamicWorkNtwrVoList);

      // 일반 첨부파일 동적 폼에 적용함
      setDynamicNormalComment({});

      // 설치 확인서 동적 폼에 적용함
      setDynamicInstallComment({});
    }

    // 체크 목록 결과의 데이터를 불러옴
    getCheckListResultData();

    return () => {};
  }, [detailData]);

  // ESL 품목 수량이 변경될 때 실행함
  useEffect(() => {
    if (Object.keys(dynamicWorkEslVoList).length === 0) {
      return;
    }

    // ESL 품목 수량을 계산하여 품목별 재고이동 요청 수량을 계산함
    for (let key in dynamicWorkEslVoList) {
      // 품목 수량을 불러옴
      let tmpItemCount: any = dynamicWorkEslVoList[key];

      // 품목의 설치, 여유, 분실, 파손, 불량 수량을 합친 후, 수령에서 뺌
      let tmpSumCnt: number =
        (+tmpItemCount.quotaCnt || 0) -
        ((+tmpItemCount.installCnt || 0) +
          (+tmpItemCount.extraCnt || 0) +
          (+tmpItemCount.lossCnt || 0) +
          (+tmpItemCount.badCnt || 0) +
          (+tmpItemCount.breakCnt || 0));

      // 추가요청 수량을 계산함
      let tmpAddCnt: number = tmpSumCnt <= 0 ? tmpSumCnt : 0;

      tmpAddCnt = Math.abs(tmpAddCnt);

      // 반납(일반)요청 수량을 계산함
      let tmpReturnNormalCnt: number = tmpSumCnt >= 0 ? tmpSumCnt : 0;

      // 반납(불량)요청 수량을 계산함
      let tmpReturnBadCnt: number = tmpItemCount.breakCnt || 0;

      // 재고이동 요청 동적 폼에 적용함
      setDynamicStockMovementVoList((pre: any) => ({
        ...pre,
        [key]: {
          ...pre[key],
          addCnt: tmpAddCnt,
          returnNormalCnt: tmpReturnNormalCnt,
          returnBadCnt: tmpReturnBadCnt,
        },
      }));
    }

    // 첫 로딩 시, ESL 품목 수량이 변경될 때마다 -1씩 감소함
    // 첫 로딩 후, 재고이동 요청 동적 폼에 적용하기 위함
    if (loadedLocalStorageDynamicWorkEslVoListLengthRef.current > 0) {
      loadedLocalStorageDynamicWorkEslVoListLengthRef.current -= 1;

      // 첫 로딩 시, ESL 품목 수량이 모두 화면에 그려졌는지 확인함
      if (loadedLocalStorageDynamicWorkEslVoListLengthRef.current === 0) {
        // 재고이동 요청 동적 폼에 브라우저 저장소의 정보를 적용함
        setDynamicStockMovementVoList(
          loadedLocalStorageRef.current.stockMovementVoList,
        );
      }
    }

    return () => {};
  }, [dynamicWorkEslVoList]);

  //
  useEffect(() => {
    // 팝업의 최상단으로 페이지를 스크롤함
    appUtil.scrollToPopupTop(id);

    return () => {};
  }, [selectedTab]);

  useEffect(() => {
    console.log(
      '> dynamicNormalComment?.fileList:',
      dynamicNormalComment?.fileList,
    );

    return () => {};
  }, [dynamicNormalComment?.fileList]);

  return (
    <div className="">
      {/* 작업구분 헤더 */}
      <div className="w-full h-16 px-3 flex justify-center items-center bg-gradient-to-b from-sky-200 to-white space-x-2">
        <div className="flex justify-center items-center space-x-1">
          {/* 주문번호 아이콘 */}
          <div className="px-2 py-1 flex justify-center items-center bg-white border border-sky-500 rounded-full overflow-hidden">
            <span className="text-xs text-gray-500 font-bold leading-none">
              주문번호.{selectedData.dcmnScrnNmbr}
            </span>
          </div>

          {/* 작업상태 아이콘 */}
          <div
            className={[
              'px-2 py-1 flex justify-center items-center border rounded-full overflow-hidden',
              _.find(workState, { value: selectedData.nstlSttsCode })
                ?.backgroundColor,
              _.find(workState, { value: selectedData.nstlSttsCode })
                ?.borderColor,
            ].join(' ')}
          >
            <span className="text-xs text-white font-bold leading-none">
              {
                _.find(workState, { value: selectedData.nstlSttsCode })
                  ?.iconTitle
              }
            </span>
          </div>
        </div>

        {/* 작업구분 */}
        <span className="text-base text-gray-900 font-medium">
          <Ellipsis
            direction="end"
            content={decode(
              (integrateData === false &&
                (selectedData.splrTypeCode === 'ESL_NSTL'
                  ? `ESL.${selectedData?.ttl || ''}`
                  : `네트워크.${selectedData?.ttl || ''}`)) ||
                '',
            )}
          />
        </span>
      </div>

      {/* 탭 */}
      <Tabs
        onChange={setSelectedTab}
        defaultActiveKey={
          selectedData.splrTypeCode === 'ESL_DLVR'
            ? 'work-history'
            : 'check-list'
        }
        className="ant-m-tabs ant-m-tabs-popup-sticky ant-m-tabs-no-padding"
      >
        {/* 사전점검 탭은 납품이 아닐 때만 사용함 */}
        {selectedData.splrTypeCode !== 'ESL_DLVR' && (
          <Tabs.Tab key="check-list" title="사전점검">
            <div className="ant-m-tabs-content !pb-40 space-y-10">
              {/* 사전점검 목록이 없음 */}
              {checkListResult.length === 0 && (
                <div className="mt-2 px-3 h-10 flex justify-center items-center bg-slate-200 rounded-sm">
                  <span className="text-base text-gray-800 font-normal">
                    사전점검 결과가 없습니다.
                  </span>
                </div>
              )}

              {checkListResult.map((teamItem: any, index: number) => (
                <div key={index} className="space-y-3">
                  <StickyContentHeader
                    title={`${teamItem.team.teamName || '팀 정보 없음'} - ${
                      teamItem.team.splrTypeCode === 'ESL_NSTL' ? 'ESL' : ''
                    }${
                      teamItem.team.splrTypeCode === 'NTWR_CNST'
                        ? '네트워크'
                        : ''
                    } - 체크리스트`}
                    className="top-24"
                  />

                  {/* 체크리스트 */}
                  {teamItem.checkList?.map(
                    (checkItem: any, checkIndex: number) => (
                      <Form.Item key={checkIndex}>
                        <div className="px-3 py-3 flex justify-between items-center bg-slate-100 rounded-sm">
                          <div className="grow space-x-2">
                            <span className="text-sm font-bold text-gray-900 font-bold">
                              {checkIndex + 1}
                            </span>

                            <span className="text-sm font-normal text-gray-800">
                              {checkItem?.cntn}
                            </span>
                          </div>

                          <div className="flex-none w-28 select-none">
                            <div className="flex justify-end items-center space-x-2">
                              {/* O */}
                              <div
                                onClick={() => {
                                  let tmpCheckListResult: any =
                                    _.cloneDeep(checkListResult);

                                  tmpCheckListResult
                                    .filter(
                                      (filterItem: any) =>
                                        filterItem.team.splrTypeCode ===
                                          teamItem.team.splrTypeCode &&
                                        filterItem.team.splrId ===
                                          teamItem.team.splrId &&
                                        filterItem.team.teamId ===
                                          teamItem.team.teamId &&
                                        filterItem.team.cntcId ===
                                          teamItem.team.cntcId,
                                    )[0]
                                    .checkList.map((tmpItem: any) => {
                                      if (
                                        tmpItem.cntnNmbr === checkItem.cntnNmbr
                                      ) {
                                        tmpItem.rslt = true;
                                      }
                                    });

                                  setCheckListResult(tmpCheckListResult);
                                }}
                                className={[
                                  'w-10 h-10 flex justify-center items-center border rounded-full',
                                  checkItem.rslt === true
                                    ? 'border-blue-400 bg-blue-500 text-white'
                                    : 'border-slate-300 bg-white text-gray-600',
                                  selectedData.nstlSttsCode !== 'PRCD' &&
                                  checkItem.rslt !== true
                                    ? 'hidden'
                                    : 'cursor-pointer',
                                ].join(' ')}
                              >
                                <span className="text-base font-bold">O</span>
                              </div>

                              {/* X */}
                              <div
                                onClick={() => {
                                  let tmpCheckListResult: any =
                                    _.cloneDeep(checkListResult);

                                  tmpCheckListResult
                                    .filter(
                                      (filterItem: any) =>
                                        filterItem.team.splrTypeCode ===
                                          teamItem.team.splrTypeCode &&
                                        filterItem.team.splrId ===
                                          teamItem.team.splrId &&
                                        filterItem.team.teamId ===
                                          teamItem.team.teamId &&
                                        filterItem.team.cntcId ===
                                          teamItem.team.cntcId,
                                    )[0]
                                    .checkList.map((tmpItem: any) => {
                                      if (
                                        tmpItem.cntnNmbr === checkItem.cntnNmbr
                                      ) {
                                        tmpItem.rslt = false;
                                      }
                                    });

                                  setCheckListResult(tmpCheckListResult);
                                }}
                                className={[
                                  'w-10 h-10 flex justify-center items-center border rounded-full cursor-pointer',
                                  checkItem.rslt === false
                                    ? 'border-rose-400 bg-rose-500 text-white'
                                    : 'border-slate-300 bg-white text-gray-600',
                                  selectedData.nstlSttsCode !== 'PRCD' &&
                                  checkItem.rslt !== false
                                    ? 'hidden'
                                    : 'cursor-pointer',
                                ].join(' ')}
                              >
                                <span className="text-base font-bold">X</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form.Item>
                    ),
                  )}
                </div>
              ))}
            </div>
          </Tabs.Tab>
        )}

        <Tabs.Tab key="work-history" title="작업내역">
          <div className="ant-m-tabs-content space-y-10">
            {/* 작업구분이 ESL일 때 출력 */}
            {/* PM검수 페이지에서 진입했을 때 출력 */}
            {(selectedData.splrTypeCode === 'ESL_NSTL' ||
              integrateData === true) && (
              <>
                {_.uniqBy(detailData?.workEslVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <div key={teamIndex} className="space-y-3">
                      <StickyContentHeader
                        title={`${
                          teamItem.teamName || '팀 정보 없음'
                        } - 설치 품목`}
                        className="top-24"
                      />

                      {detailData?.workEslVoList
                        ?.filter(
                          (filterData: any) =>
                            filterData.teamId === teamItem.teamId,
                        )
                        .map((item: any, index: number) => (
                          <div key={index}>
                            {index > 0 && <div className="divider-y" />}

                            <div className="space-y-3">
                              <div className="grow">
                                <Form.Item label={`품목(${item.itemNmbr})`}>
                                  <span className="text-base text-gray-900 font-medium">
                                    <Ellipsis
                                      direction="end"
                                      content={decode(item.itemDtls)}
                                    />
                                  </span>
                                </Form.Item>
                              </div>

                              {/* 설치 수량 */}
                              <div className="grid grid-cols-6 gap-x-2">
                                <Form.Item label="수령">
                                  <Stepper
                                    min={0}
                                    value={
                                      +(
                                        selectedData.nstlSttsCode === 'PRCD'
                                          ? dynamicWorkEslVoList?.[
                                              item.itemNmbr
                                            ]?.quotaCnt || 0
                                          : item.rcvdQntt || 0
                                      )
                                        .toString()
                                        .replaceAll(',', '')
                                    }
                                    formatter={(value?: number) =>
                                      appUtil.getFormatNumber(value)
                                    }
                                    onChange={(value: any) => {
                                      // 작업대기 상태일 때만 실행함
                                      if (
                                        selectedData.nstlSttsCode === 'PRCD'
                                      ) {
                                        setDynamicWorkEslVoList((pre: any) => ({
                                          ...pre,
                                          [item.itemNmbr]: {
                                            ...pre[item.itemNmbr],
                                            quotaCnt: value,
                                          },
                                        }));
                                      }
                                    }}
                                    inputReadOnly={
                                      selectedData.nstlSttsCode !== 'PRCD'
                                    }
                                    className="ant-m-stepper ant-m-stepper-no-button ant-m-stepper-bg-teal !w-full"
                                  />
                                </Form.Item>

                                <Form.Item label="설치">
                                  <Stepper
                                    min={0}
                                    value={
                                      +(
                                        selectedData.nstlSttsCode === 'PRCD'
                                          ? dynamicWorkEslVoList?.[
                                              item.itemNmbr
                                            ]?.installCnt || 0
                                          : item.nstlQntt || 0
                                      )
                                        .toString()
                                        .replaceAll(',', '')
                                    }
                                    formatter={(value?: number) =>
                                      appUtil.getFormatNumber(value)
                                    }
                                    onChange={(value: any) => {
                                      // 작업대기 상태일 때만 실행함
                                      if (
                                        selectedData.nstlSttsCode === 'PRCD'
                                      ) {
                                        setDynamicWorkEslVoList((pre: any) => ({
                                          ...pre,
                                          [item.itemNmbr]: {
                                            ...pre[item.itemNmbr],
                                            installCnt: value,
                                          },
                                        }));
                                      }
                                    }}
                                    inputReadOnly={
                                      selectedData.nstlSttsCode !== 'PRCD'
                                    }
                                    className="ant-m-stepper ant-m-stepper-no-button !w-full"
                                  />
                                </Form.Item>

                                <Form.Item label="여유">
                                  <Stepper
                                    min={0}
                                    value={
                                      +(
                                        selectedData.nstlSttsCode === 'PRCD'
                                          ? dynamicWorkEslVoList?.[
                                              item.itemNmbr
                                            ]?.extraCnt || 0
                                          : item.freQntt || 0
                                      )
                                        .toString()
                                        .replaceAll(',', '')
                                    }
                                    formatter={(value?: number) =>
                                      appUtil.getFormatNumber(value)
                                    }
                                    onChange={(value: any) => {
                                      // 작업대기 상태일 때만 실행함
                                      if (
                                        selectedData.nstlSttsCode === 'PRCD'
                                      ) {
                                        setDynamicWorkEslVoList((pre: any) => ({
                                          ...pre,
                                          [item.itemNmbr]: {
                                            ...pre[item.itemNmbr],
                                            extraCnt: value,
                                          },
                                        }));
                                      }
                                    }}
                                    inputReadOnly={
                                      selectedData.nstlSttsCode !== 'PRCD'
                                    }
                                    className="ant-m-stepper ant-m-stepper-no-button !w-full"
                                  />
                                </Form.Item>

                                <Form.Item label="분실">
                                  <Stepper
                                    min={0}
                                    value={
                                      +(
                                        selectedData.nstlSttsCode === 'PRCD'
                                          ? dynamicWorkEslVoList?.[
                                              item.itemNmbr
                                            ]?.lossCnt || 0
                                          : item.lostQntt || 0
                                      )
                                        .toString()
                                        .replaceAll(',', '')
                                    }
                                    formatter={(value?: number) =>
                                      appUtil.getFormatNumber(value)
                                    }
                                    onChange={(value: any) => {
                                      // 작업대기 상태일 때만 실행함
                                      if (
                                        selectedData.nstlSttsCode === 'PRCD'
                                      ) {
                                        setDynamicWorkEslVoList((pre: any) => ({
                                          ...pre,
                                          [item.itemNmbr]: {
                                            ...pre[item.itemNmbr],
                                            lossCnt: value,
                                          },
                                        }));
                                      }
                                    }}
                                    inputReadOnly={
                                      selectedData.nstlSttsCode !== 'PRCD'
                                    }
                                    className="ant-m-stepper ant-m-stepper-no-button ant-m-stepper-bg-amber !w-full"
                                  />
                                </Form.Item>

                                <Form.Item label="파손">
                                  <Stepper
                                    min={0}
                                    value={
                                      +(
                                        selectedData.nstlSttsCode === 'PRCD'
                                          ? dynamicWorkEslVoList?.[
                                              item.itemNmbr
                                            ]?.badCnt || 0
                                          : item.brkgQntt || 0
                                      )
                                        .toString()
                                        .replaceAll(',', '')
                                    }
                                    formatter={(value?: number) =>
                                      appUtil.getFormatNumber(value)
                                    }
                                    onChange={(value: any) => {
                                      // 작업대기 상태일 때만 실행함
                                      if (
                                        selectedData.nstlSttsCode === 'PRCD'
                                      ) {
                                        setDynamicWorkEslVoList((pre: any) => ({
                                          ...pre,
                                          [item.itemNmbr]: {
                                            ...pre[item.itemNmbr],
                                            badCnt: value,
                                          },
                                        }));
                                      }
                                    }}
                                    inputReadOnly={
                                      selectedData.nstlSttsCode !== 'PRCD'
                                    }
                                    className="ant-m-stepper ant-m-stepper-no-button ant-m-stepper-bg-amber !w-full"
                                  />
                                </Form.Item>

                                <Form.Item label="불량">
                                  <Stepper
                                    min={0}
                                    value={
                                      +(
                                        selectedData.nstlSttsCode === 'PRCD'
                                          ? dynamicWorkEslVoList?.[
                                              item.itemNmbr
                                            ]?.breakCnt || 0
                                          : item.porQntt || 0
                                      )
                                        .toString()
                                        .replaceAll(',', '')
                                    }
                                    formatter={(value?: number) =>
                                      appUtil.getFormatNumber(value)
                                    }
                                    onChange={(value: any) => {
                                      // 작업대기 상태일 때만 실행함
                                      if (
                                        selectedData.nstlSttsCode === 'PRCD'
                                      ) {
                                        setDynamicWorkEslVoList((pre: any) => ({
                                          ...pre,
                                          [item.itemNmbr]: {
                                            ...pre[item.itemNmbr],
                                            breakCnt: value,
                                          },
                                        }));
                                      }
                                    }}
                                    inputReadOnly={
                                      selectedData.nstlSttsCode !== 'PRCD'
                                    }
                                    className="ant-m-stepper ant-m-stepper-no-button ant-m-stepper-bg-amber !w-full"
                                  />
                                </Form.Item>
                              </div>

                              {/* 작업대기일 때만 출력 */}
                              {/* 재고 이동 수량 */}
                              {selectedData.nstlSttsCode === 'PRCD' && (
                                <div className="grid grid-cols-3 gap-x-2">
                                  <Form.Item label="추가요청">
                                    <Stepper
                                      min={0}
                                      value={
                                        dynamicStockMovementVoList?.[
                                          item.itemNmbr
                                        ]?.addCnt || 0
                                      }
                                      formatter={(value?: number) =>
                                        appUtil.getFormatNumber(value)
                                      }
                                      onChange={(value: any) => {
                                        setDynamicStockMovementVoList(
                                          (pre: any) => ({
                                            ...pre,
                                            [item.itemNmbr]: {
                                              ...pre[item.itemNmbr],
                                              addCnt: value,
                                            },
                                          }),
                                        );
                                      }}
                                      className="ant-m-stepper ant-m-stepper-no-button !w-full"
                                    />
                                  </Form.Item>

                                  <Form.Item label="반납(일반)요청">
                                    <Stepper
                                      min={0}
                                      value={
                                        dynamicStockMovementVoList?.[
                                          item.itemNmbr
                                        ]?.returnNormalCnt || 0
                                      }
                                      formatter={(value?: number) =>
                                        appUtil.getFormatNumber(value)
                                      }
                                      onChange={(value: any) => {
                                        setDynamicStockMovementVoList(
                                          (pre: any) => ({
                                            ...pre,
                                            [item.itemNmbr]: {
                                              ...pre[item.itemNmbr],
                                              returnNormalCnt: value,
                                            },
                                          }),
                                        );
                                      }}
                                      className="ant-m-stepper ant-m-stepper-no-button ant-m-stepper-bg-amber !w-full"
                                    />
                                  </Form.Item>

                                  <Form.Item label="반납(불량)요청">
                                    <Stepper
                                      min={0}
                                      value={
                                        dynamicStockMovementVoList?.[
                                          item.itemNmbr
                                        ]?.returnBadCnt || 0
                                      }
                                      formatter={(value?: number) =>
                                        appUtil.getFormatNumber(value)
                                      }
                                      onChange={(value: any) => {
                                        setDynamicStockMovementVoList(
                                          (pre: any) => ({
                                            ...pre,
                                            [item.itemNmbr]: {
                                              ...pre[item.itemNmbr],
                                              returnBadCnt: value,
                                            },
                                          }),
                                        );
                                      }}
                                      className="ant-m-stepper ant-m-stepper-no-button ant-m-stepper-bg-amber !w-full"
                                    />
                                  </Form.Item>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  ),
                )}
              </>
            )}

            {/* 작업구분이 네트워크일 때 출력 */}
            {/* PM검수 페이지에서 진입했을 때 출력 */}
            {(selectedData.splrTypeCode === 'NTWR_CNST' ||
              integrateData === true) && (
              <>
                {_.uniqBy(detailData?.workNtwrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <div key={teamIndex} className="space-y-3">
                      <StickyContentHeader
                        title={`${
                          teamItem.teamName || '팀 정보 없음'
                        } - 설치 정보`}
                        className="top-24"
                      />

                      {/* 작업대기 상태일 때 */}
                      {selectedData.nstlSttsCode === 'PRCD' && (
                        <>
                          <Form.Item label="G/W IP *">
                            <div className="flex justify-center items-center">
                              <Input
                                placeholder={
                                  selectedData.nstlSttsCode === 'PRCD'
                                    ? 'G/W IP를 입력하세요.'
                                    : ''
                                }
                                clearable={true}
                                value={dynamicWorkNtwrVoList?.gwIp || ''}
                                onChange={(value: any) => {
                                  setDynamicWorkNtwrVoList((pre: any) => ({
                                    ...pre,
                                    gwIp: value,
                                  }));
                                }}
                                readOnly={selectedData.nstlSttsCode !== 'PRCD'}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item label="Server IP">
                            <div className="flex justify-center items-center">
                              <Input
                                placeholder={
                                  selectedData.nstlSttsCode === 'PRCD'
                                    ? 'Server IP를 입력하세요.'
                                    : ''
                                }
                                clearable={true}
                                value={dynamicWorkNtwrVoList?.serverIp || ''}
                                onChange={(value: any) => {
                                  setDynamicWorkNtwrVoList((pre: any) => ({
                                    ...pre,
                                    serverIp: value,
                                  }));
                                }}
                                readOnly={selectedData.nstlSttsCode !== 'PRCD'}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item label="MAC Address">
                            <div className="flex justify-center items-center">
                              <Input
                                placeholder={
                                  selectedData.nstlSttsCode === 'PRCD'
                                    ? 'MAC Address를 입력하세요.'
                                    : ''
                                }
                                clearable={true}
                                value={dynamicWorkNtwrVoList?.macAddress || ''}
                                onChange={(value: any) => {
                                  setDynamicWorkNtwrVoList((pre: any) => ({
                                    ...pre,
                                    macAddress: value,
                                  }));
                                }}
                                readOnly={selectedData.nstlSttsCode !== 'PRCD'}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item label="G/W F/W Version">
                            <div className="flex justify-center items-center">
                              <Input
                                placeholder={
                                  selectedData.nstlSttsCode === 'PRCD'
                                    ? 'G/W F/W Version을 입력하세요.'
                                    : ''
                                }
                                clearable={true}
                                value={dynamicWorkNtwrVoList?.gwFwVersion || ''}
                                onChange={(value: any) => {
                                  setDynamicWorkNtwrVoList((pre: any) => ({
                                    ...pre,
                                    gwFwVersion: value,
                                  }));
                                }}
                                readOnly={selectedData.nstlSttsCode !== 'PRCD'}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>
                        </>
                      )}

                      {/* 작업대기 상태가 아닐 때 */}
                      {selectedData.nstlSttsCode !== 'PRCD' && (
                        <>
                          <Form.Item label="G/W IP">
                            <div className="flex justify-center items-center">
                              <Input
                                value={
                                  _.find(detailData?.workNtwrVoList, {
                                    teamId: teamItem.teamId,
                                    ntwrWorkCode: 'GTWY_IP',
                                  })?.ttl || ''
                                }
                                readOnly={true}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item label="Server IP">
                            <div className="flex justify-center items-center">
                              <Input
                                value={
                                  _.find(detailData?.workNtwrVoList, {
                                    teamId: teamItem.teamId,
                                    ntwrWorkCode: 'SRVR_IP',
                                  })?.ttl || ''
                                }
                                readOnly={true}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item label="MAC Address">
                            <div className="flex justify-center items-center">
                              <Input
                                value={
                                  _.find(detailData?.workNtwrVoList, {
                                    teamId: teamItem.teamId,
                                    ntwrWorkCode: 'MAC_ADDR',
                                  })?.ttl || ''
                                }
                                readOnly={true}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>

                          <Form.Item label="G/W F/W Version">
                            <div className="flex justify-center items-center">
                              <Input
                                value={
                                  _.find(detailData?.workNtwrVoList, {
                                    teamId: teamItem.teamId,
                                    ntwrWorkCode: 'GTWY_FW_VER',
                                  })?.ttl || ''
                                }
                                readOnly={true}
                                className="ant-m-input"
                              />
                            </div>
                          </Form.Item>
                        </>
                      )}
                    </div>
                  ),
                )}
              </>
            )}

            {/* 작업구분이 납품일 때 출력 */}
            {/* PM검수 페이지에서 진입했을 때 출력 */}
            {(selectedData.splrTypeCode === 'ESL_DLVR' ||
              selectedData.splrTypeCode === '' ||
              selectedData.splrTypeCode === null ||
              integrateData === true) && (
              <div className="space-y-3">

                {/* 작업완료일 */}
                {selectedData.splrTypeCode === 'ESL_DLVR' &&
                integrateData === true && (
                  <div className="space-y-3">
                    <StickyContentHeader
                      title="작업완료일222"
                      className="top-24"
                    />

                    <Form.Item label="작업완료일">
                      {/* 텍스트 입력 */}
                      <Input
                        type="date"
                        placeholder="작업완료일을 입력하세요.."
                        clearable={true}
                        value={dueDateInput}
                        onChange={handleDueDateInput_onChange}
                        className="ant-m-input"
                      />
                    </Form.Item>
                  </div>
                )}
                
                <StickyContentHeader title="납품 정보" className="top-24" />

                {detailData?.workDlvrVoList.map((item: any, index: number) => (
                  <div key={index}>
                    {index > 0 && <div className="divider-y" />}

                    <div className="space-y-3">
                      <div className="grow">
                        <Form.Item label={`품목(${item.itemNmbr})`}>
                          <span className="text-base text-gray-900 font-medium">
                            <Ellipsis
                              direction="end"
                              content={decode(item.itemDtls)}
                            />
                          </span>
                        </Form.Item>
                      </div>

                      {/* 납품 수량 */}
                      <div className="grid grid-cols-2 gap-x-2">
                        <Form.Item label="수량">
                          <div className="h-10 flex justify-center items-center bg-blue-200 rounded">
                            <span className="text-base text-gray-800 font-semibold">
                              {appUtil.getFormatNumber(item.qntt || 0)}
                            </span>
                          </div>
                        </Form.Item>

                        <Form.Item label="납품창고">
                          <div className="h-10 flex justify-center items-center bg-slate-200 rounded">
                            <span className="text-base text-gray-800 font-semibold">
                              {item.wrhsName}
                            </span>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Tabs.Tab>

        {/* 실적등록 화면 + (작업대기, 작업완료 상태일 때만) */}
        {/* PM검수 화면 + 전체 상태 가능 */}
        {/* 결과등록 */}
        {((integrateData === false &&
          (selectedData.nstlSttsCode === 'PRCD' ||
            (selectedData.nstlSttsCode === 'WORK_CMPL' &&
              selectedData.splrTypeCode !== 'ESL_DLVR'))) ||
          (integrateData === true &&
            (selectedData.nstlSttsCode === 'WORK_CMPL' ||
              selectedData.nstlSttsCode === 'NSPC_CMPL'))) && (
          <Tabs.Tab
            key="comment"
            title={
              selectedData.nstlSttsCode === 'PRCD' ? '결과등록' : '검수결과등록'
            }
          >
            <div className="ant-m-tabs-content space-y-10">
              {/* 소프트웨어 버전 - (PM검수완료할 때만 출력) */}
              {selectedData.nstlSttsCode === 'NSPC_CMPL' &&
                integrateData === true && (
                  <div className="space-y-3">
                    <StickyContentHeader
                      title="소프트웨어"
                      className="top-24"
                    />

                    <Form.Item label="소프트웨어 버전">
                      {/* 텍스트 입력 */}
                      <Input
                        placeholder="소프트웨어 버전을 입력하세요."
                        clearable={true}
                        value={softwareVersionInput}
                        onChange={handleSoftwareVersionInput_onChange}
                        className="ant-m-input"
                      />
                    </Form.Item>
                  </div>
                )}
                {/* 작업완료일 - (PM검수완료할 때만 출력) */}
                {selectedData.nstlSttsCode === 'NSPC_CMPL' &&
                integrateData === true && (
                  <div className="space-y-3">
                    <StickyContentHeader
                      title="작업완료일"
                      className="top-24"
                    />

                    <Form.Item label="작업완료일">
                      {/* 텍스트 입력 */}
                      <Input
                        type="date"
                        placeholder="작업완료일을 입력하세요.."
                        clearable={true}
                        value={dueDateInput}
                        onChange={handleDueDateInput_onChange}
                        className="ant-m-input"
                      />
                    </Form.Item>
                  </div>
                )}

              {/* 일반 */}
              <div className="space-y-3">
                <StickyContentHeader
                  title="일반 첨부파일"
                  rightArea={
                    <Button
                      fill="none"
                      onClick={handleModifyNormalCommentButton_onClick}
                      className="!bg-white"
                    >
                      <div className="flex justify-center items-center leading-none">
                        <span className="text-sm text-gray-600 font-semibold">
                          작성
                        </span>
                      </div>
                    </Button>
                  }
                  className="top-24"
                />

                <Form.Item label="내용">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decode(
                        (dynamicNormalComment?.content || '').replace(
                          /\n/g,
                          '<br />',
                        ),
                      ),
                    }}
                    className="ant-m-span-content"
                  />
                </Form.Item>

                <div className="divider-y" />

                <Form.Item label="첨부파일">
                  <FileDownloadList
                    data={dynamicNormalComment?.fileList || []}
                  />
                </Form.Item>

                <div className="divider-y" />

                <Form.Item label="미리보기">
                  <ImageFilePreviewList
                    data={dynamicNormalComment?.fileList || []}
                  />
                </Form.Item>
              </div>

              {/* 설치 확인서 */}
              <div className="space-y-3">
                <StickyContentHeader
                  title="설치 확인서"
                  rightArea={
                    <div className="flex justify-center items-center space-x-2">
                      <Button
                        fill="none"
                        onClick={handleExcelDownloadButton_onClick}
                        className="!bg-white"
                      >
                        <div className="flex justify-center items-center leading-none">
                          <span className="text-sm text-gray-600 font-semibold">
                            양식다운로드
                          </span>
                        </div>
                      </Button>

                      <Button
                        fill="none"
                        onClick={handleModifyInstallCommentButton_onClick}
                        className="!bg-white"
                      >
                        <div className="flex justify-center items-center leading-none">
                          <span className="text-sm text-gray-600 font-semibold">
                            작성
                          </span>
                        </div>
                      </Button>
                    </div>
                  }
                  className="top-24"
                />

                <Form.Item label="내용">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decode(
                        (dynamicInstallComment?.content || '').replace(
                          /\n/g,
                          '<br />',
                        ),
                      ),
                    }}
                    className="ant-m-span-content"
                  />
                </Form.Item>

                <div className="divider-y" />

                <Form.Item label="첨부파일">
                  <FileDownloadList
                    data={dynamicInstallComment?.fileList || []}
                  />
                </Form.Item>

                <div className="divider-y" />

                <Form.Item label="미리보기">
                  <ImageFilePreviewList
                    data={dynamicInstallComment?.fileList || []}
                  />
                </Form.Item>
              </div>
            </div>
          </Tabs.Tab>
        )}

        {/* 작업완료결과 */}
        {/* 작업완료, 검수완료, PM검수완료 상태일 때만 출력 */}
        {detailData !== null &&
          (selectedData.nstlSttsCode === 'WORK_CMPL' ||
            selectedData.nstlSttsCode === 'NSPC_CMPL' ||
            selectedData.nstlSttsCode === 'PM_NSPC_CMPL') && (
            <Tabs.Tab key="work-cmpl-comment" title="작업완료결과">
              <div className="ant-m-tabs-content space-y-10">
                {/* 완료결과가 없음 */}
                {detailData?.workPrgrVoList?.filter(
                  (filterItem: any) => filterItem.nstlSttsCode === 'WORK_CMPL',
                ).length === 0 && (
                  <div className="space-y-3">
                    <StickyContentHeader
                      title="입력된 작업완료 결과 없음"
                      className="top-24"
                    />
                  </div>
                )}

                {_.uniqBy(detailData?.workPrgrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <>
                      {detailData?.workPrgrVoList.filter(
                        (filterItem: any) =>
                          (filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'WORK_CMPL' &&
                            filterItem.nstlFileCode === 'CMN') ||
                          (filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'WORK_CMPL' &&
                            filterItem.nstlFileCode === 'NSTL_CNFR'),
                      ).length > 0 && (
                        <div key={teamIndex} className="space-y-10">
                          {/* 일반 */}
                          {detailData?.workPrgrVoList
                            .filter(
                              (filterItem: any) =>
                                filterItem.teamId === teamItem.teamId &&
                                filterItem.nstlSttsCode === 'WORK_CMPL' &&
                                filterItem.nstlFileCode === 'CMN',
                            )
                            .map((item: any, index: number) => {
                              // 첨부파일 목록을 정의함
                              let tmpFileList: any[] = [];

                              // 첨부파일 목록을 생성함
                              item.fileInfoList.map((fileItem: any) => {
                                tmpFileList.push({
                                  id: fileItem.fileId,
                                  originalFileName: fileItem.rgnlFileName,
                                  saveFileName: fileItem.pldFileName,
                                  size: fileItem.fileSize,
                                });
                              });

                              return (
                                <div key={index} className="space-y-3">
                                  <StickyContentHeader
                                    title={`${teamItem.teamName} - 일반 첨부파일`}
                                    className="top-24"
                                  />

                                  <Form.Item label="내용">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: decode(
                                          (item.dtl || '').replace(
                                            /\n/g,
                                            '<br />',
                                          ),
                                        ),
                                      }}
                                      className="ant-m-span-content"
                                    />
                                  </Form.Item>

                                  <div className="divider-y" />

                                  <Form.Item label="첨부파일">
                                    <FileDownloadList data={tmpFileList} />
                                  </Form.Item>

                                  <div className="divider-y" />

                                  <Form.Item label="미리보기">
                                    <ImageFilePreviewList data={tmpFileList} />
                                  </Form.Item>
                                </div>
                              );
                            })}

                          {/* 설치 확인서 */}
                          {detailData.workPrgrVoList
                            .filter(
                              (filterItem: any) =>
                                filterItem.teamId === teamItem.teamId &&
                                filterItem.nstlSttsCode === 'WORK_CMPL' &&
                                filterItem.nstlFileCode === 'NSTL_CNFR',
                            )
                            .map((item: any, index: number) => {
                              // 첨부파일 목록을 정의함
                              let tmpFileList: any[] = [];

                              // 첨부파일 목록을 생성함
                              item.fileInfoList.map((fileItem: any) => {
                                tmpFileList.push({
                                  id: fileItem.fileId,
                                  originalFileName: fileItem.rgnlFileName,
                                  saveFileName: fileItem.pldFileName,
                                  size: fileItem.fileSize,
                                });
                              });

                              return (
                                <div key={index} className="space-y-3">
                                  <StickyContentHeader
                                    title={`${teamItem.teamName} - 설치 확인서`}
                                    className="top-24"
                                  />

                                  <Form.Item label="내용">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: decode(
                                          (item.dtl || '').replace(
                                            /\n/g,
                                            '<br />',
                                          ),
                                        ),
                                      }}
                                      className="ant-m-span-content"
                                    />
                                  </Form.Item>

                                  <div className="divider-y" />

                                  <Form.Item label="첨부파일">
                                    <FileDownloadList data={tmpFileList} />
                                  </Form.Item>

                                  <div className="divider-y" />

                                  <Form.Item label="미리보기">
                                    <ImageFilePreviewList data={tmpFileList} />
                                  </Form.Item>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </>
                  ),
                )}
              </div>
            </Tabs.Tab>
          )}

        {/* 검수완료결과 */}
        {/* 검수완료, PM검수완료 상태일 때만 출력 */}
        {detailData !== null &&
          (selectedData.nstlSttsCode === 'NSPC_CMPL' ||
            selectedData.nstlSttsCode === 'PM_NSPC_CMPL') && (
            <Tabs.Tab key="nspc-cmpl-comment" title="검수완료결과">
              <div className="ant-m-tabs-content space-y-10">
                {/* 완료결과가 없음 */}
                {detailData.workPrgrVoList.filter(
                  (filterItem: any) => filterItem.nstlSttsCode === 'NSPC_CMPL',
                ).length === 0 && (
                  <div className="space-y-3">
                    <StickyContentHeader
                      title="입력된 검수완료 결과 없음"
                      className="top-24"
                    />
                  </div>
                )}

                {_.uniqBy(detailData.workPrgrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <div key={teamIndex} className="space-y-10">
                      {/* 일반 */}
                      {detailData.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'CMN',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <StickyContentHeader
                                title={`${teamItem.teamName} - 일반 첨부파일`}
                                className="top-24"
                              />

                              <Form.Item label="내용">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: decode(
                                      (item.dtl || '').replace(/\n/g, '<br />'),
                                    ),
                                  }}
                                  className="ant-m-span-content"
                                />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="첨부파일">
                                <FileDownloadList data={tmpFileList} />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="미리보기">
                                <ImageFilePreviewList data={tmpFileList} />
                              </Form.Item>
                            </div>
                          );
                        })}
                      {/* 설치 확인서 */}
                      {detailData.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'NSTL_CNFR',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <StickyContentHeader
                                title={`${teamItem.teamName} - 설치 확인서`}
                                className="top-24"
                              />

                              <Form.Item label="내용">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: decode(
                                      (item.dtl || '').replace(/\n/g, '<br />'),
                                    ),
                                  }}
                                  className="ant-m-span-content"
                                />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="첨부파일">
                                <FileDownloadList data={tmpFileList} />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="미리보기">
                                <ImageFilePreviewList data={tmpFileList} />
                              </Form.Item>
                            </div>
                          );
                        })}
                    </div>
                  ),
                )}
              </div>
            </Tabs.Tab>
          )}

        {/* PM검수완료결과 */}
        {/* PM검수완료 상태일 때만 출력 */}
        {detailData !== null &&
          selectedData.nstlSttsCode === 'PM_NSPC_CMPL' && (
            <Tabs.Tab key="pm-nspc-cmpl-comment" title="PM검수완료결과">
              <div className="ant-m-tabs-content space-y-10">
                {/* 소프트웨어 버전 */}
                <div className="space-y-3">
                  <StickyContentHeader title="소프트웨어" className="top-24" />

                  <Form.Item label="소프트웨어 버전">
                    <span className="ant-m-span">
                      {detailData?.workBillingDtlResVo?.vrsnNfrm}
                    </span>
                  </Form.Item>

                  <Form.Item label="작업완료일">
                    <span className="ant-m-span">
                      {detailData?.workBillingDtlResVo?.dueDate}
                    </span>
                  </Form.Item>
                </div>

                {_.uniqBy(detailData.workPrgrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <div key={teamIndex} className="space-y-10">
                      {/* 일반 */}
                      {detailData.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'PM_NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'CMN',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <StickyContentHeader
                                title={`${teamItem.teamName} - 일반 첨부파일`}
                                className="top-24"
                              />

                              <Form.Item label="내용">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: decode(
                                      (item.dtl || '').replace(/\n/g, '<br />'),
                                    ),
                                  }}
                                  className="ant-m-span-content"
                                />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="첨부파일">
                                <FileDownloadList data={tmpFileList} />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="미리보기">
                                <ImageFilePreviewList data={tmpFileList} />
                              </Form.Item>
                            </div>
                          );
                        })}

                      {/* 설치 확인서 */}
                      {detailData.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'PM_NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'NSTL_CNFR',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <StickyContentHeader
                                title={`${teamItem.teamName} - 설치 확인서`}
                                className="top-24"
                              />

                              <Form.Item label="내용">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: decode(
                                      (item.dtl || '').replace(/\n/g, '<br />'),
                                    ),
                                  }}
                                  className="ant-m-span-content"
                                />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="첨부파일">
                                <FileDownloadList data={tmpFileList} />
                              </Form.Item>

                              <div className="divider-y" />

                              <Form.Item label="미리보기">
                                <ImageFilePreviewList data={tmpFileList} />
                              </Form.Item>
                            </div>
                          );
                        })}
                    </div>
                  ),
                )}

                {/* 완료결과가 없음 */}
                {detailData.workPrgrVoList.filter(
                  (filterItem: any) =>
                    filterItem.nstlSttsCode === 'PM_NSPC_CMPL',
                ).length === 0 && (
                  <div className="space-y-3">
                    <StickyContentHeader
                      title="입력된 PM검수완료 결과 없음"
                      className="top-24"
                    />
                  </div>
                )}
              </div>
            </Tabs.Tab>
          )}

        {/* 프로젝트 탭 */}
        <Tabs.Tab key="project" title="프로젝트">
          <div className="ant-m-tabs-content space-y-3">
            <div>
              <Form.Item label="프로젝트명">
                <span className="ant-m-span">
                  {decode(detailData?.prjcName || '')}
                </span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="프로젝트코드">
                <span className="ant-m-span">{detailData?.prjcCode}</span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="프로젝트일정">
                <span className="ant-m-span">
                  {detailData?.fctvStrtDate} ~ {detailData?.fctvEndDate}
                </span>
              </Form.Item>
            </div>
          </div>
        </Tabs.Tab>

        {/* 계약 탭 */}
        <Tabs.Tab key="contract" title="계약">
          <div className="ant-m-tabs-content space-y-3">
            <div>
              <Form.Item label="계약명">
                <span className="ant-m-span">
                  {decode(detailData?.cntrName || '')}
                </span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="계약번호">
                <span className="ant-m-span">{detailData?.cntrScrnNmbr}</span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div className="grid grid-cols-2 gap-5">
              <Form.Item label="고객사">
                <span className="ant-m-span">
                  {decode(detailData?.bpName || '')}
                </span>
              </Form.Item>

              <Form.Item label="고객사(BP)코드">
                <span className="ant-m-span">{detailData?.bpCode}</span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="영업사원">
                <span className="ant-m-span">{detailData?.slspName}</span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="담당PM">
                <span className="ant-m-span">{detailData?.pmName}</span>
              </Form.Item>
            </div>
          </div>
        </Tabs.Tab>

        {/* 주문 탭 */}
        <Tabs.Tab key="order" title="주문">
          <div className="ant-m-tabs-content space-y-3">
            <div>
              <Form.Item label="주문번호">
                <span className="ant-m-span">
                  {decode(detailData?.dcmnScrnNmbr || '')}
                </span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="Shop명">
                <span className="ant-m-span">
                  {decode(detailData?.shipToCode || '')}
                </span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="Shop주소">
                <span className="ant-m-span">
                  {decode(detailData?.dlvrDrs || '')}
                </span>
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="설치예정일">
                <span className="ant-m-span">{detailData?.xpctDate}</span>
              </Form.Item>
            </div>

            {/* 실적등록, 작업검수 페이지에서만 사용함 */}
            {/*{!integrateData && (*/}
            {/*  <>*/}
            <div className="divider-y" />

            {/*<div>*/}
            {/*  <Form.Item label="ESL설치일자">*/}
            {/*    <span className="ant-m-span">*/}
            {/*      {moment(selectedData?.xpctDateEsl || '').format('YYYY.MM.DD')}*/}
            {/*    </span>*/}
            {/*  </Form.Item>*/}
            {/*</div>*/}

            {/*<div className="divider-y" />*/}

            {/*<div>*/}
            {/*  <Form.Item label="네트워크설치일자">*/}
            {/*    <span className="ant-m-span">*/}
            {/*      {moment(selectedData?.xpctDateNtwr || '').format(*/}
            {/*        'YYYY.MM.DD',*/}
            {/*      )}*/}
            {/*    </span>*/}
            {/*  </Form.Item>*/}
            {/*</div>*/}

            {/*<div className="divider-y" />*/}

            <div>
              <Form.Item label="작업완료">
                {networkInstallDate?.workCmplDate && (
                  <span className="ant-m-span">
                    {moment(networkInstallDate?.workCmplDate || '').format(
                      'YYYY.MM.DD HH:mm',
                    )}
                  </span>
                )}
                {networkInstallDate?.workCmplNm && (
                  <>
                    <br />
                    <span className="ant-m-span">
                      {networkInstallDate?.workCmplNm}
                    </span>
                  </>
                )}
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="검수완료">
                {networkInstallDate?.nspcCmplDate && (
                  <span className="ant-m-span">
                    {moment(networkInstallDate?.nspcCmplDate || '').format(
                      'YYYY.MM.DD HH:mm',
                    )}
                  </span>
                )}
                {networkInstallDate?.nspcCmplNm && (
                  <>
                    <br />
                    <span className="ant-m-span">
                      {networkInstallDate?.nspcCmplNm}
                    </span>
                  </>
                )}
              </Form.Item>
            </div>

            <div className="divider-y" />

            <div>
              <Form.Item label="PM검수완료">
                {detailData?.workBillingDtlResVo?.workDate && (
                  <span className="ant-m-span">
                    {moment(
                      detailData?.workBillingDtlResVo?.workDate || '',
                    ).format('YYYY.MM.DD HH:mm')}
                  </span>
                )}
                {detailData?.workBillingDtlResVo?.pmNspcCmplNm && (
                  <>
                    <br />
                    <span className="ant-m-span">
                      {detailData?.workBillingDtlResVo?.pmNspcCmplNm}
                    </span>
                  </>
                )}
              </Form.Item>
            </div>
            {/*  </>*/}
            {/*)}*/}
          </div>
        </Tabs.Tab>
      </Tabs>

      {/* 팝업 하단 버튼 */}
      <PopupBottomButtonArea>
        <div className="w-full flex justify-between items-center space-x-2">
          {/* 도움말 */}
          {/* PM검수 화면 + 검수완료된 상태일 때만 출력 */}
          {/* 버튼 */}
          {integrateData === true &&
          selectedData.nstlSttsCode === 'NSPC_CMPL' &&
          loginUser.isPartner === false &&
          detailData?.pmName === '' ? (
            <div className="leading-none">
              <span className="text-xs text-gray-600 font-semibold">
                담당PM을 지정하면
                <br />
                PM검수완료가 가능합니다.
              </span>
            </div>
          ) : (
            <div></div>
          )}

          {/* 하단 버튼 */}
          <div className="space-x-2">
            {/* 작업대기인 상태일 때만 출력 */}
            {/* 버튼 */}
            {selectedData.nstlSttsCode === 'PRCD' && (
              <Button
                fill="none"
                onClick={handleCheckListSaveButton_onClick}
                className="!bg-cyan-700"
              >
                <span className="text-base text-white">사전점검저장</span>
              </Button>
            )}
            {/* 작업대기인 상태일 때만 출력 */}
            {/* 버튼 */}
            {selectedData.nstlSttsCode === 'PRCD' && (
              <Button
                fill="none"
                onClick={handleTempSaveButton_onClick}
                className="!bg-black"
              >
                <span className="text-base text-white">임시저장</span>
              </Button>
            )}
            {/* 작업대기인 상태일 때만 출력 */}
            {/* 버튼 */}
            {selectedData.nstlSttsCode === 'PRCD' &&
              selectedData.splrTypeCode === 'ESL_NSTL' && (
                <Button
                  fill="none"
                  onClick={handleEslWorkCompleteButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">작업완료</span>
                </Button>
              )}
            {selectedData.nstlSttsCode === 'PRCD' &&
              selectedData.splrTypeCode === 'NTWR_CNST' && (
                <Button
                  fill="none"
                  onClick={handleNetworkWorkCompleteButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">작업완료</span>
                </Button>
              )}
            {selectedData.nstlSttsCode === 'PRCD' &&
              (selectedData.splrTypeCode === 'ESL_DLVR' ||
                selectedData.splrTypeCode === '' ||
                selectedData.splrTypeCode === null) && (
                <Button
                  fill="none"
                  onClick={handleDeliverWorkCompleteButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">작업완료</span>
                </Button>
              )}
            {/* 작업완료된 상태일 때만 출력 */}
            {/* 버튼 */}
            {selectedData.nstlSttsCode === 'WORK_CMPL' &&
              selectedData.splrTypeCode === 'ESL_NSTL' && (
                <Button
                  fill="none"
                  onClick={handleEslWorkCompleteCancelButton_onClick}
                  className="!bg-rose-700"
                >
                  <span className="text-base text-white">작업완료 취소</span>
                </Button>
              )}
            {selectedData.nstlSttsCode === 'WORK_CMPL' &&
              selectedData.splrTypeCode === 'NTWR_CNST' && (
                <Button
                  fill="none"
                  onClick={handleNetworkWorkCompleteCancelButton_onClick}
                  className="!bg-rose-700"
                >
                  <span className="text-base text-white">작업완료 취소</span>
                </Button>
              )}
            {selectedData.nstlSttsCode === 'WORK_CMPL' &&
              (selectedData.splrTypeCode === 'ESL_DLVR' ||
                selectedData.splrTypeCode === '' ||
                selectedData.splrTypeCode === null) && (
                <Button
                  fill="none"
                  onClick={handleDeliverWorkCompleteCancelButton_onClick}
                  className="!bg-rose-700"
                >
                  <span className="text-base text-white">작업완료 취소</span>
                </Button>
              )}
            {/* 작업완료된 상태일 때, 협력사가 아닐 때만 출력 */}
            {/* 버튼 */}
            {selectedData.nstlSttsCode === 'WORK_CMPL' &&
              selectedData.splrTypeCode === 'ESL_NSTL' &&
              loginUser.isPartner === false && (
                <Button
                  fill="none"
                  onClick={handleEslNspcCompleteButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">검수완료</span>
                </Button>
              )}
            {selectedData.nstlSttsCode === 'WORK_CMPL' &&
              selectedData.splrTypeCode === 'NTWR_CNST' &&
              loginUser.isPartner === false && (
                <Button
                  fill="none"
                  onClick={handleNetworkNspcCompleteButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">검수완료</span>
                </Button>
              )}
            {/* 검수완료된 상태일 때만 출력 */}
            {/* 버튼 */}
            {selectedData.nstlSttsCode === 'NSPC_CMPL' &&
              loginUser.isPartner === false && (
                <Button
                  fill="none"
                  onClick={handleNspcCompleteCancelButton_onClick}
                  className="!bg-rose-700"
                >
                  <span className="text-base text-white">검수완료 취소</span>
                </Button>
              )}
            {/* PM검수 화면 + 검수완료된 상태일 때만 출력 */}
            {/* 버튼 */}
            {integrateData === true &&
              selectedData.nstlSttsCode === 'NSPC_CMPL' &&
              loginUser.isPartner === false &&
              detailData?.pmName !== '' && (
                <Button
                  fill="none"
                  onClick={handlePmNspcCompleteButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">PM검수완료</span>
                </Button>
              )}
            {/* PM검수 화면 + PM검수완료된 상태일 때만 출력 */}
            {/* 버튼 */}
            {integrateData === true &&
              selectedData.nstlSttsCode === 'PM_NSPC_CMPL' &&
              loginUser.isPartner === false && (
                <Button
                  fill="none"
                  onClick={handlePmNspcCompleteCancelButton_onClick}
                  className="!bg-rose-700"
                >
                  <span className="text-base text-white">PM검수완료 취소</span>
                </Button>
              )}
            {/* 실적등록 화면 + PM검수완료된 상태일 때만 출력 */}
            {/* 버튼 */}
            {integrateData === false &&
              selectedData.nstlSttsCode === 'PM_NSPC_CMPL' && (
                <Button
                  fill="none"
                  onClick={handleClosePopupButton_onClick}
                  className="!bg-blue-700"
                >
                  <span className="text-base text-white">닫기</span>
                </Button>
              )}
          </div>
        </div>
      </PopupBottomButtonArea>
    </div>
  );
};

export default PerformanceRegistrationDetail;
