import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Button, Input, List } from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { IActionSheet } from '../../interfaces/app.interface';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { removePopupStore } from '../../stores/popup.store';

interface IPopupProps {
  id: string;
  data?: any | null;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 수량 수정
 * @param data 구분, 내용(신규일 때는 null)
 * @param onClick <이벤트>
 * @constructor
 */
const ModifyAmount = ({
  id,
  data,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 설치 수량 입력을 정의함
  const [installInput, setInstallInput] = useState<string>('');

  // 분실 수량 입력을 정의함
  const [lostInput, setLostInput] = useState<string>('');

  // 반납예정(일반) 수량 입력을 정의함
  const [normalReturnInput, setNormalReturnInput] = useState<string>('');

  // 반납예정(불량) 수량 입력을 정의함
  const [badReturnInput, setBadReturnInput] = useState<string>('');

  // 설치 수량 입력을 변경함
  const handleInstallInput_onChange = (event: any) => {
    setInstallInput(event);
  };

  // 분실 수량 입력을 변경함
  const handleLostInput_onChange = (event: any) => {
    setLostInput(event);
  };

  // 반납예정(일반) 수량 입력을 변경함
  const handleNormalReturnInput_onChange = (event: any) => {
    setNormalReturnInput(event);
  };

  // 반납예정(불량) 수량 입력을 변경함
  const handleBadReturnInput_onChange = (event: any) => {
    setBadReturnInput(event);
  };

  // 적용 버튼을 클릭함
  const handleApplyButton_onClick = () => {
    // 부모창으로 결과를 넘김
    callback({
      preData: data,
      resultType: '',
      nstlQntt: installInput.trim(),
      lostQntt: lostInput.trim(),
      rtrnNrmlQntt: normalReturnInput.trim(),
      rtrnDfctQntt: badReturnInput.trim(),
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  useEffect(() => {
    // 수정일 경우에 불러온 기본값을 적용함
    if (data !== null) {
      // 설치 수량 입력에 적용함
      setInstallInput(data.nstlQntt);

      // 분실 수량 입력에 적용함
      setLostInput(data.lostQntt);

      // 반납예정(일반) 수량 입력에 적용함
      setNormalReturnInput(data.rtrnNrmlQntt);

      // 반납예정(불량) 수량 입력에 적용함
      setBadReturnInput(data.rtrnDfctQntt);
    }

    return () => {};
  }, []);

  return (
    <div className="">
      {/* 편집 리스트 */}
      <List header="수량 정보" className="bg-gray-100/90">
        <List.Item title="설치">
          {/* 텍스트 입력 */}
          <Input
            placeholder="설치 수량을 입력하세요."
            clearable={true}
            value={installInput}
            onChange={handleInstallInput_onChange}
          />
        </List.Item>
        <List.Item title="분실">
          {/* 텍스트 입력 */}
          <Input
            placeholder="분실 수량을 입력하세요."
            clearable={true}
            value={lostInput}
            onChange={handleLostInput_onChange}
          />
        </List.Item>
        <List.Item title="반납예정(일반)">
          {/* 텍스트 입력 */}
          <Input
            placeholder="반납예정(일반) 수량을 입력하세요."
            clearable={true}
            value={normalReturnInput}
            onChange={handleNormalReturnInput_onChange}
          />
        </List.Item>
        <List.Item title="반납예정(불량)">
          {/* 텍스트 입력 */}
          <Input
            placeholder="반납예정(불량) 수량을 입력하세요."
            clearable={true}
            value={badReturnInput}
            onChange={handleBadReturnInput_onChange}
          />
        </List.Item>
      </List>

      <div className="p-3 space-y-2">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleApplyButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">적용</span>
        </Button>
      </div>
    </div>
  );
};

export default ModifyAmount;
