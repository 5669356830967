import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IApiResult } from '../../interfaces/app.interface';
import * as ntcbApi from '../../apis/ntcb.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { decode } from 'html-entities';
import { Form } from 'antd-mobile';
import FileDownloadList from '../../components/FileDownloadList/FileDownloadList.component';
import ImageFilePreviewList from '../../components/ImageFilePreviewList/ImageFilePreviewList.component';

interface IPopupProps {
  id: string;
  onClick?: () => void;
}

/**
 * 공지사항 > 조회
 * @param id <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const NoticeDetail = ({ id, onClick }: PropsWithChildren<IPopupProps>) => {
  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 첨부파일 이미지 미리보기를 정의함
  const [previewImage, setPreviewImage] = useState<string[]>([]);

  // 이미지 미리보기용 첨부파일 목록을 정의함
  const [previewFileImages, setPreviewFileImages] = useState<any[]>([]);

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    ntcbApi
      .getNotice({
        brdId: id,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          callback(data.data);

          // 이미지 파일의 확장자를 정의함
          let tmpImageFileExt: string[] = [
            '.jpg',
            '.jpeg',
            '.png',
            '.gif',
            '.bmp',
            '.tif',
          ];

          // 첨부파일 이미지 미리보기를 정의함
          let tmpPreviewImage: string[] = [];

          // 첨부파일 목록을 불러옴
          data.data.fileList.map((file: any) => {
            // 첨부파일에서 확장자를 추출함
            let tmpFileExt: string = _.padEnd(file.pldFileName, 5);

            // 이미지 파일의 확장자가 이미지인지 확인함
            tmpImageFileExt.map((item: string) => {
              if (_.includes(tmpFileExt, item)) {
                // 첨부파일 이미지 미리보기에 추가함
                tmpPreviewImage.push(
                  `${appUtil.getThumbnailImageUrl()}${file.pldFileName}`,
                );

                return;
              }
            });
          });

          // 첨부파일 이미지 미리보기에 적용함
          setPreviewImage(tmpPreviewImage);
        }
      });
  };

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    ntcbApi
      .getNncmFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 상세 내용에 적용함
      setDetailData(data);

      // 이미지 미리보기용 첨부파일 목록을 정의함
      let tmpFileList: any[] = [];

      // 이미지 미리보기용 첨부파일 목록을 생성함
      data.fileList.map((fileItem: any) => {
        tmpFileList.push({
          id: fileItem.fileId,
          originalFileName: fileItem.rgnlFileName,
          saveFileName: fileItem.pldFileName,
          size: fileItem.fileSize,
        });
      });

      // 이미지 미리보기용 첨부파일 목록에 적용함
      setPreviewFileImages(tmpFileList);
    });

    return () => {};
  }, []);

  return (
    <div className="">
      {detailData !== null && (
        <div className="space-y-5">
          {/* 게시물 정보 */}
          <div className="flex justify-between items-center">
            {/* 분류 */}
            <div className="">
              <span className="text-sm text-indigo-600 font-bold">
                {detailData.ntcTypeName}
              </span>
            </div>

            {/* 일자 */}
            <div className="flex justify-center items-center space-x-3">
              {/* 등록일자 */}
              <div className="flex justify-center items-center space-x-1">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['far', 'clock']}
                    className="w-3 h-3 text-gray-500"
                  />
                </div>

                <span className="text-sm text-gray-500">
                  {detailData.rgstDate}
                </span>
              </div>

              {/* 조회수 */}
              <div className="flex justify-center items-center space-x-1">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['far', 'eye']}
                    className="w-4 h-4 text-gray-500"
                  />
                </div>

                <span className="text-sm text-gray-500">{detailData.vws}</span>
              </div>
            </div>
          </div>

          {/* 내용 */}
          <div className="">
            {/* HTML 뷰어 */}
            <div dangerouslySetInnerHTML={{ __html: decode(detailData.dtl) }} />
            {/*<span className="text-base text-gray-600">{detailData.dtl}</span>*/}
          </div>

          <div className="divider-y" />

          <Form.Item label="첨부파일">
            <FileDownloadList data={previewFileImages || []} />
          </Form.Item>

          <div className="divider-y" />

          <Form.Item label="미리보기">
            <ImageFilePreviewList data={previewFileImages || []} />
          </Form.Item>

          {/* 첨부파일 이미지 미리보기 */}
          {/*{previewImage.length > 0 && (*/}
          {/*  <div className="pt-5 space-y-5">*/}
          {/*    {previewImage.map((file: string, index: number) => (*/}
          {/*      <div key={index} className="border">*/}
          {/*        <div className="px-3 py-2 bg-indigo-100/30">*/}
          {/*          <div*/}
          {/*            onClick={() =>*/}
          {/*              handleFileDownload_onClick(*/}
          {/*                detailData.fileList[index].fileId,*/}
          {/*                detailData.fileList[index].rgnlFileName,*/}
          {/*              )*/}
          {/*            }*/}
          {/*            className="button-event"*/}
          {/*          >*/}
          {/*            <span className="text-sm text-gray-500">*/}
          {/*              {detailData.fileList[index].rgnlFileName}*/}
          {/*            </span>*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <div>*/}
          {/*          <img src={file} className="w-full" />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/* 첨부파일 */}
          {/*{detailData.fileList.map((file: any, index: number) => (*/}
          {/*  <div key={index} className="px-3 py-2 bg-gray-100 rounded">*/}
          {/*    <div*/}
          {/*      onClick={() =>*/}
          {/*        handleFileDownload_onClick(file.fileId, file.rgnlFileName)*/}
          {/*      }*/}
          {/*      className="button-event"*/}
          {/*    >*/}
          {/*      <span className="text-sm text-gray-500">*/}
          {/*        {file.rgnlFileName}*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*))}*/}
        </div>
      )}
    </div>
  );
};

export default NoticeDetail;
