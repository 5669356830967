import React, { ReactElement, ReactNode, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import MainPage from './pages/Main.page';
import LoginPage from './pages/Login.page';
import NotFound from './pages/common/NotFound';
import NoticePage from './pages/Notice.page';
import ManualPage from './pages/Manual.page';
import FaqPage from './pages/Faq.page';

import './styles/app.style.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import StockRequestHistoryPage from './pages/StockRequestHistory.page';
import OngoingJobManagementPage from './pages/OngoingJobManagement.page';
import PmInspectionStandbyStatePage from './pages/PmInspectionStandbyState.page';
import PerformanceRegistrationPage from './pages/PerformanceRegistration.page';
import PmInspectionPage from './pages/PmInspection.page';
import NoticeOldPage from './pages/NoticeOld.page';
import FaqOldPage from './pages/FaqOld.page';
import ManualOldPage from './pages/ManualOld.page';

// FontAwesome 아이콘을 불러옴
library.add(fab, far, fas);

function App() {
  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* 메인 */}
        <Route path="/" element={<MainPage />} />

        {/* 로그인 */}
        <Route path="/login" element={<LoginPage />} />

        {/* 설치관리 > 실적등록 */}
        <Route
          path="/performance-registration"
          element={
            <PerformanceRegistrationPage defaultSelectedPageName="실적등록" />
          }
        />

        {/* 설치관리 > 작업검수 */}
        <Route
          path="/work-inspection"
          element={
            <PerformanceRegistrationPage defaultSelectedPageName="작업검수" />
          }
        />

        {/* 설치관리 > PM검수 */}
        <Route path="/pm-inspection" element={<PmInspectionPage />} />

        {/* 설치관리 > 진행 중 작업 관리 */}
        <Route
          path="/ongoing-job-management"
          element={<OngoingJobManagementPage />}
        />

        {/* 설치 관리 > PM 검수 대기 현황 */}
        <Route
          path="/pm-inspection-standby-state"
          element={<PmInspectionStandbyStatePage />}
        />

        {/* 현황 관리 > 재고 이동 요청 이력 */}
        <Route
          path="/stock-request-history"
          element={<StockRequestHistoryPage />}
        />

        {/* 게시판 > 메뉴얼 */}
        <Route path="/manual" element={<ManualPage />} />
        <Route path="/manual-old" element={<ManualOldPage />} />

        {/* 게시판 > 공지사항 */}
        <Route path="/notice" element={<NoticePage />} />
        <Route path="/notice-old" element={<NoticeOldPage />} />

        {/* 게시판 > F&Q */}
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/faq-old" element={<FaqOldPage />} />

        {/* 페이지를 찾을 수 없음 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
