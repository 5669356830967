import React, { PropsWithChildren, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import * as cmnApi from '../../apis/cmn.api';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { IApiResult, IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { getFindPassword } from '../../apis/cmn.api';
import { Button, Form, Input, Toast } from 'antd-mobile';
import * as appUtil from '../../utils/app.util';

interface IPopupProps {
  id?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * <Popups 이름>
 * @param id 화면 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @param callback 콜백 이벤트
 * @constructor
 */
const ChangeTmpPassword = ({
  id = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IPopupProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 임시 비밀번호 입력을 정의함
  const [tmpPassword, setTmpPassword] = useState<string>('');
  const tmpPasswordRef = useRef<any>(null);

  // 신규 비밀번호 입력을 정의함
  const [newPassword, setNewPassword] = useState<string>('');
  const newPasswordRef = useRef<any>(null);

  // 비밀번호 확인 입력을 정의함
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const confirmPasswordRef = useRef<any>(null);

  // 임시 비밀번호 입력의 값을 변경함
  const handleTmpPasswordInput_onChange = (event: any) => {
    setTmpPassword(event);
  };

  // 신규 비밀번호 입력의 값을 변경함
  const handleNewPasswordInput_onChange = (event: any) => {
    setNewPassword(event);
  };

  // 비밀번호 확인 입력의 값을 변경함
  const handleConfirmPasswordInput_onChange = (event: any) => {
    setConfirmPassword(event);
  };

  const handleSendEmailButton_onClick = () => {
    if (tmpPassword.trim() === '') {
      Toast.show({
        position: 'bottom',
        content: '임시 비밀번호를 입력하세요.',
      });

      tmpPasswordRef.current.focus();
      return;
    }

    if (newPassword.trim() === '') {
      Toast.show({
        position: 'bottom',
        content: '신규 비밀번호를 입력하세요.',
      });

      newPasswordRef.current.focus();
      return;
    }

    if (newPassword.trim().length < 8 || newPassword.trim().length > 12) {
      Toast.show({
        position: 'bottom',
        content: '신규 비밀번호는 8자리 이상 12자리 이하로 입력하세요.',
      });

      newPasswordRef.current.focus();
      return;
    }

    if (confirmPassword.trim() === '') {
      Toast.show({
        position: 'bottom',
        content: '확인 비밀번호를 입력하세요.',
      });

      confirmPasswordRef.current.focus();
      return;
    }

    if (
      confirmPassword.trim().length < 8 ||
      confirmPassword.trim().length > 12
    ) {
      Toast.show({
        position: 'bottom',
        content: '확인 비밀번호는 8자리 이상 12자리 이하로 입력하세요.',
      });

      confirmPasswordRef.current.focus();
      return;
    }

    if (newPassword === confirmPassword) {
    } else {
      Toast.show({
        position: 'bottom',
        content: '신규 비밀번호와 확인 비밀번호가 다릅니다.',
      });

      confirmPasswordRef.current.focus();
      return;
    }

    cmnApi
      .postChanagePassword({
        newPswr: newPassword,
        pswr: tmpPassword,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 비밀번호 변경 성공
          case '200':
            Toast.show({
              position: 'bottom',
              content: '비밀번호가 변경되었습니다.',
            });

            if (callback !== undefined) {
              callback(data);

              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
            }
            break;

          // 비밀번호 변경 실패
          case '500':
            console.log('> 비밀번호 변경 Error:', data);

            Toast.show({
              position: 'bottom',
              content: data.message,
            });
            break;

          default:
            break;
        }
      });
  };

  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('popup', id, false);

    return () => {};
  }, []);

  return (
    <div className="space-y-5">
      <div className="space-y-2">
        <Form.Item label="임시 비밀번호">
          <div className="flex justify-center items-center space-x-2">
            {/* 텍스트 입력 */}
            <Input
              ref={tmpPasswordRef}
              type="password"
              placeholder="임시 비밀번호를 입력하세요."
              clearable={true}
              value={tmpPassword}
              onChange={handleTmpPasswordInput_onChange}
              className="ant-m-input"
            />
          </div>
        </Form.Item>

        <Form.Item label="신규 비밀번호">
          <div className="flex justify-center items-center space-x-2">
            {/* 텍스트 입력 */}
            <Input
              ref={newPasswordRef}
              type="password"
              placeholder="신규 비밀번호를 입력하세요."
              clearable={true}
              onChange={handleNewPasswordInput_onChange}
              value={newPassword}
              className="ant-m-input"
            />
          </div>
        </Form.Item>

        <Form.Item label="비밀번호 확인">
          <div className="flex justify-center items-center space-x-2">
            {/* 텍스트 입력 */}
            <Input
              ref={confirmPasswordRef}
              type="password"
              placeholder="확인 비밀번호를 입력하세요."
              clearable={true}
              onChange={handleConfirmPasswordInput_onChange}
              value={confirmPassword}
              className="ant-m-input"
            />
          </div>
        </Form.Item>
      </div>

      <div className="flex justify-center items-center">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleSendEmailButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">비밀번호 재설정</span>
        </Button>
      </div>

      {/* 입력폼 */}
      {/*<div className="w-full space-y-2">*/}
      {/*  /!* 임시비밀번호 *!/*/}
      {/*  <div className="flex justify-center items-center space-x-2">*/}
      {/*    <div className="w-25 flex justify-start items-center">*/}
      {/*      <span className="text-sm text-gray-500 font-semibold">*/}
      {/*        임시 비밀번호*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*    <PasswordInput*/}
      {/*      placeholder="임시 비밀번호를 입력하세요."*/}
      {/*      onChange={handleTmpPasswordInput_onChange}*/}
      {/*      value={tmpPassword}*/}
      {/*      className="grow"*/}
      {/*    />*/}
      {/*  </div>*/}

      {/*  /!* 임시비밀번호 *!/*/}
      {/*  <div className="flex justify-center items-center space-x-2">*/}
      {/*    <div className="w-25 flex justify-start items-center">*/}
      {/*      <span className="text-sm text-gray-500 font-semibold">*/}
      {/*        신규 비밀번호*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*    <PasswordInput*/}
      {/*      placeholder="신규 비밀번호를 입력하세요."*/}
      {/*      onChange={handleNewPasswordInput_onChange}*/}
      {/*      value={newPassword}*/}
      {/*      className="grow"*/}
      {/*    />*/}
      {/*  </div>*/}

      {/*  /!* 이메일 주소 *!/*/}
      {/*  <div className="flex justify-center items-center space-x-2">*/}
      {/*    <div className="w-25 flex justify-start items-center">*/}
      {/*      <span className="text-sm text-gray-500 font-semibold">*/}
      {/*        비밀번호 확인*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*    <PasswordInput*/}
      {/*      placeholder="확인 비밀번호를 입력하세요."*/}
      {/*      onChange={handleConfirmPasswordInput_onChange}*/}
      {/*      value={confirmPassword}*/}
      {/*      className="grow"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* 버튼 */}
      {/*<div className="flex justify-center items-center">*/}
      {/*  /!* 버튼 *!/*/}
      {/*  <Button*/}
      {/*    color="indigo"*/}
      {/*    radius="xl"*/}
      {/*    onClick={handleSendEmailButton_onClick}*/}
      {/*    className="grow"*/}
      {/*  >*/}
      {/*    비밀번호 재설정*/}
      {/*  </Button>*/}
      {/*</div>*/}

      {/* 참고 */}
      {/* <div className="flex justify-center items-center">
        <span className="text-sm text-gray-500 font-semibold">
          비밀번호를 재설정 합니다.
        </span>
      </div> */}
    </div>
  );
};

export default ChangeTmpPassword;
