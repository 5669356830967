import React, { PropsWithChildren } from 'react';

interface IComponentProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 팝업 탭용 하단 버튼 영역
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PopupTabsBottomButtonArea = ({
  data,
  onClick,
  children,
}: PropsWithChildren<IComponentProps>) => {
  return (
    // <div className="sticky left-0 bottom-0 px-4 py-3 flex justify-end items-center bg-gray-100/50 backdrop-blur border-t border-gray-100 space-x-2 z-90">
    <div className="fixed left-0 bottom-0 w-full px-4 py-3 flex justify-end items-center bg-gray-100/80 backdrop-blur border-t border-gray-200/80 space-x-2 overflow-x-auto z-90">
      {children}
    </div>
  );
};

export default PopupTabsBottomButtonArea;
