import React, { PropsWithChildren, useEffect, useState } from 'react';
import * as csApi from '../../apis/cs.api';
import { IApiResult, IPopup } from '../../interfaces/app.interface';
import { Button, Ellipsis, Form, Input, List, Stepper } from 'antd-mobile';
import { useRecoilState } from 'recoil';
import { addPopupStore, removePopupStore } from '../../stores/popup.store';
import _ from 'lodash';
import { StickyContentHeader } from '../../components/StickyContentHeader';
import { decode } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';

interface IPopupProps {
  id?: string;
  data?: any;
  onClick?: () => void;
  callback?: any;
}

/**
 * 프로젝트 선택
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const SelectProject = ({
  id = '',
  data,
  onClick,
  callback = (param: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색어 입력을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event);
  };

  // 프로젝트 목록을 정의함
  const [project, setProject] = useState<any[]>([]);

  // 필터링된 프로젝트 목록을 정의함
  const [filterProject, setFilterProject] = useState<any[]>([]);

  // 프로젝트 목록을 불러옴
  const getProjectList = () => {
    // 프로젝트 목록을 불러옴
    csApi.getPrjcs({}).then((data: IApiResult) => {
      if (data.data.list !== undefined) {
        // 목록에 '전체' 항목을 추가함
        let tmpProject: { prjcCode: string; prjcName: string }[] = [];
        tmpProject.push({ prjcCode: '', prjcName: '전체' });

        // 프로젝트 목록에 적용함
        setProject([...tmpProject, ...data.data.list]);

        // 필터링된 프로젝트 목록에 적용함
        setFilterProject([...tmpProject, ...data.data.list]);
      }
    });
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 필터링된 품목 목록에 적용함
    setFilterProject(
      project.filter((item: any) =>
        _.includes(item.prjcName.toLowerCase(), searchInput.toLowerCase()),
      ),
    );
  };

  // 목록을 클릭함
  const handleList_onClick = (item: any) => {
    callback(item);

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('popup', id, false);

    // 프로젝트 목록을 불러옴
    getProjectList();

    return () => {};
  }, []);

  return (
    <div className="">
      <div className="ant-m-popup ant-m-popup-with-bottom-button space-y-5">
        {/* 검색 */}
        <div className="space-y-2">
          {/*<StickyContentHeader title="검색" className="top-14" />*/}

          <Form.Item label="검색">
            <div className="flex justify-center items-center space-x-2">
              {/* 텍스트 입력 */}
              <Input
                placeholder="검색어를 입력하세요."
                clearable={true}
                value={searchInput}
                onChange={handleSearchInput_onChange}
                onEnterPress={handleSearchButton_onClick}
                className="grow ant-m-input"
              />

              <Button
                fill="none"
                onClick={handleSearchButton_onClick}
                className="ant-m-button w-24 !bg-blue-700"
              >
                <span className="text-base text-white">검색</span>
              </Button>
            </div>
          </Form.Item>
        </div>

        {/* 프로젝트 */}
        <div className="space-y-2">
          {/*<StickyContentHeader title="프로젝트" className="top-14" />*/}

          <Form.Item label="프로젝트">
            {/*<List className="">*/}
            {/*  {listData.map((item: any, index: number) => (*/}
            {/*    <List.Item key={index}>*/}
            {/*      <div*/}
            {/*        onClick={() => handleList_onClick(item)}*/}
            {/*        className="button-event"*/}
            {/*      >*/}
            {/*        <div className="">*/}
            {/*          <span className="text-base text-gray-700">*/}
            {/*            {item.prjcName}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </List.Item>*/}
            {/*  ))}*/}
            {/*</List>*/}

            <div className="space-y-1">
              {filterProject.map((listItem: any, listIndex: number) => (
                <>
                  {listIndex > 0 && <div className="divider-y" />}

                  <div
                    key={listIndex}
                    onClick={() => handleList_onClick(listItem)}
                    className="button-bg-event py-2 flex space-x-3"
                  >
                    {/* 번호 */}
                    <div className="flex-none w-7 flex justify-center items-center">
                      <span className="text-sm text-gray-700">
                        {listIndex + 1}
                      </span>
                    </div>

                    <div className="grow flex justify-start items-center">
                      <div>
                        <div className="flex justify-start items-center space-x-2">
                          <span className="text-base text-gray-900 font-medium">
                            <Ellipsis
                              direction="end"
                              content={listItem.prjcName}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default SelectProject;
