import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IApiResult, IOverlay, IPopup } from '../../interfaces/app.interface';
import * as nstlApi from '../../apis/nstl.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { decode } from 'html-entities';
import {
  Button,
  Collapse,
  Dialog,
  Ellipsis,
  Input,
  List,
  Toast,
} from 'antd-mobile';
import { nanoid } from 'nanoid';
import { useRecoilState } from 'recoil';
import { addPopupStore, removePopupStore } from '../../stores/popup.store';
import PmInspectionStandbyStateDetailResult from '../PmInspectionStandbyStateDetailResult/PmInspectionStandbyStateDetailResult.popup';
import { FileButton } from '@mantine/core';
import prettyBytes from 'pretty-bytes';
import { ModifyFile } from '../ModifyFile';
import { overlayStore } from '../../stores/overlay.store';

interface IPopupProps {
  id: string;
  dcmnDntfNmbr: string;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * PM 검수 대기 현황 > 상세 조회
 * @param id <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PmInspectionStandbyStateDetail = ({
  id,
  dcmnDntfNmbr,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 화면 위 검은 화면 저장소를 정의함
  const [overlay, setOverlay] = useRecoilState<IOverlay>(overlayStore);

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 소프트웨어 버전 입력을 정의함
  const [softwareVersionInput, setSoftwareVersionInput] = useState<string>('');

  // 소프트웨어 버전 입력을 변경함
  const handleSoftwareVersionInput_onChange = (event: any) => {
    setSoftwareVersionInput(event);
  };

  // 항목의 값이 없을 때, 빈 값을 반환함
  const displayValue = (value: any): any => {
    return value ? value : <span className="text-gray-300">정보 없음</span>;
  };

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    nstlApi
      .getNstlPmNspcDetail({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 임시!!
          // data.data.fileList = [
          //   {
          //     fileDntfCode: 'PCTR',
          //     fileDscr: '파일 설명입니다.',
          //     fileId: 'aaaaa',
          //     fileSize: 12345,
          //     pldFileName:
          //       '파일명입니다_파일명입니다_파일명입니다_파일명입니다.jpg',
          //     rgnlFileName:
          //       '파일명입니다_파일명입니다_파일명입니다_파일명입니다.jpg',
          //   },
          //   {
          //     fileDntfCode: 'PCTR',
          //     fileDscr: '파일 설명입니다.',
          //     fileId: 'aaaaa',
          //     fileSize: 12345,
          //     pldFileName:
          //       '파일명입니다_파일명입니다_파일명입니다_파일명입니다.jpg',
          //     rgnlFileName:
          //       '파일명입니다_파일명입니다_파일명입니다_파일명입니다.jpg',
          //   },
          // ];

          callback(data.data);
        }
      });
  };

  // 설치 확인서 > 첨부파일 추가 버튼을 클릭함
  const handleAddFileButton_onClick = (event: any) => {
    if (event === null) {
      return;
    }

    // 첨부파일 목록을 불러옴
    let tmpFileList: any = _.cloneDeep(detailData.fileList);

    // 첨부파일 목록에 새로운 첨부파일을 추가함
    tmpFileList.push({
      fileId: nanoid(),
      csResultFileType: 'new',
      fileDntfCode: 'ETC',
      rgnlFileName: event.name,
      fileSize: event.size,
      fileDscr: '',
      newFile: event,
    });

    // 첨부파일 목록에 적용함
    setDetailData((pre: any) => ({
      ...pre,
      fileList: tmpFileList,
    }));
  };

  // 설치 확인서의 첨부파일 수정을 클릭함
  const handleFileList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '설치 확인서 - 첨부파일 수정',
      content: (
        <ModifyFile
          id={tmpId}
          file={event}
          callback={(result: any) => {
            // 첨부파일 목록을 불러옴
            let tmpFileList: any = _.cloneDeep(detailData.fileList);

            if (result.resultType === 'remove') {
              // 선택한 데이터를 삭제함
              tmpFileList = _.filter(tmpFileList, (item: any) => {
                return item.fileId !== result.preData.fileId;
              });
            } else {
              // 첨부파일 설명을 수정함
              tmpFileList
                .filter((item: any) => item.fileId === result.preData.fileId)
                .map((item: any) => {
                  item.fileDscr = result.fileDscr;
                });
            }

            // 첨부파일 목록에 적용함
            setDetailData((pre: any) => ({
              ...pre,
              fileList: tmpFileList,
            }));
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 70,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 네트워크 공사 작업 내역의 작업결과 버튼을 클릭함
  const handleNetworkList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: `${event.shipToCode || 'Shop 미지정'} - 네트워크 작업결과`,
      content: (
        <PmInspectionStandbyStateDetailResult
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          detailData={event}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 상세 내용에 적용함
              setDetailData(data);
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // ESL 설치 작업 내역의 작업결과 버튼을 클릭함
  const handleEslList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: `${event.shipToCode || 'Shop 미지정'} - ESL 작업결과`,
      content: (
        <PmInspectionStandbyStateDetailResult
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          detailData={event}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 상세 내용에 적용함
              setDetailData(data);
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // PM 검수 완료 버튼을 클릭함
  const handleApplyButton_onClick = () => {
    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 설명을 불러옴
    let tmpNewFileDescriptions: string[] = [];

    Dialog.confirm({
      content: 'PM 검수 완료로 처리하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위의 검은 화면 출력 여부에 적용함
        setOverlay((pre: IOverlay) => ({
          ...pre,
          visible: true,
        }));

        // 신규 첨부파일 정보를 저장함
        detailData.fileList
          .filter((item: any) => item.newFile !== null)
          .map((item: any) => {
            tmpNewFile.push(item.newFile);
            tmpNewFileDescriptions.push(item.fileDscr);
          });

        nstlApi
          .postNstlPmNspcDetail({
            dcmnDntfNmbr: dcmnDntfNmbr,
            vrsnNfrm: softwareVersionInput.trim(),
            fileList: tmpNewFile,
            fileDscrList: tmpNewFileDescriptions,
            bfFileIdList: tmpPreFileId,
            bfFileDscrList: tmpPreFileDescriptions,
          })
          .then((data: IApiResult) => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setOverlay((pre: IOverlay) => ({
              ...pre,
              visible: false,
            }));

            if (data.code === '200') {
              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 부모창으로 결과를 넘김
              callback({
                resultType: 'refresh',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: (
                  <div>
                    <div className="flex justify-center items-center">
                      <span>처리에 실패하였습니다.</span>
                    </div>
                    <div className="flex justify-center items-center">
                      <span>({data.message})</span>
                    </div>
                  </div>
                ),
              });
            }
          })
          .catch((error: any) => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setOverlay((pre: IOverlay) => ({
              ...pre,
              visible: false,
            }));

            Toast.show({
              position: 'bottom',
              content: (
                <div>
                  <div className="flex justify-center items-center">
                    <span>처리에 실패하였습니다.</span>
                  </div>
                  <div className="flex justify-center items-center">
                    <span>({error})</span>
                  </div>
                </div>
              ),
            });
          });
      },
    });
  };

  // PM 검수 취소 버튼을 클릭함
  const handleCancelButton_onClick = () => {
    Dialog.confirm({
      content: 'PM 검수 완료를 취소하시겠습니까?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: () => {
        // 화면 위의 검은 화면 출력 여부에 적용함
        setOverlay((pre: IOverlay) => ({
          ...pre,
          visible: true,
        }));

        nstlApi
          .putNstlPmNspcCancel({
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setOverlay((pre: IOverlay) => ({
              ...pre,
              visible: false,
            }));

            if (
              data.code === '200' ||
              data.code === '201' ||
              data.code === '204'
            ) {
              Toast.show({
                position: 'bottom',
                content: '처리하였습니다.',
              });

              // 부모창으로 결과를 넘김
              callback({
                resultType: 'refresh',
              });

              // 삭제할 팝업 저장소에 적용함
              setRemovePopup(id);
            } else {
              Toast.show({
                position: 'bottom',
                content: (
                  <div>
                    <div className="flex justify-center items-center">
                      <span>처리에 실패하였습니다.</span>
                    </div>
                    <div className="flex justify-center items-center">
                      <span>({data.message})</span>
                    </div>
                  </div>
                ),
              });
            }
          })
          .catch((error: any) => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setOverlay((pre: IOverlay) => ({
              ...pre,
              visible: false,
            }));

            Toast.show({
              position: 'bottom',
              content: '처리에 실패하였습니다.',
            });
          });
      },
    });
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 모든 작업이 완료되었는지 확인함
      if (
        data.installPrtnList.length ===
        data.installPrtnList.filter(
          (item: any) => item.nstlSttsCode === 'PM_NSPC_CMPL',
        ).length
      ) {
        // PM 검수가 완료된 상태
        data['pmNspcCmpl'] = true;
      } else {
        // PM 검수가 완료되지 않은 상태
        data['pmNspcCmpl'] = false;
      }

      // 상세 내용에 적용함
      setDetailData(data);
    });

    return () => {};
  }, []);

  return (
    <div className="">
      {detailData !== null && (
        <div className="">
          {/* 고객사/지점 정보 리스트 */}
          <List header="고객사/지점 정보" className="bg-gray-100/90">
            <List.Item title="고객사명">
              {displayValue(detailData.bpName)}
            </List.Item>
            <List.Item title="고객사코드">
              {displayValue(detailData.bpCode)}
            </List.Item>
            <List.Item title="Shop명">
              {displayValue(detailData.shipToCode)}
            </List.Item>
            <List.Item title="Shop주소">
              {displayValue(detailData.dlvrDrs)}
            </List.Item>
          </List>

          {/* PM 검수 리스트 */}
          <List header="PM 검수" className="bg-gray-100/90">
            <List.Item
              title={
                detailData.pmNspcCmpl === true
                  ? '버튼을 터치하여 PM 검수 완료를 취소합니다.'
                  : '버튼을 터치하여 PM 검수를 완료합니다.'
              }
            >
              {detailData.pmNspcCmpl === true && (
                <Button
                  size="large"
                  shape="default"
                  onClick={handleCancelButton_onClick}
                  className="w-full !bg-rose-700"
                >
                  <span className="text-md text-white">PM 검수 취소</span>
                </Button>
              )}
              {detailData.pmNspcCmpl === false && (
                <Button
                  size="large"
                  shape="default"
                  onClick={handleApplyButton_onClick}
                  className="w-full !bg-indigo-700"
                >
                  <span className="text-md text-white">PM 검수 완료</span>
                </Button>
              )}
            </List.Item>
          </List>

          {/* 버전 정보 리스트 */}
          <List header="버전 정보" className="bg-gray-100/90">
            <List.Item title="소프트웨어 버전">
              {/* 텍스트 입력 */}
              <Input
                placeholder="소프트웨어 버전을 입력하세요."
                clearable={true}
                defaultValue={detailData.vrsnNfrm}
                value={softwareVersionInput}
                onChange={handleSoftwareVersionInput_onChange}
              />
            </List.Item>
          </List>

          {/* 설치 확인서 리스트 */}
          <List header="설치 확인서" className="bg-gray-100/90">
            {detailData.fileList.map((item: any, index: number) => (
              <List.Item
                key={index}
                title={prettyBytes(_.toNumber(item.fileSize))}
                onClick={() => handleFileList_onClick(item)}
                clickable
                description={item.fileDscr}
              >
                {displayValue(item.rgnlFileName)}
              </List.Item>
            ))}

            <div className="p-3">
              <FileButton onChange={handleAddFileButton_onClick}>
                {(props) => (
                  <Button
                    {...props}
                    size="large"
                    shape="default"
                    className="w-full !bg-indigo-700"
                  >
                    <span className="text-md text-white">첨부파일 추가</span>
                  </Button>
                )}
              </FileButton>
            </div>
          </List>

          {/* 네트워크 공사 작업 내역 리스트 */}
          <List header="네트워크 공사 작업 내역" className="bg-gray-100/90">
            <Collapse accordion={false}>
              {detailData.installPrtnList
                .filter(
                  (filterItem: any) => filterItem.splrTypeCode === 'NTWR_CNST',
                )
                .map((item: any, index: number) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={
                      <div className="flex justify-between items-center space-x-2">
                        <Ellipsis
                          direction="end"
                          content={displayValue(item.ttl)}
                        />

                        <div className="pr-1">
                          <span>{displayValue(item.nstlSttsName)}</span>
                        </div>
                      </div>
                    }
                  >
                    <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                      <List.Item
                        title="내용"
                        onClick={() => handleNetworkList_onClick(item)}
                        clickable
                        extra="작업결과"
                        className="!bg-gray-100/90"
                      >
                        {displayValue(item.ttl)}
                      </List.Item>
                      <List.Item title="상태" className="!bg-gray-100/90">
                        {displayValue(item.nstlSttsName)}
                      </List.Item>
                      <List.Item title="작업일정" className="!bg-gray-100/90">
                        {displayValue(item.workStrtDttm)} ~{' '}
                        {displayValue(item.workEndDttm)}
                      </List.Item>
                      <List.Item title="협력사명" className="!bg-gray-100/90">
                        {displayValue(item.prtnName)}
                      </List.Item>
                    </List>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </List>

          {/* ESL 설치 작업 내역 리스트 */}
          <List header="ESL 설치 작업 내역" className="bg-gray-100/90">
            <Collapse accordion={false}>
              {detailData.installPrtnList
                .filter(
                  (filterItem: any) => filterItem.splrTypeCode === 'ESL_NSTL',
                )
                .map((item: any, index: number) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={
                      <div className="pr-1 flex justify-between items-center space-x-2">
                        <Ellipsis
                          direction="end"
                          content={displayValue(item.ttl)}
                        />

                        <div>
                          <span>{displayValue(item.nstlSttsName)}</span>
                        </div>
                      </div>
                    }
                  >
                    <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                      <List.Item
                        title="내용"
                        onClick={() => handleEslList_onClick(item)}
                        clickable
                        extra="작업결과"
                        className="!bg-gray-100/90"
                      >
                        {displayValue(item.ttl)}
                      </List.Item>
                      <List.Item title="상태" className="!bg-gray-100/90">
                        {displayValue(item.nstlSttsName)}
                      </List.Item>
                      <List.Item title="작업일정" className="!bg-gray-100/90">
                        {displayValue(item.workStrtDttm)} ~{' '}
                        {displayValue(item.workEndDttm)}
                      </List.Item>
                      <List.Item title="협력사명" className="!bg-gray-100/90">
                        {displayValue(item.prtnName)}
                      </List.Item>
                    </List>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </List>
        </div>
      )}
    </div>
  );
};

export default PmInspectionStandbyStateDetail;
