import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IApiResult } from '../../interfaces/app.interface';
import * as ntcbApi from '../../apis/ntcb.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { decode } from 'html-entities';

interface IPopupProps {
  id: string;
  onClick?: () => void;
}

/**
 * Faq > 조회
 * @param id <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const FaqDetail = ({ id, onClick }: PropsWithChildren<IPopupProps>) => {
  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    ntcbApi
      .getFaq({
        brdId: id,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          callback(data.data);
        }
      });
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 상세 내용에 적용함
      setDetailData(data);
    });

    return () => {};
  }, []);

  return (
    <div className="">
      {detailData !== null && (
        <div className="space-y-5">
          {/* 게시물 정보 */}
          <div className="flex justify-between items-center">
            {/* 분류 */}
            <div className="truncate">
              <span className="text-sm text-indigo-600 font-bold">
                {detailData.cstyName}
              </span>
            </div>

            {/* 일자 */}
            <div className="flex justify-center items-center space-x-3">
              {/* 등록일자 */}
              <div className="flex justify-center items-center space-x-1">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['far', 'clock']}
                    className="w-3 h-3 text-gray-500"
                  />
                </div>

                <span className="text-sm text-gray-500">
                  {detailData.rgstDate}
                </span>
              </div>

              {/* 조회수 */}
              <div className="flex justify-center items-center space-x-1">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['far', 'eye']}
                    className="w-4 h-4 text-gray-500"
                  />
                </div>

                <span className="text-sm text-gray-500">{detailData.vws}</span>
              </div>
            </div>
          </div>

          {/* 내용 */}
          <div className="">
            {/* HTML 뷰어 */}
            <div dangerouslySetInnerHTML={{ __html: decode(detailData.dtl) }} />
            {/*<span className="text-base text-gray-600">{detailData.dtl}</span>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqDetail;
