import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';

/**
 * SLS: 영업
 */

/**
 * 영업기회 목록(select box)
 * GET /api/sls/sls-ctvt-prtns
 */
export const getSlsCtvtPrtns = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/sls/sls-ctvt-prtns'].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업기회 상세
 * GET /api/sls/sls-ctvt-detail/{prtnId}
 * @param params prtnId 영업 기회 아이디
 */
export const getSlsCtvtDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: ['/api/sls/sls-ctvt-detail/', params.prtnId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업활동 엑셀 다운로드
 * GET /api/sls/sls-ctvt-excel
 * @param params ctvtEndDt 일정 종료일
 * @param params ctvtStartDt 일정 시작일
 * @param params fileYn 파일 여부
 * @param params keywork 검색어
 * @param params rgstEndDt 등록 종료일
 * @param params rtstStartDt 등록 시작일
 * @param params searchType 검색 구분(검색 대상 column 기입)
 * @param params sgstRsltCode 제안 결과 코드
 * @param params slsMprtCode 영업 중요도 코드
 * @param params slsPrbbCode 영업 확도 코드
 * @param params slsStgCode 영업 단계 코드
 */
export const getSlsCtvtExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/sls/sls-ctvt-excel',
    responseType: 'blob',
    params: {
      ctvtEndDt: params.ctvtEndDt || '',
      ctvtStartDt: params.ctvtStartDt || '',
      fileYn: params.fileYn || '',
      keywork: params.keywork || '',
      rgstEndDt: params.rgstEndDt || '',
      rgstStartDt: params.rtstStartDt || '',
      searchType: params.searchType || '',
      sgstRsltCode: params.sgstRsltCode || '',
      slsMprtCode: params.slsMprtCode || '',
      slsPrbbCode: params.slsPrbbCode || '',
      slsStgCode: params.slsStgCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업활동 파일 다운로드
 * GET /api/sls/sls-ctvt-file/{fileId}
 * @param params fileId
 */
export const getSlsCtvtFile = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: ['/api/sls/sls-ctvt-file', params.fileId].join('/'),
    responseType: 'blob',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      console.log('> response:', response);
      result = response;
    })
    .catch((error: any) => {
      console.log('> getSlsCtvtFile Error:', error);
    });

  return result;
};

/**
 * 영업활동 상세
 * GET /api/sls/sls-ctvt/{slsCtvtId}
 * @param params slsCtvtId 영업 활동 아이디
 */
export const getSlsCtvt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/sls/sls-ctvt/', params.slsCtvtId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업활동 목록
 * GET /api/sls/sls-ctvts
 * @param params ctvtEndDt 일정 종료일
 * @param params ctvtStartDt 일정 시작일
 * @param params currPageIdx 현재페이지
 * @param params fileYn 파일 여부
 * @param params keyword 검색어
 * @param params pageSize 페이지 사이즈
 * @param params rgstEndDt 등록 종료일
 * @param params rgstStartDt 등록 시작일
 * @param params searchType 검색 구분(검색 대상 column 기입)
 * @param params sgstRsltCode 제안 결과 코드
 * @param params slsMprtCode 영업 중요도 코드
 * @param params slsPrbbCode 영업 확도 코드
 * @param params slsStgCode 영업 단계 코드
 */
export const getSlsCtvts = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-ctvts',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      //ordr: 'rgstDate', // 정렬 구분
      searchType: params.searchType || 'bp_name', // 검색 구분(검색 대상 column 기입)
      keyword: params.searchKeyword || '', // 검색어
      rgstStartDt: params.rgstStartDt || '', // 등록기간 시작일
      rgstEndDt: params.rgstEndDt || '', // 등록기간 종료일
      slsMprtCode: params.slsMprtCode || '', // 중요도 구분
      ctvtStartDt: params.ctvtStartDt || '', // 영업활동일 시작일
      ctvtEndDt: params.ctvtEndDt || '', // 영업활동일 종료일
      slsStgCode: params.slsStgCode || '', // 영업단계 구분
      slsPrbbCode: params.slsPrbbCode || '', // 확도
      sgstRsltCode: params.sgstRsltCode || '', // 제안결과 구분
      fileYn: params.fileYn || '', // 파일여부
      slsPrtnId: params.slsPrtnId || '', // 영업 기회 아이디
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 고객사 상세 정보
 * GET /api/sls/sls-prtn-bp-detail
 * @param params bpCode 고객사 코드
 */
export const getSlsPrtnBpDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-bp-detail',
    params: {
      bpCode: params.bpCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getSlsPrtnBpDetail Error:', error);
    });

  return result;
};

/**
 * 영업 기회 엑셀 다운로드
 * GET /api/sls/sls-prtn-excel
 * @param params ndstDntfCode 산업 구분 코드
 * @param params ordr 정렬 구분
 * @param params rgstEndDt 등록기간 종료일
 * @param params rgstStDt 등록기간 시작일
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params sgstRsltCode 제안 결과 코드
 * @param params slsPrbbCode 영업 확도 코드
 * @param params slsSltnCode 영업 솔루션 코드
 * @param params slsStgCode 영업 단계 코드
 */
export const getSlsPrtnExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-excel',
    responseType: 'blob',
    params: {
      ndstDntfCode: params.ndstDntfCode || '',
      ordr: params.ordr || '',
      rgstEndDt: params.rgstEndDt || '',
      rgstStDt: params.rgstStDt || '',
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      sgstRsltCode: params.sgstRsltCode || '',
      slsPrbbCode: params.slsPrbbCode || '',
      slsSltnCode: params.slsSltnCode || '',
      slsStgCode: params.slsStgCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 상위코드 조회
 * GET /api/sls/sls-prtn-search-code
 * @param params grpCodeId 코드
 */
export const getSlsPrtnSearchCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-search-code',
    params: {
      grpCodeId: params.grpCodeId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 산업 구분 하위코드 조회
 * GET /api/sls/sls-prtn-search-ndstcode
 * @param params dtlCodeId 코드
 */
export const getSlsPrtnSearchNdstcode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-search-ndstcode',
    params: {
      dtlCodeId: params.dtlCodeId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 솔루션 하위코드 조회
 * GET /api/sls/sls-prtn-search-sltncode
 * @param params dtlCodeId 코드
 */
export const getSlsPrtnSearchSltncode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-search-sltncode',
    params: {
      dtlCodeId: params.dtlCodeId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 내부담당자 조회(담당자 변경하기)
 * GET /api/sls/sls-prtn-search-user
 * @param params currPageIdx 현재페이지
 * @param params name 회원명
 */
export const getSlsPrtnSearchUser = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-search-user',
    params: {
      currPageIdx: params.currPageIdx || '1',
      name: params.name || '',
      pageSize: params.pageSize || 2000
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업 기회 현황 조회
 * GET /api/sls/sls-prtn-status
 * @param params stDate 검색 시작일
 * @param params endDate 검색 종료일
 */
export const getSlsPrtnStatus = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-status',
    params: {
      stDate: params.stDate || '',
      endDate: params.endDate || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업 기회 조회 상세
 * GET /api/sls/sls-prtn/{prtnId}
 * @param params prtnId 영업 기회 아이디
 */
export const getSlsPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/sls/sls-prtn/', params.slsPrtnId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업 기회 조회
 * GET /api/sls/sls-prtns
 * @param params currPageIdx 현재페이지
 * @param params order 정렬 구분
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params rgstEndDt 등록기간 종료일
 * @param params rgstStDt 등록기간 시작일
 * @param params EndDt 수정기간 종료일
 * @param params StDt 수정기간 시작일
 * @param params ndstDntfCode 산업 구분 코드
 * @param params ndstSubCode 산업 구분 하위코드
 * @param params slsSltnCode 영업 솔루션 코드
 * @param params slsSubCode 영업 솔루션 하위코드
 * @param params slsDealCode 딜 종류
 * @param params slsDlvrCode 납품 구분
 * @param params sgstRsltCode 제안 결과 코드
 * @param params slsStgCode 영업 단계 코드
 * @param params slsPrbbCode 영업 확도 코드
 */
export const getSlsPrtns = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtns',
    params: {
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      ordr: 'rgstDate', // 정렬 구분
      searchKeyword: params.searchKeyword || '', // 검색어
      searchType: params.searchType || '', // 검색 구분
      rgstStDt: params.rgstStartDt || '', // 등록기간 시작일
      rgstEndDt: params.rgstEndDt || '', // 등록기간 종료일
      mdfcStDt: params.mdfcStartDt || '', // 수정기간 시작일
      mdfcEndDt: params.mdfcEndDt || '', // 수정기간 종료일
      ndstDntfCode: params.ndstDntfCode || '', // 산업 구분 코드(1depth)
      ndstSubCode: params.ndstSubCode || '', // 산업 구분 하위코드(2depth)
      slsSltnCode: params.slsSltnCode || '', // 솔루션 구분 코드(1depth)
      slsSubCode: params.slsSubCode || '', // 솔루션 구분 하위코드(2depth)
      slsDealCode: params.slsDealCode || '', // 딜 종류
      slsDlvrCode: params.slsDlvrCode || '', // 납품 구분
      sgstRsltCode: params.sgstRsltCode || '', // 제안 결과 코드
      slsStgCode: params.slsStgCode || '', // 영업 단계 코드
      slsPrbbCode: params.slsPrbbCode || '', // 확도 코드
      pageSize: params.pageSize || 1000, // 페이지 사이즈
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getSlsPrtns Error:', error);
    });

  return result;
};

/**
 * 영업활동 등록
 * POST /api/sls/sls-ctvt
 * @param params swotWkns SWOT 약점
 * @param params slsCtvtDate 영업 활동 일자
 * @param params swotStrn SWOT 강점
 * @param params sgstRsltCode 제안 결과 코드
 * @param params bsnsDtls 영업 내용
 * @param params prpsRslt 제안 결과 내용
 * @param params prtnId 영업 기회 아이디
 * @param params slsfClsCodeList 영업 파일 구분 코드
 * @param params slsPrbbCode 영업 확도 코드
 * @param params slsMprtCode 영업 중요도 코드
 * @param params swotPrtn SWOT 기회
 * @param params swotThrt SWOT 위협
 * @param params fileList 파일
 * @param params fileDscrList 파일 설명
 * @param params slsStgCode 영업 단계 코드
 */
export const postSlsCtvt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/sls/sls-ctvt',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      slsPrtnId: params.slsPrtnId || '', // 영업 기회 아이디
      slsStgCode: params.slsStgCode || '', // 영업 단계 코드
      slsPrbbCode: params.slsPrbbCode || '', // 확도 코드
      sgstRsltCode: params.sgstRsltCode || '', // 제안 결과 코드
      prpsRslt: params.prpsRslt || '', // 제안 결과 내용
      swotStrn: params.swotStrn || '', // Strength
      swotWkns: params.swotWkns || '', // Weakness
      swotPrtn: params.swotPrtn || '', // Opportunities
      swotThrt: params.swotThrt || '', // Threats
      slsCtvtDate: params.slsCtvtDate || '', // 영업 활동일
      slsMprtCode: params.slsMprtCode || '', // 중요도 코드
      slsDtls: params.slsDtls || '', // 영업 활동 내용
      slsfClsfCodeList: params.slsfClsfCodeList, // 영업 파일 구분 코드
      prtnName: params.prtnName || '', // 협력사
      tag: params.tag || '', // 태그
      fileList: params.fileList, // 파일 설명
      fileDscrList: params.fileDscrList, // 파일
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업활동 수정
 * POST /api/sls/sls-ctvt/{slsCtvtId}
 * @param params slsCtvtId 영업 활동 아이디
 * @param params swotWkns SWOT 약점
 * @param params slsCtvtDate 영업 활동 일자
 * @param params swotStrn SWOT 강점
 * @param params sgstRsltCode 제안 결과 코드
 * @param params bsnsDtls 영업 내용
 * @param params prpsRslt 제안 결과 내용
 * @param params prtnId 영업 기회 아이디
 * @param params slsfClsCodeList 영업 파일 구분 코드
 * @param params slsPrbbCode 영업 확도 코드
 * @param params slsMprtCode 영업 중요도 코드
 * @param params swotPrtn SWOT 기회
 * @param params swotThrt SWOT 위협
 * @param params fileList 파일
 * @param params fileDscrList 파일 설명
 * @param params slsStgCode 영업 단계 코드
 */
export const putSlsCtvt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  console.log('> params:', params);

  await api({
    method: 'POST',
    url: ['/api/sls/sls-ctvt', params.slsCtvtId].join('/'),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      slsPrtnId: params.slsPrtnId || '', // 영업 기회 아이디
      slsStgCode: params.slsStgCode || '', // 영업 단계 코드
      slsPrbbCode: params.slsPrbbCode || '', // 확도 코드
      sgstRsltCode: params.sgstRsltCode || '', // 제안 결과 코드
      prpsRslt: params.prpsRslt || '', // 제안 결과 내용
      swotStrn: params.swotStrn || '', // Strength
      swotWkns: params.swotWkns || '', // Weakness
      swotPrtn: params.swotPrtn || '', // Opportunities
      swotThrt: params.swotThrt || '', // Threats
      slsCtvtDate: params.slsCtvtDate || '', // 영업 활동일
      slsMprtCode: params.slsMprtCode || '', // 중요도 코드
      slsDtls: params.slsDtls || '', // 영업 활동 내용
      prtnName: params.prtnName || '', // 협력사
      tag: params.tag || '', // 태그
      fileList: params.fileList, // 파일 설명
      fileDscrList: params.fileDscrList, // 파일
      slsfClsfCodeList: params.slsfClsfCodeList, // 영업 파일 구분 코드
      bfFileIdList: params.bfFileIdList,
      bfFileDscrList: params.bfFileDscrList,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putSlsCtvt Error:', error);
    });

  return result;
};

/**
 * 영업 기회 조회 등록
 * POST /api/sls/sls-prtn
 * @param params slsPrtnRegPramVo
 */
export const postSlsPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/sls/sls-prtn',
    // data: {
    //   slsPrtnRegPramVo: params.slsPrtnRegPramVo || {},
    // },
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postSlsPrtn Error:', postSlsPrtn);
    });

  return result;
};

/**
 * 영업 기회 조회 수정
 * PUT /api/sls/sls-prtn/{slsPrtnId}
 * @param params slsPrtnId 영업 기회 아이디
 * @param params slsPrtnMdfyPramVo
 */
export const putSlsPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/sls/sls-prtn', params.slsPrtnId].join('/'),
    // data: {
    //   slsPrtnMdfyPramVo: params.slsPrtnMdfyPramVo || {},
    // },
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putSlsPrtn Error:', error);
    });

  return result;
};

/**
 * 영업 활동 삭제
 * DELETE /api/sls/sls-ctvt/{slsCtvtId}
 * @param params slsCtvtId 영업 활동 아이디
 */
export const deleteSlsCtvt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/sls/sls-ctvt/', params.slsCtvtId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 영업 기회 조회 삭제
 * DELETE /api/sls/sls-prtn/{prtnId}
 * @param params prtnId 영업 기회 아이디
 */
export const deleteSlsPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/sls/sls-prtn/', params.prtnId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 고객사(BP) 목록 조회
 * GET /api/sls/sls-prtn-search-bp
 * @param params currPageIdx 현재 페이지
 * @param params pageSize 한번에 보여질 갯수
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분(검색 대상 column 기입)
 */
export const getSlsPrtnSearchBp = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/sls/sls-prtn-search-bp',
    params: {
      currPageIdx: 1,
      pageSize: 1000,
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || 'bp_name',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getSlsPrtnSearchBp Error:', error);
    });

  return result;
};
