import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  ILoginUser,
  IOverlay,
  IPopup,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { addPopupStore, removePopupStore } from '../../stores/popup.store';
import { overlayStore } from '../../stores/overlay.store';
import * as nstlApi from '../../apis/nstl.api';
import { nanoid } from 'nanoid';
import {
  Button,
  Dialog,
  Ellipsis,
  Form,
  ImageViewer,
  Mask,
  Stepper,
  SwipeAction,
  Tabs,
  TextArea,
  Toast,
} from 'antd-mobile';
import { decode } from 'html-entities';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopupTabsBottomButtonArea } from '../../components/PopupTabsBottomButtonArea';
import * as appUtil from '../../utils/app.util';
import FileDownloadList from '../../components/FileDownloadList/FileDownloadList.component';
import ImageFilePreviewList from '../../components/ImageFilePreviewList/ImageFilePreviewList.component';
import * as workApi from '../../apis/work.api';

interface IPopupProps {
  id: string;
  data?: any;
  dcmnDntfNmbr?: string;
  splrId?: string;
  teamId?: string;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 설치관리 > 실적등록 > 상세 > 작업실적등록
 * @param data <인자>
 * @param dcmnDntfNmbr 주문번호
 * @param splrId 협력사 아이디
 * @param teamId 팀 아이디
 * @param onClick <이벤트>
 * @constructor
 */
const PerformanceRegistrationModifyComment = ({
  id,
  data,
  dcmnDntfNmbr = '',
  splrId = '',
  teamId = '',
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 화면 위 검은 화면 저장소를 정의함
  const [overlay, setOverlay] = useRecoilState<IOverlay>(overlayStore);

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // // 첨부파일 목록을 정의함
  // const [fileList, setFileList] = useState<any>([]);

  // 댓글 동적 폼을 정의함
  const [dynamicComment, setDynamicComment] = useState<any>({});

  // 첨부파일을 삭제함
  const removeFile = (event: any) => {
    // 삭제할 첨부파일의 순서를 불러옴
    let removeFileIndex: number = _.findIndex(dynamicComment?.fileList || [], {
      id: event.id,
    });

    setTimeout(() => {
      // 첨부파일 목록에서 삭제함
      setDynamicComment((pre: any) => ({
        ...pre,
        fileList: [
          ...pre.fileList.slice(0, removeFileIndex),
          ...pre.fileList.slice(removeFileIndex + 1),
        ],
      }));
    }, 500);
  };

  // 적용 버튼을 클릭함
  const handleApplyButton_onClick = () => {
    if (dynamicComment.content === undefined || dynamicComment.content === '') {
      Toast.show({
        position: 'bottom',
        content: '내용을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.dynamic-comment-content textarea',
      ) as HTMLTextAreaElement;
      obj.focus();

      return;
    }

    // 부모창으로 값을 전달함
    callback(dynamicComment);

    Toast.show({
      position: 'bottom',
      content: '적용하였습니다.',
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('popup', id, true);

    return () => {};
  }, []);

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (loginUser.id === '') {
      return;
    }

    // 댓글 동적 폼에 적용함
    setDynamicComment(data);

    return () => {};
  }, [loginUser.id]);

  return (
    <div className="">
      {/* 내용 */}
      <div className="ant-m-popup ant-m-popup-with-bottom-button space-y-3">
        <Form.Item
          label={
            <div className="flex justify-start items-center space-x-1">
              <span>내용</span>
              <span className="text-red-500">*</span>
            </div>
          }
        >
          <TextArea
            placeholder="작업실적 내용을 입력하세요."
            value={dynamicComment?.content || ''}
            onChange={(value: any) => {
              setDynamicComment((pre: any) => ({
                ...pre,
                content: value,
              }));
            }}
            rows={10}
            className="ant-m-textarea dynamic-comment-content"
          />
        </Form.Item>

        <div className="divider-y" />

        <Form.Item label="첨부파일">
          <FileDownloadList
            data={dynamicComment?.fileList || []}
            visibleSwipeRightAction={true}
            visibleAddFileButton={true}
            onClickAddFileButton={(event: File[]) => {
              if (event === null) {
                return;
              }

              // 화면 위 검은 화면 저장소에 적용함
              setOverlay({
                visible: true,
                content: (
                  <div className="px-3 py-1 bg-black/50">
                    <span className="text-base text-white font-bold">
                      파일 업로드 중
                    </span>
                  </div>
                ),
              });

              let tmpFileList: any[] = [];

              // 선택한 첨부파일을 업로드함
              workApi
                .postPrgrTch({
                  fileList: event,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    data.data.map((item: any) => {
                      tmpFileList.push({
                        id: item.fileId,
                        originalFileName: item.rgnlFileName,
                        saveFileName: item.pldFileName,
                        size: item.fileSize,
                      });
                    });

                    // 댓글 동적 폼에 적용함
                    setDynamicComment((pre: any) => ({
                      ...pre,
                      fileList: [...(pre?.fileList || []), ...tmpFileList],
                    }));

                    Toast.show({
                      position: 'bottom',
                      content: '업로드 하였습니다.',
                    });
                  } else {
                    Toast.show({
                      position: 'bottom',
                      content: '업로드에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  Toast.show({
                    position: 'bottom',
                    content: '업로드에 실패하였습니다.',
                  });
                })
                .finally(() => {
                  // 화면 위 검은 화면 저장소에 적용함
                  setOverlay({
                    visible: false,
                    content: '',
                  });
                });

              return;
            }}
            onClickRemoveFileButton={(event: any) => {
              // 첨부파일을 삭제함
              removeFile(event);
            }}
            onClickFileDownloadButton={(event: any) => {
              console.log('> onClickFileDownloadButton:', event);
            }}
          />
        </Form.Item>

        <div className="divider-y" />

        <Form.Item label="미리보기">
          <ImageFilePreviewList
            data={dynamicComment?.fileList || []}
            visibleSwipeRightAction={true}
            onClickRemoveFileButton={(event: any) => {
              // 첨부파일을 삭제함
              removeFile(event);
            }}
          />
        </Form.Item>
      </div>

      {/* 팝업 하단 버튼 */}
      <PopupTabsBottomButtonArea>
        {/* 버튼 */}
        <Button
          fill="none"
          onClick={handleApplyButton_onClick}
          className="!bg-blue-700"
        >
          <span className="text-base text-white">적용</span>
        </Button>
      </PopupTabsBottomButtonArea>
    </div>
  );
};

export default PerformanceRegistrationModifyComment;
