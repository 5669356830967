import React, { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { IApiResult, ILoginUser, IToken } from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import * as appUtil from '../../utils/app.util';
import * as cmnApi from '../../apis/cmn.api';

interface IManagerProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 전역 처리
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const GlobalProcess = ({ data, onClick }: PropsWithChildren<IManagerProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  useEffect(() => {
    // 로그인한 사용자 토큰을 Local Storage에서 불러옴
    let token: IToken = appUtil.getToken();

    if (!token.accessToken) {
      // 로그인 페이지로 이동함
      navigate('/login');
    }

    // 사용자 상세 정보를 불러옴
    cmnApi.getUser({ userId: token.userId }).then((data: IApiResult) => {
      if (data.data === undefined) {
        return;
      }

      // 권한 그룹 상세 정보를 불러옴
      cmnApi
        .getAuthorityGroup({
          thrtGrpId:
            data.data.thrtGrpId === undefined ? '' : data.data.thrtGrpId,
        })
        .then((subData: IApiResult) => {
          let tmpLoginUser: ILoginUser = {
            ...loginUser,
            id: data.data.userId,
            name: data.data.name,
            ml: data.data.ml,
            celPhn: data.data.celPhn,
            cmpnCode: data.data.cmpnCode,
            cmpnName: data.data.cmpnName,
            dprtCode: data.data.dprtCode,
            dprtName: data.data.dprtName,
            hdqrCode: data.data.hdqrCode,
            hdqrName: data.data.hdqrName,
            thrtGrpId: data.data.thrtGrpId || '',
            thrtGrpName: data.data.thrtGrpName,
            thrtDvsnCode: data.data.thrtDvsnCode,
            thrtDvsnName: data.data.thrtDvsnName,
            nstlCtgrCode: data.data.nstlCtgrCode,
            nstlCtgrName: data.data.nstlCtgrName,
            splrTmList: data.data.splrTmList,
            authorityGroup: [],
            useYn: data.data.useYn,
            isPartner: data.data.cmpnCode === 'PRTN' ? true : false,
          };

          // 권한 그룹의 상세 권한을 적용함
          if (subData.code === '200') {
            // tmpLoginUser.authorityGroup = subData.data.list;

            // 사용자가 속해 있는 권한 그룹에 대한 상세 권한을 적용함
            let tmpUserAuthGroup: any = subData.data.list.filter(
              (filterItem: any) =>
                filterItem.thrtGrpId === tmpLoginUser.thrtGrpId,
            );

            tmpLoginUser.authorityGroup = tmpUserAuthGroup;
          }

          // 로그인한 사용자 저장소에 적용함
          setLoginUser(tmpLoginUser);
        });
    });

    return () => {};
  }, []);

  return <></>;
};

export default GlobalProcess;
