import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/translation.json';
import kr from './kr/translation.json';
import jp from './jp/translation.json';

export const resources = {
  en: {
    translation: en,
  },
  kr: {
    translation: kr,
  },
  jp: {
    translation: jp,
  },
};

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  resources,
});
