import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ActionSheet, Button, Input, List, Toast } from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { IActionSheet } from '../../interfaces/app.interface';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { removePopupStore } from '../../stores/popup.store';

interface IPopupProps {
  id: string;
  data?: any | null;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 소프트웨어 정보 수정
 * @param data 구분, 내용(신규일 때는 null)
 * @param onClick <이벤트>
 * @constructor
 */
const ModifySoftware = ({
  id,
  data,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 액션시트 참조를 정의함
  const actionSheetHandlerRef = useRef<ActionSheetShowHandler>();

  // 구분 액션시트를 정의함
  const [typeActionSheet, setTypeActionSheet] = useState<IActionSheet>({
    value: '',
    item: [],
  });

  // 내용 입력을 정의함
  const [contentInput, setContentInput] = useState<string>('');

  // 구분 액션시트를 초기화함
  const initTypeActionSheet = () => {
    let tmpOptionItem: Action[] = [];

    tmpOptionItem.push(
      {
        text: '서버 IP',
        key: 'SRVR_IP',
      },
      {
        text: '게이트웨이 IP',
        key: 'GTWY_IP',
      },
    );

    // 구분 액션시트에 적용함
    setTypeActionSheet((pre: IActionSheet) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  // 액션시트에서 선택한 아이템의 레이블을 불러옴
  const getActionSheetLabel = (actionSheet: IActionSheet) => {
    let tmpOptionItem: Action | undefined | null = _.find(actionSheet.item, {
      key: actionSheet.value,
    });

    return tmpOptionItem?.text || '';
  };

  // 내용 입력을 변경함
  const handleContentInput_onChange = (event: any) => {
    setContentInput(event);
  };

  // 적용 버튼을 클릭함
  const handleApplyButton_onClick = () => {
    if (typeActionSheet.value === '') {
      Toast.show({
        position: 'bottom',
        content: '구분을 선택하세요.',
      });

      return;
    }

    // 부모창으로 결과를 넘김
    callback({
      preData: data,
      resultType: '',
      type: typeActionSheet.value,
      typeLabel: getActionSheetLabel(typeActionSheet),
      content: contentInput.trim(),
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  // 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    // 부모창으로 결과를 넘김
    callback({
      preData: data,
      resultType: 'remove',
      type: '',
      typeLabel: '',
      content: '',
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  useEffect(() => {
    // 구분 액션시트를 초기화함
    initTypeActionSheet();

    // 수정일 경우에 불러온 기본값을 적용함
    if (data !== null) {
      // 구분 액션시트에 적용함
      setTypeActionSheet((pre: IActionSheet) => ({
        ...pre,
        value: data.ntwrWorkCode,
      }));

      // 내용 입력에 적용함
      setContentInput(data.ttl);
    }

    return () => {};
  }, []);

  return (
    <div className="">
      {/* 편집 리스트 */}
      <List header="편집 정보" className="bg-gray-100/90">
        <List.Item title="구분">
          {/* 버튼 */}
          <Button
            size="middle"
            fill="outline"
            onClick={() => {
              actionSheetHandlerRef.current = ActionSheet.show({
                actions: typeActionSheet.item,
                onAction: (action: Action) => {
                  // 구분 액션시트에 적용함
                  setTypeActionSheet((pre: IActionSheet) => ({
                    ...pre,
                    value: action.key.toString(),
                  }));

                  // 액션시트를 닫음
                  actionSheetHandlerRef.current?.close();
                },
              });
            }}
            className="w-full !border-indigo-200"
          >
            <span className="text-md text-indigo-500">
              {getActionSheetLabel(typeActionSheet) || '선택하세요'}
            </span>
          </Button>
        </List.Item>
        <List.Item title="내용">
          {/* 텍스트 입력 */}
          <Input
            placeholder="내용을 입력하세요."
            clearable={true}
            value={contentInput}
            onChange={handleContentInput_onChange}
          />
        </List.Item>
      </List>

      <div className="p-3 space-y-2">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleApplyButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">적용</span>
        </Button>

        {/* 버튼 */}
        {data !== null && (
          <Button
            size="large"
            shape="default"
            onClick={handleRemoveButton_onClick}
            className="w-full !bg-rose-700"
          >
            <span className="text-md text-white">삭제</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModifySoftware;
