import axios, { AxiosResponse } from 'axios';
import {
  IApiListResult,
  IApiResult,
  IToken,
} from '../interfaces/app.interface';
import * as appUtil from '../utils/app.util';
import api from '../utils/axios.util';

/**
 * CMN: 공통
 */

/**
 * 로그인 처리
 * POST /api/cmn/TestLogin
 * @param params.lgnId 아이디
 * @param params.userPw 비밀번호
 */
export const postLogin = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await axios({
    method: 'POST',
    url: [appUtil.getApiUrl(), '/api/cmn/login'].join(''),
    headers: {
      Authorization: 'none',
    },
    data: {
      lgnId: params.lgnId || '',
      userPw: params.userPw || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 토큰 갱신 처리
 * POST /api/cmn/refresh-token
 */
export const postRefreshToken = async () => {
  // 로그인한 사용자 토큰을 Local Storage에서 불러옴
  const token: IToken = appUtil.getToken();

  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await axios({
    method: 'POST',
    url: [appUtil.getApiUrl(), '/api/cmn/refresh-token'].join(''),
    data: {
      accessToken: token.accessToken || '',
      refreshToken: token.refreshToken || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 그룹 등록
 * POST /api/cmn/authority-group
 * @param params.thrtGrpName 권한 그룹 이름
 * @param params.thrtGrpDscr 권한 그룹 설명
 * @param params.thrtGrpRltnListMap 권한 - 권한 그룹 관계 아이디 목록
 * @param params.useYn 사용 여부
 */
export const postAuthorityGroup = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/authority-group',
    data: {
      thrtGrpName: params.thrtGrpName || '',
      thrtGrpDscr: params.thrtGrpDscr || '',
      thrtGrpRltnListMap: params.thrtGrpRltnListMap || [],
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 그룹 수정
 * PUT /api/cmn/authority-group/{thrtGrpId}
 * @param params thrtGrpId 권한 그룹 아이디
 * @param params thrtGrpName 권한 그룹 이름
 * @param params thrtGrpDscr 권한 그룹 설명
 * @param params thrtGrpRltnListMap 권한 - 권한 그룹 관계 아이디 목록
 * @param params useYn 사용 여부
 */
export const putAuthorityGroup = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/cmn/authority-group', params.thrtGrpId].join('/'),
    data: {
      thrtGrpName: params.thrtGrpName || '',
      thrtGrpDscr: params.thrtGrpDscr || '',
      thrtGrpRltnListMap: params.thrtGrpRltnListMap || [],
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 그룹 목록 조회
 * GET /api/cmn/authority-groups
 * @param params.currPageIdx 현재페이지
 * @param params.rgstStDt 등록 기간 시작 일자
 * @param params.rgstEnDt 등록 기간 종료 일자
 * @param params.thrtGrpName 권한 그룹 이름
 * @param params.useYn 사용 여부
 */
export const getAuthorityGroups = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/authority-groups',
    params: {
      currPageIdx: params.currPageIdx || 1,
      pageSize: params.pageSize || 1000,
      rgstStDt: params.rgstStDt || '',
      rgstEnDt: params.rgstEnDt || '',
      thrtGrpName: params.thrtGrpName || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 그룹 목록 조회(페이징 X)
 * GET /api/cmn/authority-groups-all
 */
export const getAuthorityGroupsAll = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/authority-groups-all',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 그룹 상세 조회
 * GET /api/cmn/authority-group/{thrtGrpId}
 * @param params thrtGrpId 권한 그룹 아이디
 */
export const getAuthorityGroup = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cmn/authority-group', params.thrtGrpId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 그룹 삭제
 * DELETE /api/cmn/authority-group/{thrtGrpId}
 * @param params thrtGrpId 권한 아이디
 */
export const deleteAuthorityGroup = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cmn/authority-group/', params.thrtGrpId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 등록
 * POST /api/cmn/authority
 * @param params.thrtName 권한 이름
 * @param params.thrtDscr 권한 설명
 * @param params.menuDvsnCode 메뉴 구분 코드
 * @param params.mnthRltnListMap 메뉴 권한 관계 아이디 목록
 * @param params.useYn 사용 여부
 */
export const postAuthority = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/authority',
    data: {
      thrtName: params.thrtName || '',
      thrtDscr: params.thrtDscr || '',
      menuDvsnCode: params.menuDvsnCode || '',
      mnthRltnListMap: params.mnthRltnListMap || [],
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 목록 조회
 * GET /api/cmn/authorities
 * @param params.currPageIdx 현재페이지
 * @param params.rgstStDt 등록 기간 시작 일자
 * @param params.rgstEnDt 등록 기간 종료 일자
 * @param params.thrtName 권한 이름
 * @param params.useYn 사용 여부
 */
export const getAuthorities = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/authorities',
    params: {
      pageSize: params.pageSize || 1000,
      currPageIdx: params.currPageIdx || 1,
      rgstStDt: params.rgstStDt || '',
      rgstEnDt: params.rgstEnDt || '',
      thrtName: params.thrtName || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 상세 조회
 * GET /api/cmn/authority/{thrtId}
 * @param params thrtId 권한 아이디
 * @param params menuDvsnCode 메뉴 구분 코드
 */
export const getAuthority = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cmn/authority', params.thrtId].join('/'),
    params: {
      menuDvsnCode: params.menuDvsnCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 메뉴 대상 목록
 * GET /api/cmn/authority-menu-trgt
 * @param params.currPageIdx 현재페이지
 * @param params.rgstStDt 등록 기간 시작 일자
 * @param params.rgstEnDt 등록 기간 종료 일자
 * @param params.thrtName 권한 이름
 * @param params.useYn 사용 여부
 */
export const getAuthorityTarget = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/authority-menu-trgt',
    params: {
      menuDvsnCode: params.menuDvsnCode || 'WEB',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 수정
 * PUT /api/cmn/authority/{thrtId}
 * @param params thrtId 권한 아이디
 * @param params authorityModParamVo 권한 수정 정보
 */
export const putAuthority = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/cmn/authority', params.thrtId].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한 삭제
 * DELETE /api/cmn/authority/{thrtId}
 * @param params.thrtId 권한 아이디
 */
export const deleteAuthority = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cmn/authority/', params.thrtId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한명 중복검사
 * GET /api/cmn/check-authority
 * @param params thrtName 권한명
 */
export const getCheckAuthority = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/check-authority',
    params: {
      thrtName: params.thrtName || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 권한 대상 목록 - 권한 그룹 등록시 권한 선택
 * GET /api/cmn/authority-group-trgt
 * @param params thrtGrpId 권한 그룹 아이디
 */
export const getAuthorityGroupTrgt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/authority-group-trgt',
    params: {
      thrtGrpId: params.thrtGrpId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 권한그룹명 중복검사
 * GET /api/cmn/check-authority-group
 * @param params thrtGrpName 권한그룹명
 */
export const getCheckAuthorityGroup = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/check-authority-group',
    params: {
      thrtGrpName: params.thrtGrpName || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 공통코드 조회
 * GET /api/cmn/codes
 * @param params.currPageIdx 현재페이지
 * @param params.pageSize 한 페이지에 보여줄 rows count
 * @param params.searchKeyword 검색어
 * @param params.searchType 검색 구분
 * @param params.useYn 사용 여부
 */
export const getCodes = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/codes',
    params: {
      currPageIdx: params.currPageIdx || 1,
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      useYn: params.useYn || '',
      pageSize: 1000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통코드 중복확인
 * GET /api/cmn/check-code
 * @param params grpCodeId 코드
 */
export const getCheckCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/check-code',
    params: {
      grpCodeId: params.grpCodeId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 공통코드 상세
 * GET /api/cmn/code/{grpCodeId}
 * @param params grpCodeId 코드
 */
export const getCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cmn/code', params.grpCodeId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 공통상세코드 조회
 * GET /api/cmn/detail-codes
 * @param params.currPageIdx 현재페이지
 * @param params.grpCodeId 공통 상위코드
 * @param params.pageSize 한 페이지에 보여줄 rows count
 */
export const getDetailCodes = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/detail-codes',
    params: {
      currPageIdx: params.currPageIdx || 1,
      grpCodeId: params.grpCodeId || '',
      pageSize: 1000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통코드 등록
 * POST /api/cmn/code
 * @param params.grpCodeId 공통코드 아이디
 * @param params.grpCodeDscr 공통코드 명칭
 * @param params.grpCodeSrc 공통코드 설명
 * @param params.useYn 사용 여부
 */
export const postCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/code',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통코드 수정
 * PUT /api/cmn/code/{grpCodeId}
 * @param params.grpCodeId 공통코드 아이디
 * @param params.modifiedId 수정 공통코드 아이디
 * @param params.grpCodeDscr 공통코드 명칭
 * @param params.grpCodeSrc 공통코드 설명
 * @param params.useYn 사용 여부
 */
export const putCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/cmn/code', params.grpCodeId].join('/'),
    data: {
      modifiedId: params.modifiedId || '',
      grpCodeDscr: params.grpCodeDscr || '',
      grpCodeSrc: params.grpCodeSrc || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통코드 삭제
 * DELETE /api/cmn/code/{grpCodeId}
 * @param params grpCodeId 공통코드 아이디
 */
export const deleteCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cmn/code', params.grpCodeId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통상세코드 중복확인
 * GET /api/cmn/check-dtl-code
 * @param params grpCodeId 코드
 */
export const getCheckDtlCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/check-dtl-code',
    params: {
      grpCodeId: params.grpCodeId || '',
      dtlCodeId: params.dtlCodeId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 공통상세코드 상세
 * GET /api/cmn/detail-code/{grpCodeId}/{dtlCodeId}
 * @param params grpCodeId 공통코드 상위코드
 * @param params dtlCodeId 공통코드 하위코드
 */
export const getDetailCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cmn/detail-code', params.grpCodeId, params.dtlCodeId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 공통상세코드 등록
 * POST /api/cmn/detail-code
 * @param params.grpCodeId 공통상세코드 아이디
 * @param params.dtlCodeId 공통상세코드 아이디
 * @param params.dtlCodeDscr 공통상세코드 명칭
 * @param params.dtlCodeSrc 공통상세코드 설명
 * @param params.sortRdr 순서
 * @param params.useYn 사용 여부
 */
export const postDetailCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/detail-code',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통상세코드 수정
 * PUT /api/cmn/code/{grpCodeId}/{dtlCodeId}
 * @param params.grpCodeId 공통코드 아이디
 * @param params.dtlCodeId 공통상세코드 아이디
 * @param params.dtlCodeDscr 공통상세코드 명칭
 * @param params.dtlCodeSrc 공통상세코드 설명
 * @param params.modifiedId 수정 공통코드 아이디
 * @param params.sortRdr 순서
 * @param params.useYn 사용 여부
 */
export const putDetailCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/cmn/detail-code', params.grpCodeId, params.dtlCodeId].join('/'),
    data: {
      modifiedId: params.modifiedId || '',
      dtlCodeDscr: params.dtlCodeDscr || '',
      dtlCodeSrc: params.dtlCodeSrc || '',
      sortRdr: params.sortRdr || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 상세코드 삭제
 * DELETE /api/cmn/detail-code/{grpCodeId}/{dtlCodeId}
 * @param params grpCodeId 공통코드 아이디
 * @param params dtlCodeId 공통 상세코드 아이디
 */
export const deleteDetailCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cmn/detail-code', params.grpCodeId, params.dtlCodeId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 메일 설정 조회
 * GET /api/cmn/mail-list
 */
export const getMailList = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/mail-list',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 메일설정 수정
 * PUT /api/cmn/mail
 * @param params.calCntrMail 콜센터 대표 메일
 * @param params.dvlpTeamMail 개발팀 대표 메일
 * @param params.nvntMngrMail 재고담당 대표 메일
 * @param params.prtnTeamMail 운영팀 대표 메일
 */
export const putMail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: '/api/cmn/mail',
    data: {
      calCntrMail: params.calCntrMail || '',
      dvlpTeamMail: params.dvlpTeamMail || '',
      nvntMngrMail: params.nvntMngrMail || '',
      prtnTeamMail: params.prtnTeamMail || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 파일 업로드
 * POST /api/cmn/upload-files
 * @param params.files 업로드 파일
 */
export const postUploadFiles = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/upload-files',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      files: params.files,
    },
    onUploadProgress: (progressEvent) => {
      let tmpProgress = Math.floor((progressEvent.progress || 0) * 100);

      console.log('> uploading:', tmpProgress);

      if (tmpProgress === 100) {
        console.log('> upload complete step.1');
      }

      console.log('> fileUpload progress:', progressEvent);
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 파일 정보 조회
 * GET /api/cmn/files
 * @param params.fileIdList 파일 ID 목록(콤마로 구분)
 */
export const getFiles = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/files',
    params: {
      fileIdList: params.fileIdList,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 시스템 메뉴 목록
 * GET /api/cmn/menus
 * @param params.menuDvsnCode 메뉴 구분 코드
 * @param params.useYn 사용 여부
 */
export const getSystemMenus = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/system-menus',
    params: {
      menuDvsnCode: params.menuDvsnCode || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 메뉴 목록
 * GET /api/cmn/menus
 * @param params.menuDvsnCode 메뉴 구분 코드
 * @param params.useYn 사용 여부
 */
export const getMenus = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/menus',
    params: {
      menuDvsnCode: params.menuDvsnCode || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 메뉴 상세 조회
 * GET /api/cmn/menu/{menuId}
 * @param params menuId 아이디
 */
export const getMenu = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };
  await api({
    method: 'GET',
    url: ['/api/cmn/menu', params.menuId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 메뉴 등록
 * POST /api/cmn/menu
 * @param params 사용자 등록
 * @param params POST /api/cmn/user
 * @param params jpnsDscr 일본 메뉴 설명
 * @param params jpnsMenu 일본 메뉴명
 * @param params krnDscr 한글 메뉴 설명
 * @param params krnMenu	한글 메뉴명
 * @param params menuCmpn 메뉴 컴포넌트
 * @param params menuDvsnCode 메뉴 구분 코드
 * @param params menuId 메뉴 아이디
 * @param params menuTypeCode 메뉴 타입 (L: 링크, M: 모달, P: 페이지)
 * @param params menuTypeName	메뉴 타입명
 * @param params nglsDscr	영어 메뉴 설명
 * @param params nglsMenu	영어 메뉴명
 * @param params prntMenuId	부모 메뉴 아이디
 * @param params sortRdr 정렬 순서
 * @param params url 화면 접속 URL
 * @param params useYn 사용여부
 */
export const postMenu = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/menu',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 메뉴 수정
 * PUT /api/cmn/menu/{menuId}
 * @param params menuId 메뉴 아이디
 * @param params krnMenu 한글 메뉴명
 * @param params krnDscr 한글 메뉴 설명
 * @param params jpnsDscr 일본 메뉴 설명
 * @param params jpnsMenu 일본 메뉴명
 * @param params nglsDscr	영어 메뉴 설명
 * @param params nglsMenu	영어 메뉴명
 * @param params url 화면 접속 URL
 * @param params useYn 사용 여부
 * @param params menuTypeCode 메뉴 타입 (L: 링크, M: 모달, P: 페이지)
 */
export const putMenu = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/cmn/menu', params.menuId].join('/'),
    data: {
      url: params.url || '',
      krnMenu: params.krnMenu || '',
      krnDscr: params.krnDscr || '',
      jpnsMenu: params.krnMenu || '',
      jpnsDscr: params.krnDscr || '',
      nglsMenu: params.krnMenu || '',
      nglsDscr: params.krnDscr || '',
      menuTypeCode: params.menuTypeCode || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 메뉴 삭제
 * DELETE /api/cmn/menu/{menuId}
 * @param params userId 사용자 아이디
 */
export const deleteMenu = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cmn/menu', params.menuId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 사용자 목록 조회
 * GET /api/cmn/users
 * @param params currPageIdx 현재페이지
 * @param params pageSize 페이지 사이즈
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params thrtGrpId 권한 그룹 아이디
 * @param params useYn 사용 여부
 */
export const getCmnUsers = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/users',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      searchKeyword: params.searchKeyword || '', // 검색어
      searchType: params.searchType || '', // 검색 구분
      thrtGrpId: params.thrtGrpId || '', // 권한 그룹 아이디
      nstlCtgrCode: params.nstlCtgrCode || '', // 설치 구분 코드
      csDprtCode: params.csDprtCode || '', // CS 부서 코드
      useYn: params.useYn || '', // 사용 여부
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 사용자 엑셀 다운로드
 * GET /api/cmn/user-excel
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params thrtGrpId 권한 그룹 아이디
 * @param params useYn 사용 여부
 */
export const getUserExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/cmn/user-excel',
    responseType: 'blob',
    params: {
      searchType: params.searchType || '',
      searchKeyword: params.searchKeyword || '',
      thrtGrpId: params.thrtGrpId || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 사용자 상세 조회
 * GET /api/cmn/user/{userId}
 * @param params userId 아이디
 */
export const getUser = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cmn/user', params.userId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 사용자 등록
 * POST /api/cmn/user
 * @param params celPhn 휴대전화
 * @param params cmpnCode 회사코드
 * @param params csDprtCode CS 부서 코드
 * @param params dprtCode 부서코드
 * @param params hdqrCode 본부 코드
 * @param params lgnId 로그인 아이디
 * @param params ml Email
 * @param params name 이름
 * @param params nickName 이름
 * @param params nstlCtgrCode 설치구분코드
 * @param params pstnCode 직급 코드
 * @param params thrtDvsnCode 권한 구분 코드
 * @param params thrtGrpId 권한 그룹 아이디
 * @param params useYn 사용 여부
 */
export const postUser = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/user',
    data: params,
    // {
    //   celPhn: params.celPhn || '', // 휴대폰
    //   cmpnCode: params.cmpnCode || '', // 회사코드
    //   csDprtCode: params.csDprtCode || '', // CS 부서 코드
    //   dprtCode: params.dprtCode || '', // 부서 코드
    //   hdqrCode: params.hdqrCode || '', // 본부 코드
    //   lgnId: params.lgnId || '', // 로그인 아이디
    //   ml: params.ml || '', // Email
    //   name: params.name || '', // 이름
    //   nickName: params.nickName || '', //
    //   nstlCtgrCode: params.nstlCtgrCode || '', // 설치구분 코드 - 컴퍼넌트 추가시 개발 필요
    //   pstnCode: params.pstnCode || '', // 직급 코드
    //   thrtGrtId: params.thrtGrtId || '', // 권한 그룹 코드
    //   useYn: params.useYn || '', // 사용자 상태(사용여부)
    // },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 사용자 수정
 * PUT /api/cmn/user/{userId}
 * @param params celPhn 휴대전화
 * @param params cmpnCode 회사코드
 * @param params csDprtCode CS 부서 코드
 * @param params dprtCode 부서코드
 * @param params hdqrCode 본부 코드
 * @param params ml Email
 * @param params name 이름
 * @param params nickName 이름
 * @param params nstlCtgrCode 설치구분코드
 * @param params pstnCode 직급 코드
 * @param params thrtGrpId 권한 그룹 아이디
 * @param params useYn 사용 여부
 * @param params thrtDvsnCode 권한구분코드 - 임시 - 권한체계 수정시 재설계
 */
export const putUser = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/cmn/user', params.userId].join('/'),
    data: params,
    // {
    //   celPhn: params.celPhn,
    //   cmpnCode: params.cmpnCode,
    //   csDprtCode: params.csDprtCode,
    //   dprtCode: params.dprtCode,
    //   hdqrCode: params.hdqrCode,
    //   ml: params.ml,
    //   name: params.name,
    //   nickName: params.nickName,
    //   nstlCtgrCode: params.nstlCtgrCode,
    //   pstnCode: params.pstnCode,
    //   thrtGrpId: params.thrtGrpId,
    //   useYn: params.useYn,
    // },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 사용자 삭제
 * DELETE /api/cmn/user/{userId}
 * @param params userId 사용자 아이디
 */
export const deleteUser = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cmn/user', params.userId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 상세 공통코드 조회
 * GET /api/cmn/detail-codes-all
 * @param params.grpCodeId 상위코드
 * @param params.containUnused 미사용코드 포함여부
 */
export const getDetailCodesAll = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/detail-codes-all',
    params: {
      grpCodeId: params.grpCodeId || '',
      containUnused: params.containUnused || false,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 사용자 ID 중복검사
 * GET /api/cmn/check-user-id
 * @param params lgnId 로그인 아이디
 */
export const getCheckUserId = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/check-user-id',
    params: {
      lgnId: params.lgnId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 비밀번호 초기화
 * GET /api/cmn/find-password
 * @param params ml 이메일
 */
export const getFindPassword = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/find-password',
    params: {
      lgnId: params.lgnId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * 비밀번호 변경
 * POST /api/cmn/change-password
 * @param params.newPswr 변경 비밀번호
 * @param params.pswr 임시 비밀번호
 */
export const postChanagePassword = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cmn/change-password',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 프로젝트별 작업 진행 현황
 * GET /api/cmn/dshb-work-prcd/{prjcCode}
 * @param params prjcCode 프로젝트 코드
 */
export const getDshbWorkPrcd = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cmn/dshb-work-prcd', params.prjcCode].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getDshbWorkPrcd error:', error);
    });

  return result;
};

/**
 * ESL 설치 작업 진척 현황
 * GET /api/cmn/dshb-esl
 */
export const getDshbEsl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/cmn/dshb-esl',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 목록
 * GET /api/cmn/dshb-prcd
 * @param params isDshb 대시보드 여부
 * @param params prjcCode 프로젝트 코드
 */
export const getDshbPrcd = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/dshb-prcd',
    params: {
      isDshb: params.isDshb || '', // 대시보드 여부
      prjcCode: params.prjcCode || '', // 프로젝트 코드
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 CS 목록
 * GET /api/cmn/dshb-prcd-cs
 */
export const getDshbPrcdCs = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cmn/dshb-prcd-cs',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 파일 다운로드
 * GET /api/cmn/file/download/{fileId}
 */
export const getFileDownloadFileId = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: ['/api/cmn/file/download', params.fileId].join('/'),
    responseType: 'blob',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};
