import React, { useState } from 'react';
import { Button, Calendar, Popup } from 'antd-mobile';
// import dayjs from 'dayjs';

interface IDayProps {
  dayState: [string | undefined | null, string | undefined | null];
  callback: (dayState: any) => void;
}
const CalendarComponent = ({ callback }: IDayProps): JSX.Element => {
  //state관리
  const [addPopup, setAddPopup] = useState<boolean>(false);
  const [searchDay, setSearchDay] = useState<[Date, Date] | null>(null);
  const [selectedDay, setSelectedDay] = useState<[Date, Date] | null>(null);
  //팝업 이벤트 추가
  const handlePopupCalendar = () => {
    setAddPopup(true);
  };
  //date선택
  const renderSelectedDates = () => {
    if (searchDay && searchDay[0] && searchDay[1]) {
      return (
        <div>
          {searchDay[0].toLocaleDateString()} ~{' '}
          {searchDay[1].toLocaleDateString()}
        </div>
      );
    } else {
      return null;
    }
  };
  //캘린더 날짜 변경
  const handleCalendarChange = (value: [Date, Date] | null) => {
    if (value) {
      const startDate = value[0];
      // .toISOString().slice(0, 10).replace(/-/g, '');
      const endDate = value[1];
      setSearchDay([startDate, endDate]);
      callback([
        startDate.toISOString().slice(0, 10).replace(/-/g, ''),
        endDate.toISOString().slice(0, 10).replace(/-/g, ''),
      ]);
    } else {
      setSearchDay(null);
    }
  };
  //캘린더 적용하기
  const handleApply = () => {
    setSelectedDay(searchDay);
    if (searchDay) {
      callback([
        searchDay[0].toISOString().slice(0, 10).replace(/-/g, ''),
        searchDay[1].toISOString().slice(0, 10).replace(/-/g, ''),
      ]);
    }
    setAddPopup(false);
  };
  //캘린더 취소하기
  const handleCancel = () => {
    setSearchDay(selectedDay); // 취소 버튼을 누르면 이전에 선택된 값을 복원
    setAddPopup(false);
    setSearchDay(null);
  };

  return (
    <div>
      <Button
        onClick={handlePopupCalendar}
        className="w-full !border-indigo-200"
      >
        <span className="text-md text-indigo-500">
          {renderSelectedDates() ? renderSelectedDates() : '전체'}
        </span>
      </Button>
      <Popup
        visible={addPopup}
        onMaskClick={() => {
          setAddPopup(false);
        }}
        onClose={() => {
          setAddPopup(false);
        }}
        bodyStyle={{ height: '70%' }}
      >
        <div className="text-xl w-full h-12 flex justify-center items-center border-b">
          <p className="font-bold">요청 기간</p>
        </div>
        <div className="w-full flex justify-between items-center mt-5 px-5">
          <Button onClick={handleCancel} className="w-18">
            취소
          </Button>
          <Button onClick={handleApply} className="w-18">
            적용
          </Button>
        </div>

        <Calendar
          selectionMode="range"
          style={{ scale: '90%' }}
          value={searchDay}
          onChange={(day) => {
            handleCalendarChange(day);
          }}
        />
      </Popup>
    </div>
  );
};

export default CalendarComponent;
