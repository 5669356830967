import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ILoginUser } from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { Login } from '../popups/Login';
import { LanguageSelect } from '../components/LanguageSelect';
import { useTranslation } from 'react-i18next';

/**
 * 사용자 > 로그인
 * @constructor
 */
const LoginPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="relative w-screen h-screen">
      {/* 로고 */}
      <div className="fixed left-0 top-0 w-full px-2 py-3 flex justify-start items-center bg-indigo-900">
        <div className="company-logo w-20 h-10 bg-auto bg-no-repeat bg-center" />
      </div>

      {/* 로그인 */}
      <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-5 z-20">
        {/* 로그인 */}
        <Login />
      </div>

      {/* 저작권 */}
      <div className="fixed left-0 bottom-5 w-full flex justify-center items-center">
        <span className="text-xs text-gray-500 font-semibold">
          Copyright ⓒ 2023 Dtonic all rights reserved.
        </span>
      </div>
    </div>
  );
};

export default LoginPage;
