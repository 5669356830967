import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { selectedPageStore } from '../stores/selectedPage.store';
import { PageLayout } from '../layouts/PageLayout';
import { Cascader, CascaderView } from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthPickerInput } from '@mantine/dates';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import _ from 'lodash';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiListResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ITableColumn,
  IPageContent,
  IModal,
  IActionSheet,
  IPopup,
  IApiResult,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { pageContentStore } from '../stores/page.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import * as cmnApi from '../apis/cmn.api';
import * as csApi from '../apis/cs.api';
import { PbFormBox } from '../components/PbFormBox';
import { ActionSheet, Button, InfiniteScroll, Input, List } from 'antd-mobile';
import * as ntcbApi from '../apis/ntcb.api';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { PbFormBoxItem } from '../components/PbFormBoxItem';
import { nanoid } from 'nanoid';
import { AlarmLog } from '../components/AlarmLog';
import { addPopupStore } from '../stores/popup.store';
import { FaqDetail } from '../popups/FaqDetail';
import { getFaqCsType } from '../apis/ntcb.api';

/**
 * 게시판 > FAQ
 * @constructor
 */

// 목록 데이터의 현재 페이지 번호를 정의함
let valCurrentPage: number = 0;

// FAQ 접수 유형을 정의함
let valFaqType: string = '';

const FaqPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  const [options, setOptions] = useState<string | any>([]);

  // FAQ 접수 유형 옵션을 정의함
  const [faqTypeOption, setFaqTypeOption] = useState<any[]>([]);

  // 선택한 FAQ 접수 유형을 정의함
  const [faqType, setFaqType] = useState<string[]>([]);

  // 선택한 FAQ 접수 유형 문자열을 정의함
  const [faqTypeText, setFaqTypeText] = useState<JSX.Element[]>([]);

  /**
   * 공통 관련
   */

  // 공통코드를 불러옴
  const getCommonCode = (
    code: string,
    defaultItem: IOptionItem | null = null,
    callback: Function = () => {},
  ) => {
    let tmpOptionItem: Action[] = [];

    // 상세 공통코드를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: code })
      .then((data: IApiListResult) => {
        // 기본 아이템이 있으면 아이템 목록에 추가함
        if (defaultItem !== null) {
          tmpOptionItem.push({
            text: defaultItem.label,
            key: defaultItem.value,
          });
        }

        // 불러온 데이터를 아이템 목록에 추가함
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              text: item.dtlCodeDscr,
              key: item.dtlCodeId,
            });
          },
        );

        // 콜백 함수를 실행함
        callback(tmpOptionItem);
      });
  };

  /**
   * 검색 관련
   */

  // 검색 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event);
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 목록 데이터를 초기화함
    setListData([]);

    // 목록 데이터의 현재 페이지 번호를 초기화함
    valCurrentPage = 0;

    // 목록 데이터를 추가로 불러옴
    loadMoreListData();
  };

  /**
   * 목록 관련
   */

  // 목록 데이터를 정의함
  const [listData, setListData] = useState<any[]>([]);

  // 추가로 불러올 목록 데이터의 여부를 정의함
  const [hasMoreListData, setHasMoreListData] = useState<boolean>(true);

  // 목록 데이터의 현재 페이지 번호를 정의함
  const [currentPage, setCurrentPage] = useState<number>(0);

  // 목록 데이터를 추가로 불러옴
  const loadMoreListData = async () => {
    // 불러올 페이지 번호를 증가함
    valCurrentPage += 1;

    // 목록을 불러옴
    getListData(valCurrentPage, (data: any[]) => {
      // 불러온 데이터를 목록 데이터에 추가함
      setListData((pre: any) => [...pre, ...data]);

      // 불러온 데이터가 있으면 추가로 불러올 목록 데이터가 존재한다고 적용함
      setHasMoreListData(data.length > 0);
    });
  };

  // FAQ 목록을 불러옴
  const getListData = (
    paramCurrentPage: number = 1,
    callback: Function = () => {},
  ) => {
    ntcbApi
      .getFaqs({
        currPageIdx: paramCurrentPage,
        cstyGrpCode: valFaqType[0],
        cstyCode: valFaqType[1],
        pageSize: 30,
        searchKeyword: searchInput.trim(),
      })
      .then((data: IApiListResult) => {
        if (data.data.list !== undefined) {
          callback(data.data.list);
        }
      });
  };

  // 목록의 행을 클릭함
  const handleListData_onClick = (data: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: data.ttl,
      content: <FaqDetail id={data.brdId} />,
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
    });
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // FAQ 접수 유형 옵션을 정의함
    let tmpFaqTypeOption: any[] = [];

    // FAQ 접수 유형 옵션에 기본값을 추가함
    setFaqTypeOption([
      {
        label: '전체',
        value: '',
      },
    ]);

    // FAQ 접수 유형을 불러옴
    ntcbApi
      .getFaqCsType({
        depth: 1,
        searchKeyword: '',
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          data.data.list.map((item: any) => {
            // 옵션에 추가함
            tmpFaqTypeOption.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
              children: [],
            });

            // FAQ 접수 상세 유형을 불러옴
            ntcbApi
              .getFaqCsType({
                depth: 2,
                searchKeyword: item.dtlCodeId,
              })
              .then((subData: IApiResult) => {
                // 상세 유형의 부모를 찾음
                let tmpFaqTypeOptionParent: any = _.find(tmpFaqTypeOption, {
                  value: item.dtlCodeId,
                });

                // 상세 유형에 기본값을 추가함
                tmpFaqTypeOptionParent.children.push({
                  label: '전체',
                  value: '',
                });

                if (subData.data.list !== undefined) {
                  // 옵션의 자식에 추가함
                  subData.data.list.map((subItem: any) => {
                    tmpFaqTypeOptionParent.children.push({
                      label: subItem.dtlCodeDscr,
                      value: subItem.dtlCodeId,
                    });
                  });
                }

                // FAQ 접수 유형 옵션에 부모 + 자식을 추가함
                setFaqTypeOption((pre: any[]) => [
                  ...pre,
                  tmpFaqTypeOptionParent,
                ]);
              })
              .catch((error: any) => {});
          });
        }
      })
      .catch((error: any) => {});

    // 목록 데이터를 추가로 불러옴
    loadMoreListData();

    return () => {};
  }, []);

  // 선택한 FAQ 접수 유형이 변경됐을 때 실행함
  useEffect(() => {
    let tmpFaqTypeText: JSX.Element[] = [];

    // 선택한 FAQ 접수 유형을 문자열로 변환함
    if (faqType?.length > 0) {
      // 부모 옵션에서 선택한 FAQ 접수 유형을 찾음
      let tmpStep1: any = _.find(faqTypeOption, { value: faqType[0] });

      // 코드에 해당하는 문자열을 추가함
      tmpFaqTypeText.push(
        <div>
          <span className="text-md text-indigo-500">{tmpStep1.label}</span>
        </div>,
      );

      if (faqType.length > 1) {
        // 부모 옵션의 자식 옵션에서 선택한 FAQ 접수 상세 유형을 찾음
        let tmpStep2: any = _.find(tmpStep1.children, { value: faqType[1] });

        // 코드에 해당하는 문자열을 추가함
        tmpFaqTypeText.push(
          <div>
            <span className="text-md text-indigo-500">{tmpStep2.label}</span>
          </div>,
        );
      }
    }

    // 선택한 FAQ 접수 유형이 없으면 '전체'로 적용함
    if (tmpFaqTypeText.length === 0) {
      tmpFaqTypeText.push(
        <div>
          <span className="text-md text-indigo-500">전체</span>
        </div>,
      );
    }

    // 선택한 FAQ 접수 유형 문자열에 적용함
    setFaqTypeText(tmpFaqTypeText);

    return () => {};
  }, [faqType]);

  return (
    <PageLayout pageInfoBarLeftArea="FAQ">
      {/* 검색 폼 박스 */}
      <PbFormBox label="검색">
        <div className="space-y-3">
          {/* 항목 */}
          <PbFormBoxItem label="FAQ 접수 유형">
            {/* 버튼 */}
            <Button
              size="middle"
              fill="outline"
              onClick={async () => {
                const result: any = await Cascader.prompt({
                  options: faqTypeOption,
                  title: 'FAQ 접수 유형',
                  placeholder: '선택하세요',
                  cancelText: '취소',
                  confirmText: '적용',
                });

                // 선택한 FAQ 유형에 적용함
                setFaqType(result === null ? [''] : result);

                // FAQ 접수 유형에 적용함
                valFaqType = result === null ? [''] : result;

                // 목록 데이터를 초기화함
                setListData([]);

                // 목록 데이터의 현재 페이지 번호를 초기화함
                valCurrentPage = 0;

                // 목록 데이터를 추가로 불러옴
                loadMoreListData();
              }}
              className="w-full !border-indigo-200"
            >
              <div className="space-y-1">{faqTypeText}</div>
            </Button>
          </PbFormBoxItem>

          {/* 항목 */}
          <PbFormBoxItem label="검색어">
            {/* 텍스트 입력 */}
            <Input
              placeholder="검색어를 입력하세요."
              onChange={handleSearchInput_onChange}
              onEnterPress={handleSearchButton_onClick}
              value={searchInput}
              clearable={true}
            />
          </PbFormBoxItem>

          {/* 항목 */}
          <PbFormBoxItem>
            {/* 버튼 */}
            <Button
              size="large"
              shape="default"
              onClick={handleSearchButton_onClick}
              className="w-full !bg-indigo-700"
            >
              <span className="text-md text-white">검색</span>
            </Button>
          </PbFormBoxItem>
        </div>
      </PbFormBox>

      {/* 목록 폼 박스 */}
      <List className="!rounded-lg overflow-hidden">
        {listData.map((item: any, index: number) => (
          <List.Item key={index}>
            <div
              onClick={() => handleListData_onClick(item)}
              className="button-event"
            >
              {/* 제목 */}
              <div className="">
                <span className="text-base text-gray-700">{item.ttl}</span>
              </div>

              {/* 분류, 조회수, 작성일시 */}
              <div className="w-full flex justify-between items-center">
                <div className="space-x-1">
                  <span className="text-xs text-indigo-600 font-bold">
                    {item.cstyGrpName}
                  </span>

                  <span className="text-xs text-indigo-600 font-bold">-</span>

                  <span className="text-xs text-indigo-600 font-bold">
                    {item.cstyName}
                  </span>
                </div>

                <div className="flex justify-center items-center space-x-1">
                  <span className="text-xs text-gray-400 font-bold">
                    {item.vws}
                  </span>
                  <span className="text-xs text-gray-200">|</span>
                  <span className="text-xs text-gray-400">{item.rgstDate}</span>
                </div>
              </div>
            </div>
          </List.Item>
        ))}
      </List>

      {/* 더 불러오기 */}
      <div className="flex justify-center items-center">
        <div
          onClick={loadMoreListData}
          className="translateZ button-event px-10 py-2 space-y-1 animate-pulse"
        >
          <div className="flex justify-center items-center">
            <span className="text-xs text-gray-500">더 불러오기</span>
          </div>

          <div className="flex justify-center items-center">
            <FontAwesomeIcon
              icon={['fas', 'chevron-down']}
              className="w-3 h-3 text-gray-500"
            />
          </div>
        </div>
      </div>

      {/*<InfiniteScroll*/}
      {/*  loadMore={loadMoreListData}*/}
      {/*  hasMore={hasMoreListData}*/}
      {/*  className="!h-1"*/}
      {/*>*/}
      {/*  <span className="text-xs text-gray-400 !h-1">*/}
      {/*    불러올 데이터가 더 이상 없습니다.*/}
      {/*  </span>*/}
      {/*</InfiniteScroll>*/}
    </PageLayout>
  );
};

export default FaqPage;
