import {
  IActionSheet,
  IApiResult,
  IFileNameExtension,
  ILoginUser,
  IToken,
} from '../interfaces/app.interface';
import * as cmnApi from '../apis/cmn.api';
import _ from 'lodash';
import { Action } from 'antd-mobile/es/components/action-sheet';

/**
 * 공통 함수
 */

/**
 * API URL을 불러옴
 */
export const getApiUrl = (): string => {
  let result: string = '';

  if (process.env.REACT_APP_API_PORT === '80') {
    result = `${process.env.REACT_APP_API_HOST}`;
  } else {
    result = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
  }

  return result;
};

/**
 * Resize Image URL을 불러옴
 */
export const getResizeImageUrl = (): string => {
  let result: string = process.env.REACT_APP_RESIZE_IMAGE_PATH || '';

  return result;
};

/**
 * Thumbnail Image URL을 불러옴
 */
export const getThumbnailImageUrl = (): string => {
  let result: string = process.env.REACT_APP_THUMBNAIL_IMAGE_PATH || '';

  return result;
};

/**
 * 로그인한 사용자 토큰을 Local Storage에 저장함
 * @param params 토큰
 */
export const setToken = async (params: IToken) => {
  await localStorage.setItem('token', btoa(JSON.stringify(params) || ''));
};

/**
 * 로그인한 사용자 토큰을 Local Storage에서 불러옴
 */
export const getToken = (): IToken => {
  let data: IToken = {
    email: '',
    userId: '',
    tokenType: '',
    accessToken: '',
    accessTokenExpireSecond: 0,
    refreshToken: '',
    refreshTokenExpireSecond: 0,
  };

  if (localStorage.getItem('token')) {
    data = JSON.parse(atob(localStorage.getItem('token') || ''));
  }

  return data;
};

/**
 * 로그인한 사용자 토큰을 Local Storage에서 삭제함
 */
export const removeToken = () => {
  localStorage.removeItem('token');
};

/**
 * 로그인한 사용자를 Local Storage에 저장함
 * @param params 사용자
 */
export const setLoginUser = async (params: any) => {
  await localStorage.setItem('loginUser', btoa(JSON.stringify(params) || ''));
};

/**
 * 로그인한 사용자를 Local Storage에서 불러옴
 */
export const getLoginUser = (): ILoginUser => {
  let data: ILoginUser = {
    id: '',
    name: '',
  };

  if (localStorage.getItem('loginUser')) {
    data = JSON.parse(atob(localStorage.getItem('loginUser') || ''));
  }

  console.log('> login:', data);

  return data;
};

/**
 * 로그인한 사용자를 Local Storage에서 삭제함
 */
export const removeLoginUser = () => {
  localStorage.removeItem('loginUser');
};

/**
 * 현재 언어를 Local Storage에 저장함
 * @param params 선택한 언어
 */
export const setLanguage = async (params: any) => {
  await localStorage.setItem('language', params || 'en');
};

/**
 * 현재 언어를 Local Storage에서 불러옴
 */
export const getLanguage = (): string => {
  let data: string = '';

  if (localStorage.getItem('language')) {
    data = localStorage.getItem('language') || 'en';
  } else {
    data = 'en';
  }

  return data;
};

/**
 * 로그인 아이디를 Local Storage에 저장함
 * @param params 아이디
 */
export const setSaveId = async (params: any) => {
  await localStorage.setItem('saveId', params || '');
};

/**
 * 로그인 아이디를 Local Storage에서 불러옴
 */
export const getSaveId = (): string => {
  let data: string = '';

  if (localStorage.getItem('saveId')) {
    data = localStorage.getItem('saveId') || '';
  } else {
    data = '';
  }

  return data;
};

/**
 * 로그인한 사용자 정보에서 협력사 권한이 포함되어 있는지 판단함
 * @param loginUser 로그인한 사용자 정보
 * @return 협력사 권한 포함 여부(true: 포함, false: 미포함)
 */
export const checkPartner = (loginUser: ILoginUser): boolean => {
  let result: number = _.findIndex(loginUser.authorityGroup, {
    thrtName: '협력사',
  });

  return result > -1 ? true : false;
};

/**
 * 액션시트에서 선택한 아이템의 레이블을 불러옴
 * @param actionSheet 액션시트
 */
export const getActionSheetLabel = (actionSheet: IActionSheet): string => {
  let tmpOptionItem: Action | undefined | null = _.find(actionSheet.item, {
    key: actionSheet.value,
  });

  return tmpOptionItem?.text?.toString() || '';
};

/**
 * 파일명에서 파일 확장자 및 아이콘을 추출함
 * @param fileName 파일명
 */
export const getFileNameExtension = (fileName: string): IFileNameExtension => {
  // 확장자를 정의함
  let tmpExt: IFileNameExtension[] = [
    { ext: '.jpg', icon: ['far', 'image'], isImage: true },
    { ext: '.jpeg', icon: ['far', 'image'], isImage: true },
    { ext: '.png', icon: ['far', 'image'], isImage: true },
    { ext: '.gif', icon: ['far', 'image'], isImage: true },
    { ext: '.bmp', icon: ['far', 'image'], isImage: true },
    { ext: '.tif', icon: ['far', 'image'], isImage: true },
    { ext: '.mp3', icon: ['far', 'file-audio'], isImage: false },
    { ext: '.mp4', icon: ['far', 'file-video'], isImage: false },
    { ext: '.pdf', icon: ['far', 'file-pdf'], isImage: false },
    { ext: '.xls', icon: ['far', 'file-excel'], isImage: false },
    { ext: '.xlsx', icon: ['far', 'file-excel'], isImage: false },
    { ext: '.ppt', icon: ['far', 'file-powerpoint'], isImage: false },
    { ext: '.pptx', icon: ['far', 'file-powerpoint'], isImage: false },
    { ext: '.doc', icon: ['far', 'file-word'], isImage: false },
    { ext: '.docx', icon: ['far', 'file-word'], isImage: false },
    { ext: '.txt', icon: ['far', 'file-lines'], isImage: false },
    { ext: '.xml', icon: ['far', 'file-lines'], isImage: false },
    { ext: '.json', icon: ['far', 'clone'], isImage: false },
    { ext: '.zip', icon: ['far', 'file-zipper'], isImage: false },
    { ext: '.tar', icon: ['far', 'file-zipper'], isImage: false },
    { ext: '.gz', icon: ['far', 'file-zipper'], isImage: false },
    { ext: '.7z', icon: ['far', 'file-zipper'], isImage: false },
  ];

  // 결과를 정의함
  let tmpResult: IFileNameExtension = {
    ext: '',
    icon: ['far', 'note-sticky'],
    isImage: false,
  };

  // 파일명에서 확장자를 추출함
  let tmpFileExt: string = _.padEnd(fileName, 5);

  // 파일 확장자를 추출함
  tmpExt.map((item: IFileNameExtension) => {
    if (_.includes(tmpFileExt, item.ext)) {
      tmpResult = item;
    }
  });

  return tmpResult;
};

/**
 *
 * @param actionSheet 액션시트
 */

/**
 * 스크롤 버튼의 세로 위치를 이동함
 * @param id 페이지 또는 팝업 아이디
 * @param pageType 페이지 또는 팝업 구분
 * @param existBottomButton 하단 버튼 존재 여부
 */
export const moveVerticalPositionScrollButton = (
  pageType: 'page' | 'popup' = 'page',
  id: string = '',
  existBottomButton: boolean = false,
) => {
  let tmpScrollButton: HTMLDivElement | null = null;

  switch (pageType) {
    // 페이지
    case 'page':
      tmpScrollButton = document.querySelector(
        '#scroll-button',
      ) as HTMLDivElement;
      break;

    // 팝업
    case 'popup':
      tmpScrollButton = document.querySelector(
        `#scroll-area-${id} #scroll-button`,
      ) as HTMLDivElement;
      break;

    default:
      break;
  }

  if (tmpScrollButton !== null) {
    // 화면에 출력함
    tmpScrollButton.style.display = 'block';

    // 하단 버튼이 존재하는지 확인함
    if (existBottomButton) {
      tmpScrollButton.style.bottom = '5rem';
    } else {
      tmpScrollButton.style.bottom = '2rem';
    }
  }
};

/**
 * 팝업의 최상단으로 페이지를 스크롤함
 * @param id 페이지 또는 팝업 아이디
 * @param useSmoothScroll 부드러운 스크롤 사용
 */
export const scrollToPopupTop = (
  id: string = '',
  useSmoothScroll: boolean = false,
) => {
  let scrollArea = document.querySelector(
    `#scroll-area-${id}`,
  ) as HTMLDivElement;

  if (useSmoothScroll) {
    scrollArea?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    scrollArea?.scrollTo({
      top: 0,
    });
  }
};

// 숫자에 콤마를 추가함
export const getFormatNumber = (number: any) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
