import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IComponentProps {
  label?: string;
  rightForm?: JSX.Element;
  className?: string;
  onClick?: () => void;
}

/**
 * 폼 박스
 * @param label 레이블
 * @param rightForm 레이블 오른쪽 폼 영역
 * @param className CSS 클래스
 * @param onClick <이벤트>
 * @param children 기타 props
 * @constructor
 */
const PbFormBox = ({
  label = '',
  rightForm = <></>,
  className,
  onClick,
  children,
}: PropsWithChildren<IComponentProps>) => {
  return (
    <div
      className={[
        'w-full pt-3 pb-5 bg-white rounded-md space-y-3',
        className,
      ].join(' ')}
    >
      {/* 정보 */}
      <div className="flex justify-between items-center">
        {/* 제목 */}
        <div onClick={onClick} className="pl-4 truncate">
          <span className="text-xl text-gray-600 font-bold">{label}</span>
        </div>

        {/* 오른쪽 폼 */}
        <div className="pr-4 space-x-2">{rightForm}</div>
      </div>

      {/* 내용 */}
      {children !== undefined && <div className="px-4">{children}</div>}
    </div>
  );
};

export default PbFormBox;
