import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IApiResult, ILoginUser, IPopup } from '../../interfaces/app.interface';
import * as nstlApi from '../../apis/nstl.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { decode } from 'html-entities';
import { Button, Collapse, Ellipsis, List } from 'antd-mobile';
import { nanoid } from 'nanoid';
import { useRecoilState } from 'recoil';
import { addPopupStore } from '../../stores/popup.store';
import { OngoingJobManagementDetailResult } from '../OngoingJobManagementDetailResult';
import { Comment } from '../Comment';
import { loginStore } from '../../stores/login.store';

interface IPopupProps {
  dcmnDntfNmbr: string;
  onClick?: () => void;
}

/**
 * 진행 중 작업 관리 > 상세 조회
 * @param id <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const OngoingJobManagementDetail = ({
  dcmnDntfNmbr,
  onClick,
}: PropsWithChildren<IPopupProps>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 항목의 값이 없을 때, 빈 값을 반환함
  const displayValue = (value: any): any => {
    return value ? value : <span className="text-gray-300">정보 없음</span>;
  };

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    nstlApi
      .getPrcdsDcmnDntfNmbr({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        console.log('> data.data:', data.data);
        if (data.data !== undefined) {
          callback(data.data);
        }
      });
  };

  // 네트워크 공사 작업 내역의 작업결과 버튼을 클릭함
  const handleNetworkList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: `${detailData.shipToCode || 'Shop 미지정'} - 네트워크 작업결과`,
      content: (
        <OngoingJobManagementDetailResult
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          detailData={event}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 상세 내용에 적용함
              setDetailData(data);
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // ESL 설치 작업 내역의 작업결과 버튼을 클릭함
  const handleEslList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: `${detailData.shipToCode || 'Shop 미지정'} - ESL 작업결과`,
      content: (
        <OngoingJobManagementDetailResult
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          detailData={event}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 상세 내용에 적용함
              setDetailData(data);
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 상세 내용에 적용함
      setDetailData(data);
    });

    return () => {};
  }, []);

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    if (detailData === null) {
      return;
    }

    // loginUser.cmpnCode === "PRTN"

    console.log('> loginUser:', loginUser);
    console.log('> detailData.installPrtnList:', detailData.installPrtnList);

    return () => {};
  }, [detailData]);

  return (
    <div className="">
      {detailData !== null && (
        <div className="">
          {/* 고객사/지점 정보 리스트 */}
          <List header="고객사/지점 정보" className="bg-gray-100/90">
            <List.Item title="고객사명">
              {displayValue(detailData.bpName)}
            </List.Item>
            <List.Item title="Shop명">
              {displayValue(detailData.shipToCode)}
            </List.Item>
            <List.Item title="Shop주소">
              {displayValue(detailData.dlvrDrs)}
            </List.Item>
          </List>

          {/* 네트워크 공사 작업 내역 리스트 */}
          <List header="네트워크 공사 작업 내역" className="bg-gray-100/90">
            <Collapse accordion={false}>
              {detailData.installPrtnList
                .filter(
                  (filterItem: any) => filterItem.splrTypeCode === 'NTWR_CNST',
                )
                .map((item: any, index: number) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={
                      <div className="flex justify-between items-center space-x-2">
                        <Ellipsis
                          direction="end"
                          content={displayValue(item.ttl)}
                        />

                        <div className="pr-1">
                          <span>{displayValue(item.nstlSttsName)}</span>
                        </div>
                      </div>
                    }
                  >
                    <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                      <List.Item
                        title="내용"
                        onClick={() => handleNetworkList_onClick(item)}
                        clickable
                        extra="작업결과"
                        className="!bg-gray-100/90"
                      >
                        {displayValue(item.ttl)}
                      </List.Item>
                      <List.Item title="상태" className="!bg-gray-100/90">
                        {displayValue(item.nstlSttsName)}
                      </List.Item>
                      <List.Item title="작업일정" className="!bg-gray-100/90">
                        {displayValue(item.workStrtDttm)} ~{' '}
                        {displayValue(item.workEndDttm)}
                      </List.Item>
                      <List.Item title="협력사명" className="!bg-gray-100/90">
                        {displayValue(item.prtnName)}
                      </List.Item>
                    </List>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </List>

          {/* ESL 설치 작업 내역 리스트 */}
          <List header="ESL 설치 작업 내역" className="bg-gray-100/90">
            <Collapse accordion={false}>
              {detailData.installPrtnList
                .filter(
                  (filterItem: any) => filterItem.splrTypeCode === 'ESL_NSTL',
                )
                .map((item: any, index: number) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={
                      <div className="pr-1 flex justify-between items-center space-x-2">
                        <Ellipsis
                          direction="end"
                          content={displayValue(item.ttl)}
                        />

                        <div>
                          <span>{displayValue(item.nstlSttsName)}</span>
                        </div>
                      </div>
                    }
                  >
                    <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                      <List.Item
                        title="내용"
                        onClick={() => handleEslList_onClick(item)}
                        clickable
                        extra="작업결과"
                        className="!bg-gray-100/90"
                      >
                        {displayValue(item.ttl)}
                      </List.Item>
                      <List.Item title="상태" className="!bg-gray-100/90">
                        {displayValue(item.nstlSttsName)}
                      </List.Item>
                      <List.Item title="작업일정" className="!bg-gray-100/90">
                        {displayValue(item.workStrtDttm)} ~{' '}
                        {displayValue(item.workEndDttm)}
                      </List.Item>
                      <List.Item title="협력사명" className="!bg-gray-100/90">
                        {displayValue(item.prtnName)}
                      </List.Item>
                    </List>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </List>

          {/* 설치 계획 품목 리스트 */}
          <List header="설치 계획 품목" className="bg-gray-100/90">
            <Collapse accordion={false}>
              {detailData.installItemList.map((item: any, index: number) => (
                <Collapse.Panel
                  key={index.toString()}
                  title={
                    <div className="pr-1 flex justify-between items-center space-x-2">
                      <Ellipsis
                        direction="end"
                        content={decode(displayValue(item.itemDtls))}
                      />

                      <div>
                        <span>{displayValue(item.qntt)}</span>
                      </div>
                    </div>
                  }
                >
                  <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                    <List.Item title="품목번호" className="!bg-gray-100/90">
                      {displayValue(item.itemNmbr)}
                    </List.Item>
                    <List.Item title="품목명" className="!bg-gray-100/90">
                      {decode(displayValue(item.itemDtls))}
                    </List.Item>
                    <List.Item title="제조업체" className="!bg-gray-100/90">
                      {displayValue(item.mnfcName)}
                    </List.Item>
                    <List.Item title="창고" className="!bg-gray-100/90">
                      {displayValue(item.wrhsName)}
                    </List.Item>
                    <List.Item title="수량" className="!bg-gray-100/90">
                      {displayValue(item.qntt)}
                    </List.Item>
                  </List>
                </Collapse.Panel>
              ))}
            </Collapse>
          </List>
        </div>
      )}
    </div>
  );
};

export default OngoingJobManagementDetail;
