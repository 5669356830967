import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decode } from 'html-entities';
import { Ellipsis } from 'antd-mobile';

interface IComponentProps {
  title?: string;
  rightArea?: any;
  data?: any;
  onClick?: () => void;
  className?: string;
}

/**
 * 상단 자석 내용 헤더
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const StickyContentHeader = ({
  title = '',
  rightArea = <></>,
  data,
  onClick,
  className = '',
  children,
}: PropsWithChildren<IComponentProps>) => {
  return (
    <div className={['sticky pt-2 bg-white rounded z-20', className].join(' ')}>
      <div className="px-3 h-10 flex justify-between items-center bg-slate-600 rounded-sm space-x-3">
        {/* 왼족 영역 */}
        <div className="flex justify-center items-center space-x-2">
          {/* 제목 */}
          {/*<div className="flex justify-center items-center">*/}
          {/*  <FontAwesomeIcon*/}
          {/*    icon={['far', 'bookmark']}*/}
          {/*    className="w-3 h-3 text-white"*/}
          {/*  />*/}
          {/*</div>*/}

          <span className="text-base text-white font-semibold">
            <Ellipsis direction="end" content={decode(title)} />
          </span>
        </div>

        {/* 오른쪽 영역 */}
        <div className="flex justify-center items-center">{rightArea}</div>
      </div>
    </div>
  );
};

export default StickyContentHeader;
