import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import * as appUtil from './app.util';
import * as cmnApi from '../apis/cmn.api';
import { IApiResult, ILoginUser, IToken } from '../interfaces/app.interface';

// 인스턴스를 생성함
const instance: AxiosInstance = axios.create({
  baseURL: appUtil.getApiUrl(),
});

// 개발자가 요청을 한 후, 실제 요청을 하기 전에 처리함
instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // 로그인한 사용자 토큰을 Local Storage에서 불러옴
    let token: IToken = appUtil.getToken();

    // 임시!
    // console.log('> token.accessToken:', token.accessToken);

    if (token.accessToken) {
      // 갱신된 토큰을 불러옴
      await cmnApi
        .postRefreshToken()
        .then((data: IApiResult) => {
          token = {
            email: data.data.email,
            userId: data.data.userId,
            tokenType: data.data.tokenType,
            accessToken: data.data.accessToken,
            accessTokenExpireSecond: data.data.accessTokenExpireSecond,
            refreshToken: data.data.refreshToken,
            refreshTokenExpireSecond: data.data.refreshTokenExpireSecond,
          };
        })
        .catch((error: any) => {
          console.log('> getRefreshToken error:', error);

          // 로그인 페이지로 이동함
          window.location.href = '/login';
        });
    } else {
    }

    try {
      if (token.accessToken) {
        if (config.headers.Authorization !== 'none') {
          // 토큰을 Axios 헤더에 추가함
          config.headers.Authorization = `${token.tokenType}${token.accessToken}`;
        }

        // 로그인한 사용자 토큰을 Local Storage에 저장함
        await appUtil.setToken(token);
      }

      return config;
    } catch (error: any) {
      console.log('> axios.interceptors.request error:', error);
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

// 결과를 받고 개발자가 결과를 확인하기 전에 처리함
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Status가 200인 경우(성공)
    // 결과를 처리하는 소스에서 .then()으로 넘어감
    return response;
  },

  (error: any) => {
    // Status가 200이 아닌 경우(실패)
    // 결과를 처리하는 소스에서 .catch()로 넘어감
    return Promise.reject(error);
  },
);

export default instance;
