import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ActionSheet, Button, Input, List } from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { IActionSheet, IApiResult } from '../../interfaces/app.interface';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { removePopupStore } from '../../stores/popup.store';
import * as nstlApi from '../../apis/nstl.api';
import { decode } from 'html-entities';

interface IPopupProps {
  id: string;
  dcmnDntfNmbr: string;
  data?: any | null;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 품목 선택
 * @param data 품목(신규일 때는 null)
 * @param onClick <이벤트>
 * @constructor
 */
const ModifyProduct = ({
  id,
  dcmnDntfNmbr,
  data,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색어 입력을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event);
  };

  // 품목 목록을 정의함
  const [product, setProduct] = useState<any[]>([]);

  // 필터링된 품목 목록을 정의함
  const [filterProduct, setFilterProduct] = useState<any[]>([]);

  // 품목 목록을 불러옴
  const getProductList = () => {
    nstlApi
      .getNstlItems({
        dcmnDntfNmbr: dcmnDntfNmbr,
        searchKeyword: '',
        pageSize: 10000,
        currPageIdx: 1,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 품목 목록에 적용함
          setProduct(data.data.list);

          // 필터링된 품목 목록에 적용함
          setFilterProduct(data.data.list);
        }
      });
  };

  // 목록을 클릭함
  const handleList_onClick = (item: any) => {
    callback({
      preData: data,
      resultType: '',
      itemNmbr: item.itemNmbr,
      itemDtls: item.itemDtls,
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  // 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    // 부모창으로 결과를 넘김
    callback({
      preData: data,
      resultType: 'remove',
      itemNmbr: '',
      itemDtls: '',
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 필터링된 품목 목록에 적용함
    setFilterProduct(
      product.filter((item: any) =>
        _.includes(item.itemDtls.toLowerCase(), searchInput.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    // 품목 목록을 불러옴
    getProductList();

    return () => {};
  }, []);

  return (
    <div className="">
      {/* 버튼 */}
      {data !== null && (
        <div className="p-3 space-y-2">
          <Button
            size="large"
            shape="default"
            onClick={handleRemoveButton_onClick}
            className="w-full !bg-rose-700"
          >
            <span className="text-md text-white">품목 삭제</span>
          </Button>
        </div>
      )}

      {/* 목록 폼 박스 */}
      <List className="">
        <List.Item title="검색">
          <div className="space-y-3">
            {/* 텍스트 입력 */}
            <Input
              placeholder="검색어를 입력하세요."
              clearable={true}
              value={searchInput}
              onChange={handleSearchInput_onChange}
              onEnterPress={handleSearchButton_onClick}
            />

            <Button
              size="large"
              shape="default"
              onClick={handleSearchButton_onClick}
              className="w-full !bg-indigo-700"
            >
              <span className="text-md text-white">검색</span>
            </Button>
          </div>
        </List.Item>

        {filterProduct.map((item: any, index: number) => (
          <List.Item
            key={index}
            title={decode(item.itemNmbr)}
            description={decode(item.codeName)}
          >
            <div
              onClick={() => handleList_onClick(item)}
              className="button-event"
            >
              <div className="">
                <span className="text-base text-gray-700">
                  {decode(item.itemDtls)}
                </span>
              </div>
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default ModifyProduct;
