import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { selectedPageStore } from '../stores/selectedPage.store';
import { PageLayout } from '../layouts/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthPickerInput } from '@mantine/dates';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import _ from 'lodash';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ITableColumn,
  IPageContent,
  IModal,
  IActionSheet,
  IPopup,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { pageContentStore } from '../stores/page.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import * as cmnApi from '../apis/cmn.api';
import * as csApi from '../apis/cs.api';
import { PbFormBox } from '../components/PbFormBox';
import {
  ActionSheet,
  Button,
  Checkbox,
  Ellipsis,
  InfiniteScroll,
  Input,
  List,
} from 'antd-mobile';
import * as ntcbApi from '../apis/ntcb.api';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { PbFormBoxItem } from '../components/PbFormBoxItem';
import { nanoid } from 'nanoid';
import { AlarmLog } from '../components/AlarmLog';
import { addPopupStore } from '../stores/popup.store';
import { NoticeDetail } from '../popups/NoticeDetail';
import { SelectProject } from '../popups/SelectProject';
import * as nstlApi from '../apis/nstl.api';
import { OngoingJobManagementDetail } from '../popups/OngoingJobManagementDetail';
import { PbFullCalendar } from '../components/PbFullCalendar';
import { checkPartner } from '../utils/app.util';
import * as appUtil from '../utils/app.util';

/**
 * 설치 관리 > 진행 중 작업 관리
 * @constructor
 */

// 목록 데이터의 현재 페이지 번호를 정의함
let valCurrentPage: number = 0;

const OngoingJobManagementPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  /**
   * 공통 관련
   */

  // 액션시트 참조를 정의함
  const actionSheetHandlerRef = useRef<ActionSheetShowHandler>();

  // 공통코드를 불러옴
  const getCommonCode = (
    code: string,
    defaultItem: IOptionItem | null = null,
    callback: Function = () => {},
  ) => {
    let tmpOptionItem: Action[] = [];
    // 상세 공통코드를 불러옴
    cmnApi.getDetailCodesAll({ grpCodeId: code }).then((data: IApiResult) => {
      // 기본 아이템이 있으면 아이템 목록에 추가함
      if (defaultItem !== null) {
        tmpOptionItem.push({
          text: defaultItem.label,
          key: defaultItem.value,
        });
      }

      // 불러온 데이터를 아이템 목록에 추가함
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          text: item.dtlCodeDscr,
          key: item.dtlCodeId,
        });
      });

      // 콜백 함수를 실행함
      callback(tmpOptionItem);
    });
  };

  // 액션시트에서 선택한 아이템의 레이블을 불러옴
  const getActionSheetLabel = (actionSheet: IActionSheet) => {
    let tmpOptionItem: Action | undefined | null = _.find(actionSheet.item, {
      key: actionSheet.value,
    });

    return tmpOptionItem?.text || '';
  };

  // 목록을 초기화하고 목록을 다시 불러옴
  const getList = () => {
    // 목록 데이터를 초기화함
    setListData([]);

    // 목록 데이터의 현재 페이지 번호를 초기화함
    valCurrentPage = 0;

    // 목록 데이터를 추가로 불러옴
    loadMoreListData();
  };

  /**
   * 검색 관련
   */

  // 선택한 프로젝트를 정의함
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>({ label: '전체', value: '' });

  // 네트워크 공사 체크박스를 정의함
  const [networkCheckbox, setNetworkCheckbox] = useState<boolean>(true);

  // ESL 설치 체크박스를 정의함
  const [eslCheckbox, setEslCheckbox] = useState<boolean>(true);

  // 설치 상태 액션시트를 초기화함
  const initInstallStatusActionSheet = () => {
    let tmpOptionItem: Action[] = [];

    tmpOptionItem.push(
      { text: '전체작업', key: '' },
      { text: '진행중', key: 'prcd' },
      { text: '작업완료', key: 'work_cmpl' },
      { text: '지연', key: 'dly' },
    );

    // 설치 상태 액션시트에 적용함
    setInstallStatusActionSheet((pre: IActionSheet) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  // 프로젝트 선택 버튼을 클릭함
  const handleProject_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '프로젝트 선택',
      content: <SelectProject id={tmpId} callback={callbackSelectedProject} />,
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 선택한 프로젝트를 적용함
  const callbackSelectedProject = (item: any) => {
    // 선택한 프로젝트를 정의함
    setSelectedProject({
      label: item.prjcName,
      value: item.prjcCode,
    });
  };

  // 네트워크 공사 체크박스를 변경함
  const handleNetworkCheckbox_onChange = (event: any) => {
    setNetworkCheckbox(event);
  };

  // ESL 설치 체크박스를 변경함
  const handleEslCheckbox_onChange = (event: any) => {
    setEslCheckbox(event);
  };

  // 설치 상태 액션시트를 정의함
  const [installStatusActionSheet, setInstallStatusActionSheet] =
    useState<IActionSheet>({
      value: '',
      item: [],
    });

  /**
   * 목록 관련
   */

  // 목록 데이터를 정의함
  const [listData, setListData] = useState<any[]>([]);

  // 추가로 불러올 목록 데이터의 여부를 정의함
  const [hasMoreListData, setHasMoreListData] = useState<boolean>(true);

  // 목록 데이터를 추가로 불러옴
  const loadMoreListData = async () => {
    // 불러올 페이지 번호를 증가함
    valCurrentPage += 1;

    // 목록을 불러옴
    getListData(valCurrentPage, (data: any[]) => {
      // 불러온 데이터를 목록 데이터에 추가함
      setListData((pre: any) => [...pre, ...data]);

      // 불러온 데이터가 있으면 추가로 불러올 목록 데이터가 존재한다고 적용함
      setHasMoreListData(data.length > 0);
    });
  };

  // 목록을 불러옴
  const getListData = (
    paramCurrentPage: number = 1,
    callback: Function = () => {},
  ) => {
    nstlApi
      .getPrcds({
        isNtwrCnst: networkCheckbox ? 'Y' : 'N',
        isEslNstl: eslCheckbox ? 'Y' : 'N',
        isShowAll: 'Y',
        nstlSttsCode: installStatusActionSheet.value || '',
        prjcCode: selectedProject.value || '',
        // splrId: tmpSplrId,
        workBaseYearMonth: '',
        cmpnCode: loginUser.cmpnCode || '',
        userId: loginUser.id,
        currPageIdx: paramCurrentPage,
        pageSize: 30,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          console.log('> data.data.list:', data.data.list);

          callback(data.data.list);
        }
      })
      .catch((error: any) => {});
  };

  // 목록의 행을 클릭함
  const handleListData_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: `${event.shipToCode || 'Shop 미지정'} - ${event.nstlSttsName}`,
      content: <OngoingJobManagementDetail dcmnDntfNmbr={event.dcmnDntfNmbr} />,
      widthSizePercent: 100,
      heightSizePercent: 90,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  /**
   * 캘린더 관련
   */

  // 캘린더의 검색한 년월을 정의함
  const [searchYm, setSearchYm] = useState<string>(
    moment(new Date()).format('YYYYMM'),
  );

  // 캘린더 이벤트를 정의함
  const [calendarEvent, setCalendarEvent] = useState<any[]>([]);

  // 캘린더의 날짜를 클릭함
  const handleCalendar_onDateClick = (arg: any) => {
    let tmpId: string = `calendarEvent_${arg.dateStr}`;

    // // 컴포넌트 모달을 추가함
    // setAddComponentModal({
    //   id: tmpId,
    //   title: moment(arg.dateStr).format('YYYY년 MM월 DD일'),
    //   content: (
    //     <MN2302220301_Days
    //       id={tmpId}
    //       searchYmd={moment(arg.dateStr).format('YYYYMMDD')}
    //     />
    //   ),
    //   size: 750,
    //   closeOnClickOutside: true,
    // });
  };

  // 캘린더의 이벤트를 클릭함
  const handleCalendar_onEventClick = (arg: any) => {
    console.log('> event click:', arg);
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    return () => {};
  }, []);

  // 로그인한 상태일 때 실행함
  // 검색 항목이 변경될 때 실행함
  useEffect(() => {
    if (loginUser.id === '') {
      return;
    }

    // 설치 상태 액션시트를 초기화함
    initInstallStatusActionSheet();

    // 목록을 초기화하고 목록을 다시 불러옴
    getList();

    return () => {};
  }, [
    loginUser.id,
    selectedProject.value,
    networkCheckbox,
    eslCheckbox,
    installStatusActionSheet.value,
  ]);

  return (
    <PageLayout pageInfoBarLeftArea="진행 중 작업 관리">
      {/* 검색 폼 박스 */}
      <PbFormBox label="검색">
        <div className="space-y-3">
          {/* 항목 */}
          <PbFormBoxItem label="프로젝트">
            {/* 버튼 */}
            <Button
              size="middle"
              fill="outline"
              onClick={handleProject_onClick}
              className="w-full !border-indigo-200"
            >
              <span className="text-md text-indigo-500">
                {selectedProject.label}
              </span>
            </Button>
          </PbFormBoxItem>

          {/* 항목 */}
          <PbFormBoxItem label="진행 유형" className="hidden">
            <div className="flex justify-center items-center">
              <div className="w-1/2">
                {/* 체크박스 */}
                <Checkbox
                  onChange={handleNetworkCheckbox_onChange}
                  checked={networkCheckbox}
                >
                  네트워크 공사
                </Checkbox>
              </div>
              <div className="w-1/2">
                {/* 체크박스 */}
                <Checkbox
                  onChange={handleEslCheckbox_onChange}
                  checked={eslCheckbox}
                >
                  ESL 설치
                </Checkbox>
              </div>
            </div>
          </PbFormBoxItem>

          {/* 항목 */}
          <PbFormBoxItem label="설치 상태">
            {/* 버튼 */}
            <Button
              size="middle"
              fill="outline"
              onClick={() => {
                actionSheetHandlerRef.current = ActionSheet.show({
                  actions: installStatusActionSheet.item,
                  onAction: (action: Action) => {
                    // 설치 상태 액션시트에 적용함
                    setInstallStatusActionSheet((pre: IActionSheet) => ({
                      ...pre,
                      value: action.key.toString(),
                    }));

                    // 액션시트를 닫음
                    actionSheetHandlerRef.current?.close();
                  },
                });
              }}
              className="w-full !border-indigo-200"
            >
              <span className="text-md text-indigo-500">
                {getActionSheetLabel(installStatusActionSheet)}
              </span>
            </Button>
          </PbFormBoxItem>
        </div>
      </PbFormBox>

      {/* 목록 폼 박스 */}
      <List className="!rounded-lg overflow-hidden">
        {listData.map((item: any, index: number) => (
          <List.Item key={index} clickable>
            <div
              onClick={() => handleListData_onClick(item)}
              className="button-event"
            >
              <div className="flex justify-between items-center">
                {/* 프로젝트명, 고객사명 */}
                <div className="space-x-2">
                  <span className="text-xs text-gray-600 font-bold">
                    {item.prjcName}
                  </span>

                  <span className="text-xs text-gray-300">|</span>

                  <span className="text-xs text-gray-600">{item.bpName}</span>
                </div>

                <div className="flex justify-center items-center space-x-1">
                  {/* 담당PM */}
                  <div className="truncate space-x-1">
                    <span className="text-xs text-gray-600">{item.pmName}</span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center">
                {/* 협력사명 */}
                <div className="">
                  <span className="text-xs text-indigo-600 font-bold">
                    {/*{item.totalSplr.replace(/,\s*$/, '').replaceAll(',', ' / ')}*/}
                  </span>
                </div>

                <div className="flex justify-center items-center space-x-1">
                  {/* ? */}
                  <div className="truncate space-x-1">
                    <span className="text-xs text-gray-600">
                      {/*{item.pmName}*/}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center space-x-2">
                {/* 설치상태 */}
                <div>
                  <span className="text-xs text-blue-500 font-bold">
                    {item.nstlSttsName}
                  </span>
                </div>

                {/* 작업일정 */}
                <div>
                  <span className="text-xs text-gray-500">
                    <Ellipsis
                      direction="end"
                      content={`${item.workStrtDttm} ~ ${item.workEndDttm}`}
                    />
                  </span>
                </div>

                {/*<div className="space-x-1">*/}
                {/*  <span className="text-xs text-gray-500">*/}
                {/*    {item.workStrtDttm}*/}
                {/*  </span>*/}
                {/*  <span className="text-xs text-gray-400">~</span>*/}
                {/*  <span className="text-xs text-gray-500">*/}
                {/*    {item.workEndDttm}*/}
                {/*  </span>*/}
                {/*</div>*/}
              </div>
            </div>
          </List.Item>
        ))}
      </List>

      {/* 더 불러오기 */}
      <div className="flex justify-center items-center">
        <div
          onClick={loadMoreListData}
          className="translateZ button-event px-10 py-2 space-y-1 animate-pulse"
        >
          <div className="flex justify-center items-center">
            <span className="text-xs text-gray-500">더 불러오기</span>
          </div>

          <div className="flex justify-center items-center">
            <FontAwesomeIcon
              icon={['fas', 'chevron-down']}
              className="w-3 h-3 text-gray-500"
            />
          </div>
        </div>
      </div>

      {/* 풀캘린더 */}
      {/*<PbFullCalendar*/}
      {/*  date={searchYm}*/}
      {/*  events={calendarEvent}*/}
      {/*  setEvents={setCalendarEvent}*/}
      {/*  dateClick={handleCalendar_onDateClick}*/}
      {/*  eventClick={handleCalendar_onEventClick}*/}
      {/*/>*/}

      {/*<InfiniteScroll*/}
      {/*  loadMore={loadMoreListData}*/}
      {/*  hasMore={hasMoreListData}*/}
      {/*  className="!h-1"*/}
      {/*>*/}
      {/*  <span className="text-xs text-gray-400 !h-1">*/}
      {/*    불러올 데이터가 더 이상 없습니다.*/}
      {/*  </span>*/}
      {/*</InfiniteScroll>*/}
    </PageLayout>
  );
};

export default OngoingJobManagementPage;
