import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';

/**
 * WORK: 작업
 */

/**
 * 작업 현황 목록 - Mobile
 * GET /api/work/stt/mbl
 */
export const getWorkSttMbl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/stt/mbl',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 현황 상세 조회(팀 포함 협력사 대상)
 * GET /api/work/stt/{dcmnDntfNmbr}/{cmpnCode}
 */
export const getSttDcmnDntfNmbrCmpnCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/work/stt', params.dcmnDntfNmbr, params.cmpnCode].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 현황 상세 조회 - 기본정보/네트워크/ESL/품목/검수
 * GET /api/work/stt/dtl/all/{dcmnDntfNmbr}
 */
export const getSttDtlAllDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/work/stt/dtl/all', params.dcmnDntfNmbr].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 댓글 첨부파일만 등록
 * POST /api/work/prgr/tch
 * @param params dcmnDntfNmbr 주문번호
 * @param params splrId 협력사 아이디
 * @param params teamId 팀 아이디
 * @param params fileList 첨부파일 목록
 * @param params dtl 첨부파일 설명
 * @param params bfFileIdList 이전 첨부파일 아이디 목록
 */
export const postPrgrTch = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/prgr/tch',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postPrgrTch Error:', error);
    });

  return result;
};

/**
 * 작업 내역/결과 수정(네트워크)
 * PUT /api/work/work-ntwr/{dcmnDntfNmbr}
 */
export const putWorkNtwrDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/work-ntwr', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putWorkNtwrDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * 작업 내역 - 재고이전 포함 작업완료(ESL)
 * PUT /api/work/work-esl/nvnt-rqst/{dcmnDntfNmbr}
 */
export const putWorkEslNvntRqstDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/work-esl/nvnt-rqst', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putWorkEslNvntRqstDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * PM 검수 취소
 * PUT /api/work/pm-nspc-cancel/{dcmnDntfNmbr}
 */
export const putPmNspcCancelDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/pm-nspc-cancel', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putPmNspcCancelDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * PM 검수 상세 등록/수정(PM 검수 완료)
 * POST /api/work/pm-nspc-rgst
 */
export const postPmNspcRgst = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/pm-nspc-rgst',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postPmNspcRgst Error:', error);
    });

  return result;
};

/**
 * 작업 현황 목록
 * GET /api/work/stt
 */
export const getWorkStt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/stt',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ESL 설치 확인서 엑셀 다운로드
 * GET /api/work/setup/report/excel
 */
export const getSetupReportExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/work/setup/report/excel',
    responseType: 'blob',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 납품 완료
 * POST /api/work/delivery/complete/{dcmnDntfNmbr}
 */
export const postDeliveryCompleteDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/work/delivery/complete', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postDeliveryCompleteDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * 납품 완료 취소
 * POST /api/work/delivery/cancel/{dcmnDntfNmbr}
 */
export const postDeliveryCancelDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/work/delivery/cancel', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postDeliveryCancelDcmnDntfNmbr Error:', error);
    });

  return result;
};
