import React, { PropsWithChildren } from 'react';
import Lottie from 'lottie-react';
import moreListAnimation from './lottie.json';

interface IComponentProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 목록 더 불러오기 버튼
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MoreList = ({ data, onClick }: PropsWithChildren<IComponentProps>) => {
  return (
    <div className="py-3 flex justify-center items-center">
      <div
        onClick={onClick}
        className="button-event flex justify-center items-center"
      >
        <div className="">
          <div className="px-3 py-1 flex justify-center items-center border border-gray-200 rounded-xl">
            <span className="text-xs text-gray-500 font-bold">더보기</span>
          </div>

          <div className="flex justify-center items-center">
            <div className="w-1 flex justify-center items-center">
              <Lottie animationData={moreListAnimation} loop={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreList;
