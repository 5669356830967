import React, { PropsWithChildren, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import {
  addRefreshListStore,
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';

interface IManagerProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 목록 새로고침 매니저
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const RefreshListManager = ({
  data,
  onClick,
}: PropsWithChildren<IManagerProps>) => {
  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 목록 새로고침이 추가됨
  useEffect(() => {
    if (!addRefreshList) {
      return;
    }

    // 목록 새로고침에 추가함
    setRefreshList((pre: string[]) => [...pre, addRefreshList]);

    // 임시 공간에서 삭제함
    setAddRefreshList('');
  }, [addRefreshList]);

  // 목록 새로고침이 삭제됨
  useEffect(() => {
    if (!removeRefreshList) {
      return;
    }

    // 삭제할 목록 새로고침의 순서를 불러옴
    let tmpIndex: number = _.indexOf(refreshList, removeRefreshList);

    // 삭제할 목록 새로고침을 전체 목록 새로고침에서 삭제함
    setRefreshList((pre: string[]) => [
      ...pre.slice(0, tmpIndex),
      ...pre.slice(tmpIndex + 1),
    ]);

    // 임시 공간에서 삭제함
    setRemoveRefreshList('');
  }, [removeRefreshList]);

  return <></>;
};

export default RefreshListManager;
