import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { IComponentModal, IPopup } from '../../interfaces/app.interface';
import {
  addPopupStore,
  popupStore,
  removeAllPopupStore,
  removePopupStore,
} from '../../stores/popup.store';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';

interface IManagerProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 팝업 매니저
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PopupManager = ({ data, onClick }: PropsWithChildren<IManagerProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 팝업 저장소를 정의함
  const [popup, setPopup] = useRecoilState<IPopup[]>(popupStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 삭제할 전체 팝업 저장소를 정의함
  const [removeAllPopup, setRemoveAllPopup] =
    useRecoilState<boolean>(removeAllPopupStore);

  // 팝업의 출력 여부를 정의함
  const [visiblePopup, setVisiblePopup] = useState<string[]>([]);

  // 팝업의 새 최상단 위치를 불러옴
  const getMaxZIndex = (): number => {
    // 최상단의 팝업을 불러옴
    let maxZIndex = _.maxBy(popup, 'zIndex') as IPopup;

    // 팝업이 하나도 없을 경우에는 기본값을 적용함
    if (maxZIndex === undefined) {
      return 200;
    }

    return maxZIndex.zIndex! + 1;
  };

  // 팝업을 닫음
  const handlePopup_onClose = (id: string) => {
    setRemovePopup(id);
  };

  // 화면을 스크롤함
  const handleScrollTo = (popupId: string, scrollType: string) => {
    let scrollArea = document.querySelector(
      `#scroll-area-${popupId}`,
    ) as HTMLDivElement;

    switch (scrollType) {
      case 'top':
        scrollArea?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        break;

      case 'bottom':
        scrollArea?.scrollTo({
          top: scrollArea?.scrollHeight,
          behavior: 'smooth',
        });
        break;

      default:
        break;
    }
  };

  // 팝업이 추가됨
  useEffect(() => {
    if (addPopup === null) {
      return;
    }

    // 팝업 아이디를 지정했을 경우, 중복되는 레이어라면 새 레이어로 추가하지 않음
    if (addPopup.id !== undefined || addPopup.id !== '') {
      let findLayer = _.find(popup, {
        id: addPopup.id,
      }) as IPopup;

      if (findLayer !== undefined) {
        return;
      }
    }

    // 팝업 기본값을 생성함
    let tmpPopup: IPopup = {
      id: addPopup.id || nanoid(),
      title: addPopup.title || '제목 없음',
      content: addPopup.content || <></>,
      widthSizePercent: addPopup.widthSizePercent || 100,
      heightSizePercent: addPopup.heightSizePercent || 60,
      position: addPopup.position || 'bottom',
      rounded: addPopup.rounded === undefined ? true : addPopup.rounded,
      maskClick: addPopup.maskClick || true,
      applyPadding:
        addPopup.applyPadding === undefined ? true : addPopup.applyPadding,
      zIndex: addPopup.zIndex || getMaxZIndex(),
    };

    // 팝업을 추가함
    setPopup((pre: IPopup[]) => [...pre, tmpPopup]);

    // 추가한 팝업을 임시 공간에서 삭제함
    setAddPopup(null);

    // 팝업의 출력 여부에 추가함
    setVisiblePopup((pre: string[]) => [...pre, tmpPopup.id!]);
  }, [addPopup]);

  // 팝업이 삭제됨
  useEffect(() => {
    if (removePopup === null) {
      return;
    }

    // 삭제할 팝업의 순서를 불러옴
    let removePopupIndex: number = _.findIndex(popup, {
      id: removePopup,
    });

    (async () => {
      // 팝업 출력 여부에서 삭제함
      setVisiblePopup((pre: string[]) => [
        ...pre.slice(0, removePopupIndex),
        ...pre.slice(removePopupIndex + 1),
      ]);

      await sleep(500);

      // 팝업을 삭제함
      setPopup((pre: IPopup[]) => [
        ...pre.slice(0, removePopupIndex),
        ...pre.slice(removePopupIndex + 1),
      ]);

      // 삭제한 팝업을 임시 공간에서 삭제함
      setRemovePopup(null);
    })();
  }, [removePopup]);

  // 전체 팝업이 삭제됨
  useEffect(() => {
    if (!removeAllPopup) {
      return;
    }

    // 팝업을 삭제함
    setPopup([]);

    // 삭제한 전체 팝업을 임시 공간에서 삭제함
    setRemoveAllPopup(false);
  }, [removeAllPopup]);

  return (
    <>
      {popup.map((item: IPopup, index: number) => (
        <Popup
          key={index}
          visible={_.indexOf(visiblePopup, item.id) > -1 ? true : false}
          onMaskClick={() => {
            if (item.maskClick === true) {
              // 팝업을 닫음
              handlePopup_onClose(item.id!);
            }
          }}
          bodyStyle={{
            borderTopLeftRadius:
              item.rounded === true
                ? item.position === 'right' || item.position === 'bottom'
                  ? '15px'
                  : ''
                : '',
            borderTopRightRadius:
              item.rounded === true
                ? item.position === 'bottom' || item.position === 'left'
                  ? '15px'
                  : ''
                : '',
            borderBottomLeftRadius:
              item.rounded === true
                ? item.position === 'top' || item.position === 'right'
                  ? '15px'
                  : ''
                : '',
            borderBottomRightRadius:
              item.rounded === true
                ? item.position === 'top' || item.position === 'left'
                  ? '15px'
                  : ''
                : '',
            width: `${item.widthSizePercent}%`,
            height: `${item.heightSizePercent}%`,
            // overflowY: 'scroll',
          }}
          position={item.position}
          style={{
            zIndex: item.zIndex,
          }}
        >
          <div
            id={`scroll-area-${item.id}`}
            className="relative w-full h-full rounded-t-2xl overflow-y-scroll"
          >
            {/* 팝업 제목 */}
            {item.title && (
              <div className="fixed -translate-y-px border-1 border-b border-white w-full bg-white rounded-t-2xl z-90">
                <div className="w-full border-b border-gray-100">
                  <div className="pl-4 pr-3 py-3 flex justify-between items-center space-x-3">
                    {/* 제목 */}
                    <span className="text-xl font-bold text-gray-800 z-20 truncate">
                      {item.title}
                    </span>

                    {/* 닫기 버튼 */}
                    <div
                      onClick={() => handlePopup_onClose(item.id!)}
                      className="button-event p-0.5 flex justify-center items-center rounded-md"
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'xmark']}
                        className="w-6 h-6 text-gray-400"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* 팝업 내용 */}
            {item.content &&
              (item.applyPadding ? (
                <div className="relative pt-16 pb-3 px-3">{item.content}</div>
              ) : (
                <div className="relative pt-14">{item.content}</div>
              ))}

            {/* 스크롤 버튼 */}
            <div
              id="scroll-button"
              className="fixed right-4 bottom-20 z-100 hidden"
            >
              <div className="space-y-1">
                {/* 최상단 */}
                <div
                  onClick={() => handleScrollTo(item.id!, 'top')}
                  className="button-event w-10 h-10 flex justify-center items-center bg-white/90 border border-gray-300/80 rounded-t-md"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-up']}
                    className="w-4 h-4 text-gray-600/60"
                  />
                </div>

                {/* 최하단 */}
                <div
                  onClick={() => handleScrollTo(item.id!, 'bottom')}
                  className="button-event w-10 h-10 flex justify-center items-center bg-white/90 border border-gray-300/80 rounded-b-md"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-down']}
                    className="w-4 h-4 text-gray-600/60"
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
      ))}
    </>
  );
};

export default PopupManager;
