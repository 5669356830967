import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ActionSheet, Button, Ellipsis, Input, Tabs, Toast } from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import {
  IActionSheet,
  IApiResult,
  ILeftMenu,
  ILoginUser,
  IPageContent,
  IPopup,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { pageContentStore } from '../stores/page.store';
import { addPopupStore } from '../stores/popup.store';
import { PageLayout } from '../layouts/PageLayout';
import { PbFormBox } from '../components/PbFormBox';
import { PbFormBoxItem } from '../components/PbFormBoxItem';
import { MoreList } from '../components/MoreList';
import { SelectProject } from '../popups/SelectProject';
import { PerformanceRegistrationDetail } from '../popups/PerformanceRegistrationDetail';
import * as appUtil from '../utils/app.util';
import * as workApi from '../apis/work.api';
import { decode } from 'html-entities';
import moment from 'moment';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import * as cmnApi from '../apis/cmn.api';
import * as ntcbApi from '../apis/ntcb.api';
import { NoticeDetail } from '../popups/NoticeDetail';

/**
 * 게시판 > FAQ
 * @constructor
 */
const FaqPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 선택한 페이지의 이름을 정의함
  const [selectedPageName, setSelectedPageName] = useState<string>('');

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 액션시트 참조를 정의함
  const actionSheetHandlerRef = useRef<ActionSheetShowHandler>();

  // 공지 유형 액션시트를 정의함
  const [noticeTypeActionSheet, setNoticeTypeActionSheet] =
    useState<IActionSheet>({
      value: '',
      item: [],
    });

  // 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 선택한 탭을 정의함
  const [selectedTab, setSelectedTab] = useState<string>('');

  // 선택한 하위 탭을 정의함
  const [selectedSubTab, setSelectedSubTab] = useState<string>('');

  // 목록 데이터를 정의함
  const [listData, setListData] = useState<any[]>([]);

  // 탭을 정의함
  const [tabItem, setTabItem] = useState<any[]>([]);

  // 하위 탭을 정의함
  const [subTabItem, setSubTabItem] = useState<any[]>([]);

  // 선택한 목록 페이지를 정의함
  const currentPageRef = useRef<number>(1);

  /**
   * 공통코드를 불러옴
   * @param code 공통코드
   * @param callback 콜백 함수
   */
  const getCommonCode = async (
    code: string = '',
    callback: Function = () => {},
  ) => {
    await cmnApi
      .getDetailCodesAll({
        grpCodeId: code,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          callback(data.data.list);
        }
      });
  };

  /**
   * FAQ 접수 유형 코드를 불러옴
   * @param depth 단계
   * @param code 코드
   * @param callback 콜백 함수
   */
  const getFaqCode = async (
    depth: number = 1,
    code: string = '',
    callback: Function = () => {},
  ) => {
    await ntcbApi
      .getFaqCsType({
        depth: depth,
        searchKeyword: code,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          callback(data.data.list);
        }
      });
  };

  // 목록을 불러옴
  const getListData = () => {
    ntcbApi
      .getFaqs({
        cstyGrpCode: selectedTab,
        cstyCode: selectedSubTab,
        searchKeyword: searchInput.trim(),
        currPageIdx: currentPageRef.current,
        pageSize: 30,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 더 이상 불러올 목록 데이터가 없으면 종료함
          if (currentPageRef.current > 1 && data.data.list.length === 0) {
            Toast.show({
              position: 'bottom',
              content: '마지막 페이지입니다.',
            });

            return;
          }

          // 목록 데이터에 적용함
          if (currentPageRef.current === 1) {
            // 첫 페이지면 목록 데이터를 그대로 적용함
            setListData(data.data.list);
          } else {
            // 첫 페이지가 아니면 목록 데이터를 추가함
            setListData((pre: any) => [...pre, ...data.data.list]);
          }
        }
      })
      .catch((error: any) => {});
  };

  // 검색어 입력을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event);
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 현재 페이지를 증가함
    currentPageRef.current = 1;

    // 목록을 불러옴
    getListData();
  };

  // 더보기 버튼을 클릭함
  const handleMoreListButton_onClick = () => {
    // 현재 페이지를 증가함
    currentPageRef.current += 1;

    // 목록을 불러옴
    getListData();
  };

  // 목록의 행을 클릭함
  const handleList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: event.ttl,
      content: <NoticeDetail id={event.brdId} />,
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('page', '', false);

    // FAQ 유형을 불러와서 탭을 정의함
    getFaqCode(1, '', (data: any[]) => {
      let tmpOptionItem: any[] = [];

      // 기본값을 적용함
      tmpOptionItem.push({ title: '전체', key: '' });

      _.sortBy(data, ['sortRdr']).map((item: any) => {
        tmpOptionItem.push({
          title: item.dtlCodeDscr,
          key: item.dtlCodeId,
        });
      });

      setTabItem(tmpOptionItem);
    });

    return () => {};
  }, []);

  useEffect(() => {
    // 하위 FAQ 유형을 불러와서 탭을 정의함
    getFaqCode(2, selectedTab, (data: any[]) => {
      let tmpOptionItem: any[] = [];

      // 기본값을 적용함
      tmpOptionItem.push({ title: '전체', key: '' });

      if (selectedTab !== '') {
        _.sortBy(data, ['sortRdr']).map((item: any) => {
          tmpOptionItem.push({
            title: item.dtlCodeDscr,
            key: item.dtlCodeId,
          });
        });
      }

      setSubTabItem(tmpOptionItem);
    });

    return () => {};
  }, [selectedTab]);

  // 페이지의 제목이 변경될 때 실행함
  useEffect(() => {
    if (selectedPageName === '') {
      return;
    }

    return () => {};
  }, [selectedPageName]);

  // 선택한 페이지가 생성됐을 때 실행함
  useEffect(() => {
    if (Object.keys(selectedPage).length === 0) {
      return;
    }

    let tmpSelectedPageName: string = '';

    // 내비게이션에 1딘계 페이지를 추가함
    if (selectedPage.oneMenuId) {
      tmpSelectedPageName = selectedPage.oneKrnMenu;
    }

    // 내비게이션에 2딘계 페이지를 추가함
    if (selectedPage.twoMenuId) {
      tmpSelectedPageName = selectedPage.twoKrnMenu;
    }

    // 내비게이션에 3딘계 페이지를 추가함
    if (selectedPage.threeMenuId) {
      tmpSelectedPageName = selectedPage.threeKrnMenu;
    }

    // 선택한 페이지의 이름에 적용함
    setSelectedPageName(tmpSelectedPageName);

    return () => {};
  }, [selectedPage]);

  // 검색 항목이 변경될 때 실행함
  useEffect(() => {
    if (loginUser.id === '') {
      return;
    }

    // 공지 유형을 불러옴
    getCommonCode('NTC_TYPE', (data: any[]) => {
      let tmpOptionItem: any[] = [];

      // 기본값을 적용함
      tmpOptionItem.push({ text: '전체', key: '' });

      _.sortBy(data, ['sortRdr']).map((item: any) => {
        tmpOptionItem.push({
          text: item.dtlCodeDscr,
          key: item.dtlCodeId,
        });
      });

      // 공지 유형 액션시트에 적용함
      setNoticeTypeActionSheet((pre: IActionSheet) => ({
        ...pre,
        item: tmpOptionItem,
      }));
    });

    // 현재 페이지를 초기화함
    currentPageRef.current = 1;

    // 목록을 불러옴
    getListData();

    return () => {};
  }, [loginUser.id, noticeTypeActionSheet.value]);

  // 목록 데이터, 탭이 변경될 때 실행함
  useEffect(() => {
    // 현재 페이지를 초기화함
    currentPageRef.current = 1;

    setSelectedSubTab('');

    // 목록을 불러옴
    getListData();

    return () => {};
  }, [selectedTab]);

  return (
    <PageLayout
      pageInfoBarLeftArea={selectedPageName}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
    >
      <PbFormBox label="검색">
        <div className="space-y-3">
          {/* 항목 */}
          {/*<PbFormBoxItem label="공지 유형">*/}
          {/*  /!* 버튼 *!/*/}
          {/*  <Button*/}
          {/*    size="middle"*/}
          {/*    fill="outline"*/}
          {/*    onClick={handleNoticeTypeButton_onClick}*/}
          {/*    className="w-full !h-11 !border-rose-500"*/}
          {/*  >*/}
          {/*    <span className="text-md text-gray-700 font-semibold">*/}
          {/*      <Ellipsis*/}
          {/*        direction="end"*/}
          {/*        content={appUtil.getActionSheetLabel(noticeTypeActionSheet)}*/}
          {/*      />*/}
          {/*    </span>*/}
          {/*  </Button>*/}
          {/*</PbFormBoxItem>*/}

          {/* 항목 */}
          <PbFormBoxItem label="검색어">
            <div className="flex justify-center items-center space-x-2">
              {/* 텍스트 입력 */}
              <Input
                placeholder="검색어를 입력하세요."
                clearable={true}
                value={searchInput}
                onChange={handleSearchInput_onChange}
                onEnterPress={handleSearchButton_onClick}
                className="grow ant-m-input"
              />

              <Button
                fill="none"
                onClick={handleSearchButton_onClick}
                className="ant-m-button w-24 !bg-blue-700"
              >
                <span className="text-base text-white">검색</span>
              </Button>
            </div>
          </PbFormBoxItem>
        </div>
      </PbFormBox>

      {/* 목록 */}
      {/* 탭 */}
      <Tabs
        onChange={setSelectedTab}
        activeKey={selectedTab}
        className="ant-m-tabs ant-m-tabs-page-sticky"
      >
        {tabItem.map((tabItem: any) => (
          <Tabs.Tab key={tabItem.key} title={tabItem.title}>
            {/* 하위 탭 */}
            <Tabs
              onChange={setSelectedSubTab}
              activeKey={selectedSubTab}
              className="ant-m-tabs ant-m-tabs-page-sticky"
            >
              {subTabItem.map((subTabItem: any) => (
                <Tabs.Tab key={subTabItem.key} title={subTabItem.title}>
                  <div className="space-y-1">
                    {listData.map((listItem: any, listIndex: number) => (
                      <div
                        key={listIndex}
                        onClick={() => handleList_onClick(listItem)}
                        className="button-bg-event py-2 flex space-x-3"
                      >
                        {/* 번호 */}
                        <div className="flex-none w-7 flex justify-center items-center">
                          <span className="text-sm text-gray-700">
                            {listItem.no}
                          </span>
                        </div>

                        {/* 공지 유형 */}
                        <div className="flex-none flex justify-center items-center">
                          <div className="flex-none w-12 h-12 flex justify-center items-center bg-blue-500 rounded-full overflow-hidden">
                            <span className="text-xs text-white font-bold">
                              {listItem.ntcTypeName}
                            </span>
                          </div>
                        </div>

                        <div className="grow flex justify-start items-center">
                          <div className="space-y-0.5">
                            {/* 첫번째 행 */}
                            <div className="flex justify-start items-center space-x-2">
                              {/* 작성일자 */}
                              <span className="text-xs text-gray-700 font-normal">
                                {listItem.rgstDate}
                              </span>

                              <div className="flex justify-center items-center">
                                <FontAwesomeIcon
                                  icon={['fas', 'circle']}
                                  className="w-0.5 h-0.5 text-gray-300"
                                />
                              </div>

                              {/* 조회수 */}
                              <span className="text-xs text-gray-700 font-semibold">
                                <Ellipsis
                                  direction="end"
                                  content={listItem.vws}
                                />
                              </span>
                            </div>

                            {/* 두번째 행 */}
                            <div className="flex justify-start items-center space-x-2">
                              {/* 제목 */}
                              <span className="text-base text-gray-900 font-medium">
                                <Ellipsis
                                  direction="end"
                                  content={decode(listItem.ttl || '')}
                                />
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="flex-none w-5 flex justify-center items-center">
                          {/* 아이콘 */}
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="w-3 h-3 text-gray-500"
                            />
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* 더 불러오기 */}
                    <MoreList onClick={handleMoreListButton_onClick} />
                  </div>
                </Tabs.Tab>
              ))}
            </Tabs>
          </Tabs.Tab>
        ))}
      </Tabs>
    </PageLayout>
  );
};

export default FaqPage;
