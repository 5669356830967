import React, { PropsWithChildren } from 'react';
import { Button, Ellipsis, SwipeAction } from 'antd-mobile';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFileDownloadList } from '../../interfaces/app.interface';
import * as appUtil from '../../utils/app.util';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FileButton } from '@mantine/core';
import prettyBytes from 'pretty-bytes';
import * as cmnApi from '../../apis/cmn.api';

interface IComponentProps {
  data?: IFileDownloadList[];
  onClick?: () => void;
  visibleAddFileButton?: boolean;
  onClickAddFileButton?: (payload: File[]) => void;
  onClickRemoveFileButton?: (event: any) => void;
  visibleSwipeRightAction?: boolean;
  onClickFileDownloadButton?: (event: any) => void;
}

/**
 * 파일 다운로드 목록
 * @param data 파일 목록
 * @param visibleAddFileButton 파일 추가 버튼 표시 여부
 * @param onClickAddFileButton 파일 추가 버튼 클릭 이벤트
 * @param onClickRemoveFileButton 파일 삭제 버튼 클릭 이벤트
 * @param visibleSwipeRightAction 오른쪽 스위프트 액션 표시 여부
 * @constructor
 */
const FileDownloadList = ({
  data,
  onClick,
  visibleAddFileButton = false,
  onClickAddFileButton = (payload: File[]) => {},
  onClickRemoveFileButton = (event: any) => {},
  visibleSwipeRightAction = false,
  onClickFileDownloadButton = (event: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 파일을 다운로드함
  const handleFileDownload_onClick = (event: any) => {
    cmnApi
      .getFileDownloadFileId({
        fileId: event.id,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', event.originalFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className="space-y-2">
      {/* 파일 추가 버튼 */}
      {visibleAddFileButton && (
        <FileButton onChange={onClickAddFileButton} multiple={true}>
          {(props) => (
            <div
              {...props}
              className="button-event w-full py-2 bg-sky-100/50 border border-sky-200 border-dashed space-y-1 rounded"
            >
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fas', 'plus']}
                  className="w-5 h-5 text-sky-500"
                />
              </div>

              <div className="flex justify-center items-center">
                <span className="text-base text-sky-700">파일 추가</span>
              </div>
            </div>
          )}
        </FileButton>
      )}

      {/* 첨부파일 목록 */}
      {data?.map((item: IFileDownloadList, index: number) => (
        <SwipeAction
          key={index}
          rightActions={
            visibleSwipeRightAction
              ? [
                  {
                    key: 'remove',
                    text: (
                      // 삭제 버튼
                      <div className="flex justify-center items-center space-x-0.5">
                        <div className="flex justify-center items-center">
                          <FontAwesomeIcon
                            icon={['fas', 'xmark']}
                            className="w-4 h-4 text-white"
                          />
                        </div>

                        <div className="flex justify-center items-center">
                          <span className="text-base text-white">삭제</span>
                        </div>
                      </div>
                    ),
                    color: 'rgb(225 29 72)',
                    onClick: (event: any) => {
                      onClickRemoveFileButton(item);
                    },
                  },
                ]
              : []
          }
          className="ant-m-swipe-action"
        >
          <div
            // onClick={() => onClickFileDownloadButton(item)}
            onClick={() => handleFileDownload_onClick(item)}
            className="button-bg-opacity-event w-full h-10 px-3 flex justify-between items-center bg-blue-200 rounded space-x-5"
          >
            <div className="flex justify-center items-center space-x-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={
                    appUtil.getFileNameExtension(item.originalFileName)
                      .icon as IconProp
                  }
                  className="w-3 h-3 text-blue-600"
                />
              </div>

              {/* 파일명 */}
              <span className="text-sm text-gray-700 font-medium">
                <Ellipsis direction="middle" content={item.originalFileName} />
              </span>
            </div>

            <div className="flex justify-center items-center">
              {/* 파일 용량 */}
              <span className="text-xs text-gray-700 font-medium">
                {prettyBytes(_.toNumber(item.size || 0))}
              </span>
            </div>
          </div>
        </SwipeAction>
      ))}
    </div>
  );
};

export default FileDownloadList;
