import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ActionSheet, Button, Input, List, Toast } from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import {
  IActionSheet,
  IApiResult,
  IPopup,
} from '../../interfaces/app.interface';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { addPopupStore, removePopupStore } from '../../stores/popup.store';
import * as cmnApi from '../../apis/cmn.api';
import { nanoid } from 'nanoid';
import { ModifyProduct } from '../ModifyProduct';
import * as nstlApi from '../../apis/nstl.api';

interface IPopupProps {
  id: string;
  dcmnDntfNmbr: string;
  splrId: string;
  teamId: string;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 재고 이동 요청
 * @param onClick <이벤트>
 * @constructor
 */
const MoveProduct = ({
  id,
  dcmnDntfNmbr,
  splrId = '',
  teamId = '',
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 액션시트 참조를 정의함
  const actionSheetHandlerRef = useRef<ActionSheetShowHandler>();

  // 재고 유형 액션시트를 정의함
  const [typeActionSheet, setTypeActionSheet] = useState<IActionSheet>({
    value: '',
    item: [],
  });

  // 품목 버튼을 정의함
  const [productButton, setProductButton] = useState<{
    label: string;
    value: string;
  }>({
    label: '',
    value: '',
  });

  // 요청 상태 액션시트를 정의함
  const [requestStatusActionSheet, setRequestStatusActionSheet] =
    useState<IActionSheet>({
      value: '',
      item: [],
    });

  // 수량 입력을 정의함
  const [amountInput, setAmountInput] = useState<string>('');
  const amountInputRef = useRef<any>(null);

  // 메모 입력을 정의함
  const [memoInput, setMemoInput] = useState<string>('');

  // 재고 유형 액션시트를 초기화함
  const initTypeActionSheet = () => {
    let tmpOptionItem: Action[] = [];

    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'STCK_TYPE',
      })
      .then((data: IApiResult) => {
        data.data.list.map((item: any, index: number) => {
          tmpOptionItem.push({
            text: item.dtlCodeDscr,
            key: item.dtlCodeId,
          });
        });

        // 구분 액션시트에 적용함
        setTypeActionSheet((pre: IActionSheet) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 요청 상태 액션시트를 초기화함
  const initRequestStatusActionSheet = () => {
    let tmpOptionItem: Action[] = [];

    tmpOptionItem.push(
      {
        text: '일반',
        key: 'CMN',
      },
      {
        text: '불량',
        key: 'EROR',
      },
    );

    // 요청 상태 액션시트에 적용함
    setRequestStatusActionSheet((pre: IActionSheet) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  // 액션시트에서 선택한 아이템의 레이블을 불러옴
  const getActionSheetLabel = (actionSheet: IActionSheet) => {
    let tmpOptionItem: Action | undefined | null = _.find(actionSheet.item, {
      key: actionSheet.value,
    });

    return tmpOptionItem?.text || '';
  };

  // 품목 선택 버튼을 클릭함
  const handleSelectProductButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '품목 선택',
      content: (
        <ModifyProduct
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          data={null}
          callback={(result: any) => {
            // 품목 버튼에 적용함
            setProductButton({
              label: result.itemDtls,
              value: result.itemNmbr,
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 60,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 수량 입력을 변경함
  const handleAmountInput_onChange = (event: any) => {
    setAmountInput(event);
  };

  // 메모 입력을 변경함
  const handleMemoInput_onChange = (event: any) => {
    setMemoInput(event);
  };

  // 처리 요청 버튼을 클릭함
  const handleRequestButton_onClick = () => {
    if (typeActionSheet.value === '') {
      Toast.show({
        position: 'bottom',
        content: '재고 유형을 선택하세요.',
      });

      return;
    }

    if (requestStatusActionSheet.value === '') {
      Toast.show({
        position: 'bottom',
        content: '요청 상태를 선택하세요.',
      });

      return;
    }

    if (productButton.value === '') {
      Toast.show({
        position: 'bottom',
        content: '품목을 선택하세요.',
      });

      return;
    }

    if (amountInput.trim() === '') {
      Toast.show({
        position: 'bottom',
        content: '수량을 입력하세요.',
      });

      amountInputRef.current.focus();
      return;
    }

    nstlApi
      .postNstlNvntRgst({
        dcmnDntfNmbr: dcmnDntfNmbr,
        dueDate: '',
        list: [
          {
            stckTypeCode: typeActionSheet.value,
            stckSttsCode: requestStatusActionSheet.value,
            itemCode: productButton.value,
            quantity: amountInput,
            ttl: memoInput,
          },
        ],
        splrId: splrId,
        teamId: teamId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          Toast.show({
            position: 'bottom',
            content: '처리하였습니다.',
          });

          // 부모창으로 결과를 넘김
          callback(null);

          // 삭제할 팝업 저장소에 적용함
          setRemovePopup(id);
        } else {
          Toast.show({
            position: 'bottom',
            content: '요청에 실패하였습니다.',
          });
        }
      })
      .catch((error: any) => {
        Toast.show({
          position: 'bottom',
          content: '요청에 실패하였습니다.',
        });
      });
  };

  useEffect(() => {
    // 재고 유형 액션시트를 초기화함
    initTypeActionSheet();

    // 요청 상태 액션시트를 초기화함
    initRequestStatusActionSheet();

    return () => {};
  }, []);

  return (
    <div className="">
      {/* 편집 리스트 */}
      <List header="수량 정보" className="bg-gray-100/90">
        <List.Item title="재고 유형">
          {/* 버튼 */}
          <Button
            size="middle"
            fill="outline"
            onClick={() => {
              actionSheetHandlerRef.current = ActionSheet.show({
                actions: typeActionSheet.item,
                onAction: (action: Action) => {
                  // 재고 유형 액션시트에 적용함
                  setTypeActionSheet((pre: IActionSheet) => ({
                    ...pre,
                    value: action.key.toString(),
                  }));

                  // 액션시트를 닫음
                  actionSheetHandlerRef.current?.close();
                },
              });
            }}
            className="w-full !border-indigo-200"
          >
            <span className="text-md text-indigo-500">
              {getActionSheetLabel(typeActionSheet) || '선택하세요'}
            </span>
          </Button>
        </List.Item>
        <List.Item title="요청 상태">
          {/* 버튼 */}
          <Button
            size="middle"
            fill="outline"
            onClick={() => {
              actionSheetHandlerRef.current = ActionSheet.show({
                actions: requestStatusActionSheet.item,
                onAction: (action: Action) => {
                  // 요청 상태 액션시트에 적용함
                  setRequestStatusActionSheet((pre: IActionSheet) => ({
                    ...pre,
                    value: action.key.toString(),
                  }));

                  // 액션시트를 닫음
                  actionSheetHandlerRef.current?.close();
                },
              });
            }}
            className="w-full !border-indigo-200"
          >
            <span className="text-md text-indigo-500">
              {getActionSheetLabel(requestStatusActionSheet) || '선택하세요'}
            </span>
          </Button>
        </List.Item>
        <List.Item title="품목명">
          {/* 버튼 */}
          <Button
            size="middle"
            fill="outline"
            onClick={handleSelectProductButton_onClick}
            className="w-full !border-indigo-200"
          >
            <span className="text-md text-indigo-500">
              {productButton.label || '선택하세요'}
            </span>

            {productButton.value && (
              <div className="pt-1 leading-none">
                <span className="text-md text-indigo-500">
                  ({productButton.value})
                </span>
              </div>
            )}
          </Button>
        </List.Item>
        <List.Item title="수량">
          {/* 텍스트 입력 */}
          <Input
            ref={amountInputRef}
            placeholder="수량을 입력하세요."
            clearable={true}
            value={amountInput}
            onChange={handleAmountInput_onChange}
          />
        </List.Item>
        <List.Item title="메모">
          {/* 텍스트 입력 */}
          <Input
            placeholder="메모를 입력하세요."
            clearable={true}
            value={memoInput}
            onChange={handleMemoInput_onChange}
          />
        </List.Item>
      </List>

      <div className="p-3 space-y-2">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleRequestButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">처리 요청</span>
        </Button>
      </div>
    </div>
  );
};

export default MoveProduct;
