import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface ILayoutProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 하단바
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const BottomBar = ({ data, onClick }: PropsWithChildren<ILayoutProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 링크를 클릭함
  const handleLink_onClick = (link: string) => {
    // 해당 페이지로 이동함
    navigate(link);
  };

  return (
    <>
      {/* 저작권 */}
      <div className="w-full pt-7 pb-7 flex justify-center items-center">
        <span className="text-xs text-gray-500 font-semibold">
          Copyright ⓒ 2023 Dtonic all rights reserved.
        </span>
      </div>
    </>
  );
};

export default BottomBar;
