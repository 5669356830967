import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import * as cmnApi from '../../apis/cmn.api';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { IApiResult, IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { getFindPassword } from '../../apis/cmn.api';
import { Button, Checkbox, Dialog, Form, Input } from 'antd-mobile';
import * as appUtil from '../../utils/app.util';

interface IFindPasswordProps {
  id?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 로그인 > 비밀번호 찾기
 * @param id 화면 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const FindPassword = ({
  id = '',
  data,
  onClick,
}: PropsWithChildren<IFindPasswordProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 아이디 입력을 정의함
  const [userId, setUserId] = useState<string>('');

  // 이메일주소 입력을 정의함
  const [email, setEmail] = useState<string>('');

  // 아이디 입력의 값을 변경함
  const handleUserIdInput_onChange = (event: any) => {
    setUserId(event.target.value);
  };

  // 이메일주소 입력의 값을 변경함
  const handleEmailInput_onChange = (event: any) => {
    setEmail(event);
  };

  // 이메일주소로 확인 메일 발송 버튼을 클릭함
  const handleSendEmailButton_onClick = () => {
    // 처리 순서
    // 1. DB에서 아이디와 이메일주소가 일치하는 사용자를 조회함(이미 API에서 구현되어 있을 수 있음)
    // 2. 사용자에게 확인 메일을 보냄
    // 3. 웹브라우저에서 사용자의 메일함(개인이 사용하는 메일 서비스 사이트)으로 접속하여 메일이 왔는지 확인함
    // 4. 메일이 왔으면 어떠한 링크 주소가 포함되어 있을 것임
    // 5. 링크 주소를 클릭하면 비밀번호 변경 페이지로 이동함(컴포넌트를 새로 만들어야 함)
    // 6. 비밀번호 변경 컴포넌트가 출력되면, 사용자가 자신의 새 비밀번호를 입력하고 확인 버튼을 클릭함
    // 7. 새 비밀번호가 DB에 저장됨
    //
    // 참고
    // 1. 사용자 로그인 비밀번호는 기본값 1234로 되어 있음
    // 2. 로그인 할 때마다 사용자가 초기 비밀번호를 변경했는지를 확인함(변경했는지를 확인하는 컬럼이 있음)
    // 3. 비밀번호 변경 컴포넌트에서 비밀번호를 변경하면 해당 확인 컬럼의 값을 변경함

    // if(!userId){
    //   setModal({
    //     title: '알림',
    //     content: '사용자 아이디를 입력하세요.',
    //   });

    //   return;
    // }

    if (!email) {
      Dialog.show({
        content: '이메일주소를 입력하세요.',
        closeOnMaskClick: true,
        closeOnAction: true,
        actions: [
          {
            key: 'okay',
            text: '확인',
          },
        ],
      });

      return;
    }

    // 사용자 정보 가져오기 - 아이디 / Email 비교
    // getCmnUserInfo();

    // 입력된 비밀번호로 임시 비밀번호 발송
    initUserPassword();
  };

  /**
   * 사용자 아이디, 이메일 확인
   */
  // 권한 정보 상세 데이터를 정의함
  const [cmnUserInfo, setCmnUserInfo] = useState<any>(null);

  // 권한 정보 상세 데이터를 불러옴
  const getCmnUserInfo = () => {
    cmnApi
      .getUser({
        userId: userId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 정보 상세 데이터에 적용함
          setCmnUserInfo(data.data);
        }
      });
  };
  /* // 사용자 아이디, 이메일 확인 */

  /**
   * 입력된 Email 로 임시 비밀번호 발송
   */
  const initUserPassword = () => {
    if (!email) {
      Dialog.show({
        content: '이메일주소를 입력하세요.',
        closeOnMaskClick: true,
        closeOnAction: true,
        actions: [
          {
            key: 'okay',
            text: '확인',
          },
        ],
      });
    }
    cmnApi
      .getFindPassword({
        lgnId: email,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          case '200':
            Dialog.show({
              content: '메일을 발송하였습니다.',
              closeOnMaskClick: true,
              closeOnAction: true,
              actions: [
                {
                  key: 'okay',
                  text: '확인',
                },
              ],
            });
            break;

          case '500':
            console.log('> 메일 발송에 Error:', data);

            Dialog.show({
              content: '메일 발송에 실패하였습니다.',
              closeOnMaskClick: true,
              closeOnAction: true,
              actions: [
                {
                  key: 'okay',
                  text: '확인',
                },
              ],
            });
            break;

          default:
            break;
        }
      })
      .catch((error: any) => {
        console.log('> 권한 정보 수정 Error:', error);

        Dialog.show({
          content: '메일 발송에 실패하였습니다.',
          closeOnMaskClick: true,
          closeOnAction: true,
          actions: [
            {
              key: 'okay',
              text: '확인',
            },
          ],
        });
      });
  };

  /* // 입력된 Email 로 임시 비밀번호 발송 */

  // 사용자 아이디, Email 확인
  // useEffect(() => {

  //   if (!cmnUserInfo) {
  //     return;
  //   }

  //   if (email === cmnUserInfo.ml) {
  //     initUserPassword();
  //   }
  //   return () => {};
  // }, [cmnUserInfo]);

  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('popup', id, false);

    return () => {};
  }, []);

  return (
    <div className="space-y-1">
      <div className="space-y-5">
        <Form.Item label="이메일주소">
          <div className="flex justify-center items-center space-x-2">
            {/* 텍스트 입력 */}
            <Input
              placeholder="이메일주소를 입력하세요."
              onChange={handleEmailInput_onChange}
              value={email}
              clearable={true}
              className="ant-m-input"
            />
          </div>
        </Form.Item>

        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleSendEmailButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">메일로 임시 비밀번호 받기</span>
        </Button>

        {/* 참고 */}
        <div>
          <div className="flex justify-center items-center">
            <span className="text-sm text-gray-500 font-semibold">
              귀하의 로그인 비밀번호를 초기화한 후,
            </span>
          </div>
          <div className="flex justify-center items-center">
            <span className="text-sm text-gray-500 font-semibold">
              이메일주소로 임시 비밀번호를 발송합니다.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPassword;
