import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ActionSheet, Button, Ellipsis, ImageViewer, List } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/action-sheet';
import {
  IActionSheet,
  IApiResult,
  IPopup,
} from '../../interfaces/app.interface';
import * as appUtil from '../../utils/app.util';
import { nanoid } from 'nanoid';
import { ModifySoftware } from '../ModifySoftware';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { addPopupStore } from '../../stores/popup.store';
import { ModifyComment } from '../ModifyComment';
import * as nstlApi from '../../apis/nstl.api';
import prettyBytes from 'pretty-bytes';
import * as slsApi from '../../apis/sls.api';

interface IPopupProps {
  id: string;
  dcmnDntfNmbr: string;
  splrId: string;
  splrTypeCode: string;
  teamId: string;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 댓글
 * @param dcmnDntfNmbr 주문번호
 * @param teamId 협력사(팀) 아이디
 * @constructor
 */
const Comment = ({
  id,
  dcmnDntfNmbr = '',
  splrId = '',
  splrTypeCode = '',
  teamId = '',
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 댓글을 정의함
  const [comment, setComment] = useState<any[]>([]);

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    switch (splrTypeCode) {
      // 네트워크 공사 작업 내역
      case 'NTWR_CNST':
        nstlApi
          .getNstlWorkNtwr({
            splrId: splrId,
            teamId: teamId,
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            if (data.data !== undefined) {
              callback(data.data);
            }
          });
        break;

      // ESL 설치 작업 내역
      case 'ESL_NSTL':
        nstlApi
          .getNstlWorkEsl({
            splrId: splrId,
            teamId: teamId,
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            if (data.data !== undefined) {
              callback(data.data);
            }
          });
        break;

      default:
        break;
    }
  };

  // 댓글 작성 버튼을 클릭함
  const handleAddButton_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '댓글 작성',
      content: (
        <ModifyComment
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={splrId}
          teamId={teamId}
          commentData={null}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 댓글에 적용함
              setComment(data.prgrRsltList);
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 60,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 댓글 수정 버튼을 클릭함
  const handleModifyButton_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '댓글 수정',
      content: (
        <ModifyComment
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={splrId}
          teamId={teamId}
          commentData={event}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 댓글에 적용함
              setComment(data.prgrRsltList);
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 60,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    slsApi
      .getSlsCtvtFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 댓글에 적용함
      setComment(data.prgrRsltList);

      console.log('> data.prgrRsltList:', data.prgrRsltList);
    });

    return () => {};
  }, []);

  return (
    <div className="">
      <div className="p-3 space-y-2">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleAddButton_onClick}
          className="w-full !bg-black"
        >
          <span className="text-md text-white">댓글 작성</span>
        </Button>
      </div>

      {/* 댓글 리스트 */}
      <List header="댓글" className="bg-gray-100/90">
        {comment.map((item: any, index: number) => (
          <List.Item
            title={
              <div className="flex justify-between items-center">
                <div className="flex justify-center items-center space-x-2">
                  {/* 설치 카테고리 */}
                  {item.nstlCtgrName !== '' && (
                    <span className="text-xs text-lime-600 font-bold">
                      {item.nstlCtgrName}
                    </span>
                  )}

                  {/* 작성자 */}
                  <span className="text-xs text-blue-600 font-bold">
                    {item.name}
                  </span>
                </div>

                <div className="flex justify-center items-center">
                  {/* 작성일시 */}
                  <span className="text-xs text-gray-400">{item.rgstDate}</span>
                </div>
              </div>
            }
            description={
              <div className="pt-2 flex justify-end items-center">
                {/* 댓글수정 */}
                <div
                  onClick={() => handleModifyButton_onClick(item)}
                  className="button-event px-2 py-1 flex justify-center items-center bg-indigo-500 rounded"
                >
                  <span className="text-sm text-white">수정</span>
                </div>
              </div>
            }
          >
            <div className="space-y-2">
              {/* 댓글 내용 */}
              <div className="pt-1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.dtl.replaceAll('\r\n', '<br />'),
                  }}
                />
              </div>

              <div className="space-y-3">
                {/* 첨부파일: 이미지 */}
                {item.fileList.filter(
                  (filterItem: any) => filterItem.fileDntfCode === 'PCTR',
                ).length > 0 && (
                  <div className="flex flex-wrap gap-1">
                    {item.fileList
                      .filter(
                        (filterItem: any) => filterItem.fileDntfCode === 'PCTR',
                      )
                      .map((file: any, index: number) => (
                        <div className="border rounded-md overflow-hidden">
                          <img
                            key={index}
                            src={[
                              appUtil.getThumbnailImageUrl(),
                              file.pldFileName,
                            ].join('')}
                            onClick={() => {
                              let tmpFileList: any[] = [];

                              item.fileList
                                .filter(
                                  (subFile: any) =>
                                    subFile.fileDntfCode === 'PCTR',
                                )
                                .map((subFile: any) => {
                                  tmpFileList.push(
                                    [
                                      appUtil.getThumbnailImageUrl(),
                                      subFile.pldFileName,
                                    ].join(''),
                                  );
                                });

                              ImageViewer.Multi.show({
                                images: tmpFileList,
                                defaultIndex: index,
                                renderFooter: (
                                  image: string,
                                  index: number,
                                ) => (
                                  <div className="px-3 pb-4">
                                    {/* 파일 설명 */}
                                    <span className="text-xs text-white font-semibold">
                                      {file.fileDscr}
                                    </span>
                                  </div>
                                ),
                              });
                            }}
                            className="w-16 h-16"
                          />
                        </div>
                      ))}
                  </div>
                )}

                {/* 첨부파일: 기타 */}
                {item.fileList.filter(
                  (filterItem: any) =>
                    filterItem.fileDntfCode !== 'PCTR' ||
                    filterItem.fileDntfCode === undefined,
                ).length > 0 && (
                  <div className="space-y-1">
                    {item.fileList
                      .filter(
                        (filterItem: any) =>
                          filterItem.fileDntfCode !== 'PCTR' ||
                          filterItem.fileDntfCode === undefined,
                      )
                      .map((file: any, index: number) => (
                        <div
                          onClick={() =>
                            handleFileDownload_onClick(
                              file.fileId,
                              file.rgnlFileName,
                            )
                          }
                          className="button-event px-3 py-2 bg-gray-100 rounded"
                        >
                          {/* 파일명 */}
                          <div className="leading-none">
                            <span className="text-sm text-gray-600 font-semibold">
                              <Ellipsis
                                direction="middle"
                                content={file.rgnlFileName}
                              />
                            </span>
                          </div>

                          {/* 파일 설명, 용량 */}
                          <div className="leading-none">
                            <span className="text-xs text-gray-400">
                              <Ellipsis
                                direction="middle"
                                content={prettyBytes(_.toNumber(file.fileSize))}
                              />
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default Comment;
