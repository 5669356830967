import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Button, Ellipsis, Input, List } from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { IActionSheet } from '../../interfaces/app.interface';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { removePopupStore } from '../../stores/popup.store';
import prettyBytes from 'pretty-bytes';

interface IPopupProps {
  id: string;
  file?: any;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 첨부파일 수정
 * @param data 구분, 내용(신규일 때는 null)
 * @param onClick <이벤트>
 * @constructor
 */
const ModifyFile = ({
  id,
  file,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 설명 입력을 정의함
  const [descriptionInput, setDescriptionInput] = useState<string>('');

  // 항목의 값이 없을 때, 빈 값을 반환함
  const displayValue = (value: any): any => {
    return value ? value : <span className="text-gray-300">정보 없음</span>;
  };

  // 설명 입력을 변경함
  const handleDescriptionInput_onChange = (event: any) => {
    setDescriptionInput(event);
  };

  // 적용 버튼을 클릭함
  const handleApplyButton_onClick = () => {
    // 부모창으로 결과를 넘김
    callback({
      preData: file,
      resultType: '',
      fileDscr: descriptionInput.trim(),
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  // 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    // 부모창으로 결과를 넘김
    callback({
      preData: file,
      resultType: 'remove',
      fileDscr: '',
    });

    // 삭제할 팝업 저장소에 적용함
    setRemovePopup(id);
  };

  useEffect(() => {
    // 설명 입력에 적용함
    setDescriptionInput(file.fileDscr);

    return () => {};
  }, []);

  return (
    <div className="">
      {/* 편집 리스트 */}
      <List header="첨부파일 정보" className="bg-gray-100/90">
        <List.Item title="파일명">
          <Ellipsis direction="end" content={displayValue(file.rgnlFileName)} />
        </List.Item>
        <List.Item title="용량">
          {prettyBytes(_.toNumber(file.fileSize))}
        </List.Item>
        <List.Item title="설명">
          {/* 텍스트 입력 */}
          <Input
            placeholder="설명을 입력하세요."
            clearable={true}
            value={descriptionInput}
            onChange={handleDescriptionInput_onChange}
          />
        </List.Item>
      </List>

      <div className="p-3 space-y-2">
        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleApplyButton_onClick}
          className="w-full !bg-indigo-700"
        >
          <span className="text-md text-white">적용</span>
        </Button>

        {/* 버튼 */}
        <Button
          size="large"
          shape="default"
          onClick={handleRemoveButton_onClick}
          className="w-full !bg-rose-700"
        >
          <span className="text-md text-white">첨부파일 삭제</span>
        </Button>
      </div>
    </div>
  );
};

export default ModifyFile;
