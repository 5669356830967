import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  ILeftMenu,
  ILoginUser,
  IPageNavigation,
  IPopup,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import * as appUtil from '../../utils/app.util';
import { useTranslation } from 'react-i18next';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { LanguageMenu } from '../../components/LanguageMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nanoid } from 'nanoid';
import { FindPassword } from '../../popups/FindPassword';
import { addPopupStore } from '../../stores/popup.store';
import { MainMenu } from '../LeftMenu';
import { AlarmLog } from '../../components/AlarmLog';
import { Ellipsis } from 'antd-mobile';

interface ILayoutProps {
  pageInfoBarLeftArea?: any;
  pageInfoBarCenterArea?: any;
  pageInfoBarRightArea?: any;
  data?: any;
  onClick?: () => void;
}

/**
 * 상단바
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const TopBar = ({
  pageInfoBarLeftArea = null,
  pageInfoBarCenterArea = null,
  pageInfoBarRightArea = null,
  data,
  onClick,
}: PropsWithChildren<ILayoutProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 페이지 내비게이션을 정의함
  const [pageNavigation, setPageNavigation] = useState<IPageNavigation[]>([]);

  // 페이지 내비게이션 태그를 정의함
  const [pageNavigationTag, setPageNavigationTag] = useState<JSX.Element[]>([]);

  // 로그아웃 버튼을 클릭함
  const handleLogoutButton_onClick = () => {
    // 로그인한 사용자를 Local Storage에서 삭제함
    appUtil.removeLoginUser();

    // 로그인한 사용자 토큰을 Local Storage에서 삭제함
    appUtil.removeToken();

    // 로그인 페이지로 이동함
    navigate('/login');
  };

  // 페이지 내비게이션 태그를 생성함
  const getPageNavigationTag = (): JSX.Element[] => {
    let result: JSX.Element[] = [];

    pageNavigation.map((item: IPageNavigation, index: number) => {
      result.push(
        <li
          key={index}
          onClick={() => handlePageNavigationItem_onClick(item.link)}
          className={[
            'button-event breadcrumb-item',
            pageNavigation.length === index + 1 && 'active',
          ].join(' ')}
        >
          {item.title}
        </li>,
      );
    });

    return result;
  };

  // 메뉴 버튼을 클릭함
  const handleMenuButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '메뉴',
      content: <MainMenu id={tmpId} />,
      widthSizePercent: 100,
      heightSizePercent: 100,
      position: 'left',
      rounded: false,
      maskClick: true,
    });
  };

  // 로고 버튼을 클릭함
  const handleLogoButton_onClick = () => {
    window.location.href = '/';
  };

  // 알람 이력 버튼을 클릭함
  const handleAlarmLogButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '알람 이력',
      content: <AlarmLog id={tmpId} />,
      widthSizePercent: 100,
      heightSizePercent: 80,
      position: 'bottom',
      maskClick: true,
    });
  };

  // 페이지 내비게이션 아이템을 클릭함
  const handlePageNavigationItem_onClick = (link: string) => {
    // 해당 페이지로 이동함
    navigate(link);
  };

  // 컴포넌트 샘플 버튼을 클릭함
  const handleComponentSampleButton_onClick = () => {
    // 해당 페이지로 이동함
    navigate('/sample');
  };

  useEffect(() => {
    return () => {};
  }, []);

  // 선택한 페이지의 정보가 생성될 때 실행함
  useEffect(() => {
    // 페이지 내비게이션을 정의함
    let tmpPageNavigation: IPageNavigation[] = [];

    // 내비게이션에 1딘계 페이지를 추가함
    if (selectedPage.oneMenuId) {
      if (selectedPage.oneKrnMenu !== 'HOME') {
        tmpPageNavigation.push({
          title: selectedPage.oneKrnMenu,
          link: '/',
        });
      }
    }

    // 내비게이션에 2딘계 페이지를 추가함
    if (selectedPage.twoMenuId) {
      tmpPageNavigation.push({
        title: selectedPage.twoKrnMenu,
        link: `/${selectedPage.menuId}`,
      });
    }

    // 내비게이션에 3딘계 페이지를 추가함
    if (selectedPage.threeMenuId) {
      tmpPageNavigation.push({
        title: selectedPage.threeKrnMenu,
        link: `/${selectedPage.menuId}`,
      });
    }

    // 페이지 내비게이션에 적용함
    setPageNavigation(tmpPageNavigation);

    return () => {};
  }, [selectedPage]);

  // 페이지 내비게이션이 생성될 때 실행함
  useEffect(() => {
    // 페이지 내비게이션 태그에 적용함
    setPageNavigationTag(getPageNavigationTag());

    return () => {};
  }, [pageNavigation]);

  return (
    // <div className="fixed left-0 top-0 w-full h-14 py-2 flex justify-between items-center bg-white/30 backdrop-blur-md z-90">
    <div className="fixed left-0 top-0 w-full h-14 py-2 flex justify-between items-center bg-white z-90">
      {/* 로고 */}
      <div className="h-10 pl-5 flex justify-center items-center space-x-1">
        {/* 메뉴 버튼 */}
        <div
          onClick={handleMenuButton_onClick}
          className="button-event flex justify-center items-center"
        >
          <FontAwesomeIcon
            icon={['fas', 'bars']}
            className="w-4 h-4 text-red-700"
          />
        </div>

        {/* 왼쪽 정보 영역 */}
        {/* 로고 */}
        {pageInfoBarLeftArea === null && (
          <div className="px-1 py-2">
            <div className="pb-1.5">
              <div className="button-event company-logo-2 w-20 h-6 bg-auto bg-no-repeat bg-center" />
            </div>
          </div>
        )}

        {/* 왼쪽 정보 영역 */}
        {/* 페이지 제목 */}
        {pageInfoBarLeftArea !== null && (
          <div className="pl-3">
            <span className="text-xl text-gray-900 font-bold">
              <Ellipsis direction="end" content={pageInfoBarLeftArea} />
            </span>
          </div>
        )}
      </div>

      {/* 단축 버튼 */}
      <div className="pr-5 flex justify-center items-center space-x-6">
        {/* 알림 이력 버튼 */}
        {/*<div*/}
        {/*  onClick={handleAlarmLogButton_onClick}*/}
        {/*  className="relative button-event flex justify-center items-center"*/}
        {/*>*/}
        {/*  <FontAwesomeIcon*/}
        {/*    icon={['fas', 'bell']}*/}
        {/*    className="w-6 h-6 text-gray-400"*/}
        {/*  />*/}

        {/*  <div className="absolute right-4 -top-1 px-1 py-0.5 flex justify-center items-center bg-red-600 rounded-md">*/}
        {/*    <span className="text-xs text-white font-bold leading-none">*/}
        {/*      99+*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/* 사용자 정보 */}
        <div className="flex justify-center items-center">
          <span className="text-base text-gray-700 font-bold">
            {loginUser.name}
          </span>
          <span className="text-base text-gray-400 font-bold">님</span>
        </div>

        {/* 로그아웃 버튼 */}
        <div
          onClick={handleLogoutButton_onClick}
          className="button-event flex justify-center items-center"
        >
          <FontAwesomeIcon
            icon={['fas', 'arrow-right-from-bracket']}
            className="w-6 h-6 text-gray-700"
          />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
