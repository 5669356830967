import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopBar } from '../TopBar';
import { GlobalProcess } from '../../managers/GlobalProcess';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  ILeftMenu,
  IOverlay,
  IPageContent,
} from '../../interfaces/app.interface';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { BottomBar } from '../BottomBar';
import { ScrollArea } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { pageContentStore } from '../../stores/page.store';
import { ComponentModalManager } from '../../managers/ComponentModalManager';
import ModalManager from '../../managers/ModalManager/ModalManager.manager';
import { RefreshListManager } from '../../managers/RefreshListManager';
import { PopupManager } from '../../managers/PopupManager';
import loadingAnimation from './loading.json';
import { overlayStore } from '../../stores/overlay.store';
import Lottie from 'lottie-react';
import { leftMenuStore } from '../../stores/leftMenu.store';
import leftMenuLayout from '../LeftMenu/LeftMenu.layout';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

interface ILayoutProps {
  pageInfoBarLeftArea?: any;
  pageInfoBarCenterArea?: any;
  pageInfoBarRightArea?: any;
  onClick?: () => void;
}

/**
 * 페이지 레이아웃
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PageLayout = ({
  pageInfoBarLeftArea = null,
  pageInfoBarCenterArea = null,
  pageInfoBarRightArea = null,
  onClick,
  children,
}: PropsWithChildren<ILayoutProps>) => {
  // 페이지의 내용 영역 크기를 정의함
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 페이지 주소를 정의함
  const location = useLocation();

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 메뉴 저장소를 정의함
  const [leftMenu, setLeftMenu] = useRecoilState<ILeftMenu[]>(leftMenuStore);

  // 선택한 페이지의 이름을 정의함
  const [selectedPageName, setSelectedPageName] = useState<string>('');

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [overlay, setOverlay] = useRecoilState<IOverlay>(overlayStore);

  // 화면을 스크롤함
  const handleScrollTo = (scrollType: string) => {
    switch (scrollType) {
      case 'top':
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        break;

      case 'bottom':
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // 메뉴 목록을 불러옴
    cmnApi
      .getMenus({ menuDvsnCode: 'MBL', useYn: 'Y' })
      .then((data: IApiResult) => {
        let tmpLeftMenu: ILeftMenu[] = [];

        if (data.code === '200') {
          // 왼쪽 메뉴에 적용함
          setLeftMenu(data.data.list);
        }
      });

    return () => {};
  }, []);

  // 왼쪽 메뉴를 불러왔을 때 실행함
  useEffect(() => {
    if (leftMenu.length === 0) {
      return;
    }

    // 현재 페이지의 주소를 불러옴
    let url: string = location.pathname;

    // 선택한 페이지를 불러옴
    let tmpSelectedPage: ILeftMenu | undefined = _.find(leftMenu, {
      twoUrl: url,
    });

    if (tmpSelectedPage !== undefined) {
      // 선택한 페이지에 적용함
      setSelectedPage(tmpSelectedPage);
    }

    return () => {};
  }, [leftMenu]);

  // 선택한 페이지가 생성됐을 때 실행함
  useEffect(() => {
    let tmpSelectedPageName: string = '';

    // 내비게이션에 1딘계 페이지를 추가함
    if (selectedPage.oneMenuId) {
      tmpSelectedPageName = selectedPage.oneKrnMenu;
    }

    // 내비게이션에 2딘계 페이지를 추가함
    if (selectedPage.twoMenuId) {
      tmpSelectedPageName = selectedPage.twoKrnMenu;
    }

    // 내비게이션에 3딘계 페이지를 추가함
    if (selectedPage.threeMenuId) {
      tmpSelectedPageName = selectedPage.threeKrnMenu;
    }

    // 선택한 페이지의 이름에 적용함
    setSelectedPageName(tmpSelectedPageName);

    return () => {};
  }, [selectedPage]);

  // 페이지의 내용 영역 크기가 변경될 때 실행함
  useEffect(() => {
    // 페이지 내용 저장소에 적용함
    setPageContent({
      pageContentWidth: contentWidth,
    });

    return () => {};
  }, [contentWidth]);

  return (
    <>
      {/* 전역 처리 */}
      <GlobalProcess />

      {/* 팝업 매니저 */}
      <PopupManager />

      {/* 목록 새로고침 매니저 */}
      <RefreshListManager />

      {/* 화면 위 검은 화면 */}
      {overlay.visible && (
        <div className="object-opacity-appear fixed left-0 top-0 w-screen h-screen z-1000">
          {/* 로딩 애니메이션 */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center bg-white/0 z-20">
            <div className="w-96 h-96 flex justify-center items-center">
              <Lottie animationData={loadingAnimation} loop={true} />
            </div>
          </div>

          {/* 내용 */}
          {overlay.content && (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center bg-white/0 z-30">
              {overlay.content}
            </div>
          )}

          {/* 하얀/검은 배경 */}
          {overlay.useLightMode === true && (
            <div className="w-screen h-screen bg-white/60" />
          )}
          {overlay.useLightMode === false && (
            <div className="w-screen h-screen bg-black/60" />
          )}
        </div>
      )}

      <div className="relative w-screen h-full bg-white">
        {/* 상단바 */}
        <TopBar
          pageInfoBarLeftArea={pageInfoBarLeftArea}
          pageInfoBarCenterArea={pageInfoBarCenterArea}
          pageInfoBarRightArea={pageInfoBarRightArea}
        />

        {/* 페이지 */}
        <div ref={contentRef} className="pt-14">
          <div className="space-y-3">{children}</div>
        </div>

        {/* 하단바 */}
        <BottomBar />

        {/* 스크롤 버튼 */}
        <div id="scroll-button" className="fixed right-4 bottom-4 z-100">
          <div className="space-y-1">
            {/* 최상단 */}
            <div
              onClick={() => handleScrollTo('top')}
              className="button-event w-10 h-10 flex justify-center items-center bg-white/90 border border-gray-300/80 rounded-t-md"
            >
              <FontAwesomeIcon
                icon={['fas', 'arrow-up']}
                className="w-4 h-4 text-gray-600/60"
              />
            </div>

            {/* 최하단 */}
            <div
              onClick={() => handleScrollTo('bottom')}
              className="button-event w-10 h-10 flex justify-center items-center bg-white/90 border border-gray-300/80 rounded-b-md"
            >
              <FontAwesomeIcon
                icon={['fas', 'arrow-down']}
                className="w-4 h-4 text-gray-600/60"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLayout;
