import { atom } from 'recoil';
import { IComponentModal } from '../interfaces/app.interface';

/**
 * 컴포넌트 모달
 */
export const componentModalStore = atom({
  key: 'componentModalStore',
  default: [] as IComponentModal[],
});

/**
 * 추가할 컴포넌트 모달
 */
export const addComponentModalStore = atom({
  key: 'addComponentModalStore',
  default: null as IComponentModal | null,
});

/**
 * 수정할 컴포넌트 모달
 */
export const modifyComponentModalStore = atom({
  key: 'modifyComponentModalStore',
  default: null as IComponentModal | null,
});

/**
 * 삭제할 컴포넌트 모달
 */
export const removeComponentModalStore = atom({
  key: 'removeComponentModalStore',
  default: null as string | null,
});

/**
 * 전체 컴포넌트 모달 삭제
 */
export const removeAllComponentModalStore = atom({
  key: 'removeAllComponentModalStore',
  default: false as boolean,
});
