import React, { PropsWithChildren, useEffect } from 'react';
import { IFileDownloadList } from '../../interfaces/app.interface';
import { Button, Ellipsis, ImageViewer, SwipeAction } from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  data?: IFileDownloadList[];
  onClick?: () => void;
  visibleSwipeRightAction?: boolean;
  onClickRemoveFileButton?: (event: any) => void;
}

/**
 * 파일 다운로드 목록
 * @param data 파일 목록
 * @param visibleSwipeRightAction 오른쪽 스위프트 액션 표시 여부
 * @param onClickRemoveFileButton 파일 삭제 버튼 클릭 이벤트
 * @constructor
 */
const ImageFilePreviewList = ({
  data,
  onClick,
  visibleSwipeRightAction = false,
  onClickRemoveFileButton = (event: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  return (
    <div className="grid grid-cols-4 gap-3">
      {data
        ?.filter(
          (item: IFileDownloadList) =>
            appUtil.getFileNameExtension(item.originalFileName).isImage,
        )
        .map((item: IFileDownloadList, index: number) => (
          <SwipeAction
            key={index}
            rightActions={
              visibleSwipeRightAction
                ? [
                    {
                      key: 'remove',
                      text: (
                        <div className="space-y-1">
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['fas', 'xmark']}
                              className="w-4 h-4 text-white"
                            />
                          </div>

                          <div className="flex justify-center items-center">
                            <span className="text-base text-white">삭제</span>
                          </div>
                        </div>
                      ),
                      color: 'rgb(225 29 72)',
                      onClick: (event: any) => {
                        onClickRemoveFileButton(item);
                      },
                    },
                  ]
                : []
            }
            className="ant-m-swipe-action"
          >
            <div
              onClick={() => {
                let tmpFileList: any[] = [];
                let tmpFileNameList: string[] = [];

                data
                  ?.filter(
                    (item: IFileDownloadList) =>
                      appUtil.getFileNameExtension(item.originalFileName)
                        .isImage,
                  )
                  .map((subItem: IFileDownloadList) => {
                    tmpFileList.push(
                      `${appUtil.getResizeImageUrl()}${subItem.saveFileName}`,
                    );

                    tmpFileNameList.push(subItem.originalFileName);
                  });

                ImageViewer.Multi.show({
                  images: tmpFileList,
                  defaultIndex: index,
                  renderFooter: (imageItem: string, imageIndex: number) => (
                    <div className="px-3 pb-4">
                      {/* 파일명 */}
                      <span className="text-xs text-white font-semibold">
                        <Ellipsis
                          direction="middle"
                          content={tmpFileNameList[imageIndex]}
                        />
                      </span>
                    </div>
                  ),
                });
              }}
              className="button-bg-opacity-event w-full m-auto flex justify-center items-center bg-gray-100 border border-gray-300 rounded-md overflow-hidden"
            >
              <div
                style={{
                  paddingBottom: '100%',
                }}
                className="h-0"
              >
                {/*<span className="text-xs text-gray-500 font-medium">*/}
                {/*  <Ellipsis*/}
                {/*    direction="middle"*/}
                {/*    content={item.originalFileName}*/}
                {/*  />*/}
                {/*</span>*/}
                <img
                  src={`${appUtil.getThumbnailImageUrl()}${item.saveFileName}`}
                  className="w-full"
                />
              </div>
            </div>
          </SwipeAction>
        ))}
    </div>
  );
};

export default ImageFilePreviewList;
