import React, { PropsWithChildren } from 'react';
import { ActionSheet, Button } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/action-sheet';
import { IActionSheet } from '../../interfaces/app.interface';

interface IComponentProps {
  label?: string;
  className?: string;
  onClick?: () => void;
}

/**
 * 폼 박스 아이템
 * @param label 레이블
 * @param className CSS 클래스
 * @param onClick <이벤트>
 * @param children 기타 props
 * @constructor
 */
const PbFormBoxItem = ({
  label = '',
  className,
  onClick,
  children,
}: PropsWithChildren<IComponentProps>) => {
  return (
    <div className={['space-y-1', className].join(' ')}>
      {/* 제목 */}
      {label && (
        <div className="flex justify-start items-center">
          <span className="text-xs text-gray-500 font-bold">{label}</span>
        </div>
      )}

      {/* 내용 */}
      {children}
    </div>
  );
};

export default PbFormBoxItem;
