import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { selectedPageStore } from '../stores/selectedPage.store';
import { PageLayout } from '../layouts/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Select, TextInput, Progress } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import _ from 'lodash';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { pageContentStore } from '../stores/page.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import * as cmnApi from '../apis/cmn.api';
import * as csApi from '../apis/cs.api';
import { PbFormBox } from '../components/PbFormBox';
import { leftMenuStore } from '../stores/leftMenu.store';

/**
 * 메인(대시보드)
 * @constructor
 */
const MainPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 메뉴 저장소를 정의함
  const [leftMenu, setLeftMenu] = useRecoilState<ILeftMenu[]>(leftMenuStore);

  // 메뉴를 클릭함
  const handleMenu_onClick = (url: string) => {
    window.location.href = url;
  };

  // 메뉴에 접근이 가능한지 확인함(true: 가능, false: 불가능)
  const checkAvailableMenu = (menuUrl: string): boolean => {
    let check: number = _.findIndex(leftMenu, { twoUrl: menuUrl });

    return check > -1 ? true : false;
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 메뉴 목록을 불러옴
    cmnApi
      .getMenus({ menuDvsnCode: 'MBL', useYn: 'Y' })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 왼쪽 메뉴에 적용함
          setLeftMenu(data.data.list);
        }
      });

    return () => {};
  }, []);

  return (
    <PageLayout>
      {checkAvailableMenu('/performance-registration') && (
        <PbFormBox
          label="실적등록"
          rightForm={
            <div
              onClick={() => handleMenu_onClick('/performance-registration')}
              className="button-event px-2 py-1 flex justify-center items-center"
            >
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className="w-4 h-4 text-gray-400"
              />
            </div>
          }
          onClick={() => handleMenu_onClick('/performance-registration')}
        />
      )}

      {checkAvailableMenu('/work-inspection') && (
        <PbFormBox
          label="작업검수"
          rightForm={
            <div
              onClick={() => handleMenu_onClick('/work-inspection')}
              className="button-event px-2 py-1 flex justify-center items-center"
            >
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className="w-4 h-4 text-gray-400"
              />
            </div>
          }
          onClick={() => handleMenu_onClick('/work-inspection')}
        />
      )}

      {checkAvailableMenu('/pm-inspection') && (
        <PbFormBox
          label="PM검수"
          rightForm={
            <div
              onClick={() => handleMenu_onClick('/pm-inspection')}
              className="button-event px-2 py-1 flex justify-center items-center"
            >
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className="w-4 h-4 text-gray-400"
              />
            </div>
          }
          onClick={() => handleMenu_onClick('/pm-inspection')}
        />
      )}

      {/* 폼 박스 */}
      {checkAvailableMenu('/notice') && (
        <PbFormBox
          label="공지사항"
          rightForm={
            <div
              onClick={() => handleMenu_onClick('/notice')}
              className="button-event px-2 py-1 flex justify-center items-center"
            >
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className="w-4 h-4 text-gray-400"
              />
            </div>
          }
          onClick={() => handleMenu_onClick('/notice')}
        />
      )}

      {/* 폼 박스 */}
      {/*{checkAvailableMenu('MN2302221201') && (*/}
      {/*  <PbFormBox*/}
      {/*    label="진행 중 작업 관리"*/}
      {/*    rightForm={*/}
      {/*      <div*/}
      {/*        onClick={() => handleMenu_onClick('/ongoing-job-management')}*/}
      {/*        className="button-event px-2 py-1 flex justify-center items-center"*/}
      {/*      >*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'chevron-right']}*/}
      {/*          className="w-4 h-4 text-gray-400"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*    onClick={() => handleMenu_onClick('/ongoing-job-management')}*/}
      {/*  />*/}
      {/*)}*/}

      {/* 폼 박스 */}
      {/*{checkAvailableMenu('MN2302221202') && (*/}
      {/*  <PbFormBox*/}
      {/*    label="PM 검수 대기 현황"*/}
      {/*    rightForm={*/}
      {/*      <div*/}
      {/*        onClick={() => handleMenu_onClick('/pm-inspection-standby-state')}*/}
      {/*        className="button-event px-2 py-1 flex justify-center items-center"*/}
      {/*      >*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'chevron-right']}*/}
      {/*          className="w-4 h-4 text-gray-400"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*    onClick={() => handleMenu_onClick('/pm-inspection-standby-state')}*/}
      {/*  />*/}
      {/*)}*/}

      {/* 폼 박스 */}
      {/*{checkAvailableMenu('MN2302221301') && (*/}
      {/*  <PbFormBox*/}
      {/*    label="재고 이동 요청 이력"*/}
      {/*    rightForm={*/}
      {/*      <div*/}
      {/*        onClick={() => handleMenu_onClick('/stock-request-history')}*/}
      {/*        className="button-event px-2 py-1 flex justify-center items-center"*/}
      {/*      >*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'chevron-right']}*/}
      {/*          className="w-4 h-4 text-gray-400"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*    onClick={() => handleMenu_onClick('/stock-request-history')}*/}
      {/*  />*/}
      {/*)}*/}
    </PageLayout>
  );
};

export default MainPage;
