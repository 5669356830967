import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IApiResult, ILeftMenu } from '../../interfaces/app.interface';
import * as cmnApi from '../../apis/cmn.api';
import { useRecoilState } from 'recoil';
import { leftMenuStore } from '../../stores/leftMenu.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import _ from 'lodash';

interface ILayoutProps {
  id?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 메인 메뉴
 * @param id 컴포넌트 모달 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MainMenu = ({
  id = '',
  data,
  onClick,
}: PropsWithChildren<ILayoutProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 페이지 주소를 정의함
  const location = useLocation();

  // 메뉴 저장소를 정의함
  const [leftMenu, setLeftMenu] = useRecoilState<ILeftMenu[]>(leftMenuStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 선택한 메뉴 그룹을 정의함
  const [selectedMenuGroup, setSelectedMenuGroup] = useState<string>('');

  // 메뉴를 클릭함
  const handleMenu_onClick = (url: string) => {
    window.location.href = url;
  };

  // 메뉴 아이템을 클릭함
  const handleMenuClick = (menuId: string) => {
    // 클릭한 페이지를 불러옴
    let tmpClickedPage: ILeftMenu | undefined = _.find(leftMenu, {
      twoMenuId: menuId,
    });

    switch (tmpClickedPage?.twoMenuTypeCode) {
      // 페이지
      case 'P':
      case '페이지':
        // 해당 페이지로 이동함
        // navigate(tmpClickedPage?.twoUrl);
        window.location.href = tmpClickedPage?.twoUrl;
        break;

      // 링크
      case 'L':
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // 메뉴 목록을 불러옴
    cmnApi
      .getMenus({ menuDvsnCode: 'MBL', useYn: 'Y' })
      .then((data: IApiResult) => {
        let tmpLeftMenu: ILeftMenu[] = [];

        if (data.code === '200') {
          // 왼쪽 메뉴에 적용함
          setLeftMenu(data.data.list);
        }
      });

    return () => {};
  }, []);

  // 왼쪽 메뉴를 불러왔을 때 실행함
  useEffect(() => {
    if (leftMenu.length === 0) {
      return;
    }

    // 현재 페이지의 주소를 불러옴
    let url: string = location.pathname;

    // 선택한 페이지를 불러옴
    let tmpSelectedPage: ILeftMenu | undefined = _.find(leftMenu, {
      twoUrl: url,
    });

    if (tmpSelectedPage !== undefined) {
      // 선택한 페이지에 적용함
      setSelectedPage(tmpSelectedPage);

      // 선택한 메뉴 그룹에 적용함
      setSelectedMenuGroup(tmpSelectedPage.oneMenuId);
    }

    return () => {};
  }, [leftMenu]);

  return (
    <div className="pt-2 pb-10 space-y-12">
      {/* 정보 섹션 */}
      <div className="space-y-4">
        {/* 섹션 제목 */}
        <div className="px-1">
          <span className="text-2xl text-gray-600 font-bold">정보</span>
        </div>

        {/* 섹션 내용 */}
        <div className="space-y-4">
          {/* 메뉴 */}
          <div
            onClick={() => handleMenu_onClick('/')}
            className="button-event w-full pl-3 flex justify-center items-center space-x-3 select-none"
          >
            <div className="flex-none flex justify-center items-center">
              <FontAwesomeIcon
                icon={['far', 'note-sticky']}
                className="w-4 h-4 text-indigo-800"
              />
            </div>

            <div className="grow flex justify-start items-center">
              <span className="text-lg text-gray-600">홈</span>
            </div>
          </div>
        </div>
      </div>

      {_.uniqBy(leftMenu, 'oneSortRdr').map((item: any, index: number) => {
        if (item.oneSortRdr !== '1') {
          return (
            <div key={item.oneMenuId} className="space-y-4">
              {/* 섹션 제목 */}
              <div className="px-1">
                <span className="text-2xl text-gray-600 font-bold">
                  {item.oneKrnMenu}
                </span>
              </div>

              {/* 섹션 내용 */}
              <div className="space-y-4">
                {/* 하위 메뉴 */}
                {_.filter(leftMenu, {
                  oneSortRdr: item.oneSortRdr,
                }).map((subItem: any, subIndex: number) => {
                  if (subItem.twoMenuId) {
                    return (
                      <div
                        key={subItem.twoMenuId}
                        onClick={() => handleMenuClick(subItem.twoMenuId)}
                        className="button-event w-full pl-3 flex justify-center items-center space-x-3 select-none"
                      >
                        <div className="flex-none flex justify-center items-center">
                          <FontAwesomeIcon
                            icon={['far', 'note-sticky']}
                            className="w-4 h-4 text-indigo-800"
                          />
                        </div>

                        <div className="grow flex justify-start items-center">
                          <span className="text-lg text-gray-600">
                            {subItem.twoKrnMenu}
                          </span>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        }
      })}

      {/*/!* 정보 섹션 *!/*/}
      {/*<div className="space-y-4">*/}
      {/*  /!* 섹션 제목 *!/*/}
      {/*  <div className="">*/}
      {/*    <span className="text-2xl text-gray-600 font-bold">정보</span>*/}
      {/*  </div>*/}

      {/*  /!* 섹션 내용 *!/*/}
      {/*  <div className="space-y-4">*/}
      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'house']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">홈</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      // onClick={() => handleMenu_onClick('/')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['far', 'user']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">사용자 정보</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*/!* 설치 관리 섹션 *!/*/}
      {/*<div className="space-y-4">*/}
      {/*  /!* 섹션 제목 *!/*/}
      {/*  <div className="">*/}
      {/*    <span className="text-2xl text-gray-600 font-bold">설치 관리</span>*/}
      {/*  </div>*/}

      {/*  /!* 섹션 내용 *!/*/}
      {/*  <div className="space-y-4">*/}
      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/ongoing-job-management')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'chalkboard-user']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">진행 중 작업 관리</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/pm-inspection-standby-state')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'pen-to-square']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">PM 검수 대기 현황</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*/!* 현황 관리 섹션 *!/*/}
      {/*<div className="space-y-4">*/}
      {/*  /!* 섹션 제목 *!/*/}
      {/*  <div className="">*/}
      {/*    <span className="text-2xl text-gray-600 font-bold">현황 관리</span>*/}
      {/*  </div>*/}

      {/*  /!* 섹션 내용 *!/*/}
      {/*  <div className="space-y-4">*/}
      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/stock-request-history')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'truck-ramp-box']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">재고 이동 요청 이력</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*/!* 게시판 섹션 *!/*/}
      {/*<div className="space-y-4">*/}
      {/*  /!* 섹션 제목 *!/*/}
      {/*  <div className="">*/}
      {/*    <span className="text-2xl text-gray-600 font-bold">게시판</span>*/}
      {/*  </div>*/}

      {/*  /!* 섹션 내용 *!/*/}
      {/*  <div className="space-y-4">*/}
      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/manual')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'book-open']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">메뉴얼</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/notice')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['far', 'message']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">공지사항</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    /!* 메뉴 *!/*/}
      {/*    <div*/}
      {/*      onClick={() => handleMenu_onClick('/faq')}*/}
      {/*      className="button-event w-full pl-1 flex justify-center items-center space-x-3 select-none"*/}
      {/*    >*/}
      {/*      <div className="flex-none flex justify-center items-center">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={['fas', 'person-chalkboard']}*/}
      {/*          className="w-5 h-5 text-indigo-700"*/}
      {/*        />*/}
      {/*      </div>*/}

      {/*      <div className="grow flex justify-start items-center">*/}
      {/*        <span className="text-lg text-gray-600">FAQ</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default MainMenu;
