import { atom } from 'recoil';
import { IPopup } from '../interfaces/app.interface';

/**
 * 팝업
 */
export const popupStore = atom({
  key: 'popupStore',
  default: [] as IPopup[],
});

/**
 * 추가할 팝업
 */
export const addPopupStore = atom({
  key: 'addPopupStore',
  default: null as IPopup | null,
});

/**
 * 수정할 팝업
 */
export const modifyPopupStore = atom({
  key: 'modifyPopupStore',
  default: null as IPopup | null,
});

/**
 * 삭제할 팝업
 */
export const removePopupStore = atom({
  key: 'removePopupStore',
  default: null as string | null,
});

/**
 * 전체 팝업 삭제
 */
export const removeAllPopupStore = atom({
  key: 'removeAllPopupStore',
  default: false as boolean,
});
