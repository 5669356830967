import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';
import * as appUtil from '../utils/app.util';

/**
 * STTN: 현황 관리
 */

/**
 * ERP 판매 계약 현황 조회
 * GET /api/sttn/erp
 * @param params pageSize
 * @param params currPageIdx 현재 페이지
 * @param params cntrPrgrCode 계약 진행 상황 코드(end:종료, prcd:진행중)
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params useYn 사용 여부
 */
export const getSttnErps = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/sttn/erp',
    params: {
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      pageSize: params.pageSize || 1000,
      currPageIdx: params.currPageIdx || '1',
      cntrPrgrCode: params.cntrPrgrCode || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ERP 판매 계약 현황 다운로드
 * GET /api/sttn/erp-excel
 * @param params cntrPrgrCode 계약 진행 상황 코드(end:종료, prcd:진행중)
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params useYn 사용 여부
 */
export const getSttnErpExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/sttn/erp-excel',
    responseType: 'blob',
    params: {
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      cntrPrgrCode: params.cntrPrgrCode || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ERP 판매 계약 상세
 * GET /api/sttn/erp/{cntrNmbr}
 * @param params cntrNmbr 계약 번호
 */
export const getSttnErp = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/sttn/erp', params.cntrNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ERP 판매 계약 담당PM(select box)
 * GET /api/sttn/erp/search_pm
 */
export const getSttnErpSearchPm = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sttn/erp/search_pm',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ESL 설치작업 진척 현황
 * GET /api/sttn/esl
 * @param params pageSize
 * @param params currPageIdx 현재 페이지
 */
export const getSttnEsl = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/sttn/esl',
    params: {
      pageSize: params.pageSize || 1000,
      currPageIdx: params.currPageIdx || '1',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ESL 설치작업 진척 현황 상세 주문리스트
 * GET /api/sttn/esl-order-list
 * @param params prfcCode 프로젝트 코드
 * @param params searchType 검색구분
 */
export const getSttnEslOrderList = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/sttn/esl-order-list',
    params: {
      prfcCode: params.prfcCode,
      searchType: params.searchType,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ESL 설치작업 진척 현황 상세
 * GET /api/sttn/esl/{prfcCode}
 * @param params prjcCode 프로젝트 코드
 */
export const getSttnEslDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/sttn/esl', params.prfcCode].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 재고이전 요청 이력 조회
 * GET /api/sttn/nvnt
 * @param params pageSize
 * @param params currPageIdx 현재 페이지
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params endDate 요청기간 종료일
 * @param params stDate 요청기간 시작일
 * @param params stckSttsCode 재고 상태 코드
 * @param params stckTypeCode 재고 유형 코드
 */
export const getSttnNvnt = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/sttn/nvnt',
    params: {
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      pageSize: params.pageSize || 1000,
      currPageIdx: params.currPageIdx || '1',
      endDate: params.endDate || '',
      stDate: params.stDate || '',
      stckSttsCode: params.stckSttsCode || '',
      stckTypeCode: params.stckTypeCode || '',
      cmpnCode: params.cmpnCode || '',
      userId: params.userId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 재고이전 요청 이력 엑셀 다운로드
 * GET /api/sttn/nvnt-excel
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params endDate 요청기간 종료일
 * @param params stDate 요청기간 시작일
 * @param params stckSttsCode 재고 상태 코드
 * @param params stckTypeCode 재고 유형 코드
 */
export const getSttnNvntExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/sttn/nvnt-excel',
    responseType: 'blob',
    params: {
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      endDate: params.endDate || '',
      stDate: params.stDate || '',
      stckSttsCode: params.stckSttsCode || '',
      stckTypeCode: params.stckTypeCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 재고이전 요청 이력 상세
 * GET /api/sttn/nvnt/{docEntry}
 * @param params docEntry 재고이전 문서번호
 */
export const getSttnNvntDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/sttn/nvnt', params.docEntry].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * ERP 판매 계약 수정
 * PUT /api/sttn/erp/{cntrNmbr}
 * @param params cntrNmbr 계약 번호
 * @param params pmId 담당 PM
 * @param params useYn 사용 여부
 */
export const putSttnErp = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/sttn/erp', params.cntrNmbr].join('/'),
    data: {
      pmId: params.pmId || '',
      useYn: params.useYn || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};
