import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  IActionSheet,
  IApiResult,
  ILoginUser,
  IPopup,
} from '../../interfaces/app.interface';
import * as nstlApi from '../../apis/nstl.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { decode } from 'html-entities';
import { getPrcdsDcmnDntfNmbr } from '../../apis/nstl.api';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import {
  ActionSheet,
  Button,
  Collapse,
  Dialog,
  Ellipsis,
  List,
  Toast,
} from 'antd-mobile';
import {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet';
import { nanoid } from 'nanoid';
import { useRecoilState } from 'recoil';
import { addPopupStore, removePopupStore } from '../../stores/popup.store';
import { ModifySoftware } from '../ModifySoftware';
import { ModifyProduct } from '../ModifyProduct';
import { ModifyAmount } from '../ModifyAmount';
import { MoveProduct } from '../MoveProduct';
import { Comment } from '../Comment';
import { loginStore } from '../../stores/login.store';

interface IPopupProps {
  id: string;
  dcmnDntfNmbr: string;
  detailData: any;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 진행 중 작업 관리 > 상세 조회 > 작업결과
 * @param dcmnDntfNmbr <인자>
 * @param onClick <이벤트>
 * @constructor
 */

const OngoingJobManagementDetailResult = ({
  id,
  dcmnDntfNmbr,
  detailData,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IPopupProps>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 삭제할 팝업 저장소를 정의함
  const [removePopup, setRemovePopup] = useRecoilState<string | null>(
    removePopupStore,
  );

  // 액션시트 참조를 정의함
  const actionSheetHandlerRef = useRef<ActionSheetShowHandler>();

  // 상세 내용을 정의함
  const [detailResultData, setDetailResultData] = useState<any>(null);

  // 작업상태 액션시트를 정의함
  const [workStatusActionSheet, setWorkStatusActionSheet] =
    useState<IActionSheet>({
      value: '',
      item: [],
    });

  // 작업상태 액션시트를 초기화함
  const initWorkStatusActionSheet = () => {
    let tmpOptionItem: Action[] = [];

    tmpOptionItem.push(
      {
        text: '진행중',
        key: 'PRCD',
      },
      {
        text: '작업완료',
        key: 'WORK_CMPL',
      },
    );

    // 로그인한 사용자가 협력사가 아닐 때만 검수완료 항목을 추가함
    if (loginUser.cmpnCode !== 'PRTN') {
      tmpOptionItem.push({
        text: '검수완료',
        key: 'NSPC_CMPL',
      });
    }

    // 작업상태 액션시트에 적용함
    setWorkStatusActionSheet((pre: IActionSheet) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  // 항목의 값이 없을 때, 빈 값을 반환함
  const displayValue = (value: any): any => {
    return value ? value : <span className="text-gray-300">정보 없음</span>;
  };

  // 액션시트에서 선택한 아이템의 레이블을 불러옴
  const getActionSheetLabel = (actionSheet: IActionSheet) => {
    let tmpOptionItem: Action | undefined | null = _.find(actionSheet.item, {
      key: actionSheet.value,
    });

    return tmpOptionItem?.text || '';
  };

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    switch (detailData.splrTypeCode) {
      // 네트워크 공사 작업 내역
      case 'NTWR_CNST':
        nstlApi
          .getNstlWorkNtwr({
            splrId: detailData.splrId,
            teamId: detailData.teamId,
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            if (data.data !== undefined) {
              callback(data.data);
            }
          });
        break;

      // ESL 설치 작업 내역
      case 'ESL_NSTL':
        nstlApi
          .getNstlWorkEsl({
            splrId: detailData.splrId,
            teamId: detailData.teamId,
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            if (data.data !== undefined) {
              callback(data.data);
            }
          });
        break;

      default:
        break;
    }
  };

  // 댓글 버튼을 클릭함
  const handleCommentButton_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '댓글',
      content: (
        <Comment
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={detailData.splrId}
          teamId={detailData.teamId}
          splrTypeCode={detailData.splrTypeCode}
          callback={(result: any) => {}}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 70,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 네트워크 공사 작업 내역의 작업결과 버튼을 클릭함
  const handleNetworkList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '소프트웨어 정보 수정',
      content: (
        <ModifySoftware
          id={tmpId}
          data={event}
          callback={(result: any) => {
            let tmpNtwrList: any[] = _.cloneDeep(detailResultData.ntwrList);

            if (result.resultType === 'remove') {
              // 선택한 데이터를 삭제함
              tmpNtwrList = _.filter(tmpNtwrList, (item: any) => {
                return item.id !== result.preData.id;
              });
            } else {
              // 원본 데이터를 수정함
              tmpNtwrList.map((item: any) => {
                if (item.id === result.preData.id) {
                  item.ntwrWorkCode = result.type;
                  item.ntwrWorkName = result.typeLabel;
                  item.ttl = result.content;
                }
              });
            }

            // 상세 내용에 적용함
            setDetailResultData((pre: any) => ({
              ...pre,
              ntwrList: tmpNtwrList,
            }));
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 60,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // ESL 설치 작업 내역의 품목선택 버튼을 클릭함
  const handleEslList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '품목 선택',
      content: (
        <ModifyProduct
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          data={event}
          callback={(result: any) => {
            let tmpEslList: any[] = _.cloneDeep(detailResultData.eslList);

            if (result.resultType === 'remove') {
              // 선택한 데이터를 삭제함
              tmpEslList = _.filter(tmpEslList, (item: any) => {
                return item.itemNmbr !== result.preData.itemNmbr;
              });
            } else {
              // 원본 데이터를 수정함
              tmpEslList.map((item: any) => {
                if (item.itemNmbr === result.preData.itemNmbr) {
                  item.itemNmbr = result.itemNmbr;
                  item.itemDtls = result.itemDtls;
                }
              });
            }

            // 상세 내용에 적용함
            setDetailResultData((pre: any) => ({
              ...pre,
              eslList: tmpEslList,
            }));
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 70,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // ESL 설치 작업 내역의 수량수정 버튼을 클릭함
  const handleEslListAmount_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '수량 수정',
      content: (
        <ModifyAmount
          id={tmpId}
          data={event}
          callback={(result: any) => {
            let tmpEslList: any[] = _.cloneDeep(detailResultData.eslList);

            // 원본 데이터를 수정함
            tmpEslList.map((item: any) => {
              if (item.itemNmbr === result.preData.itemNmbr) {
                item.nstlQntt = result.nstlQntt;
                item.lostQntt = result.lostQntt;
                item.rtrnNrmlQntt = result.rtrnNrmlQntt;
                item.rtrnDfctQntt = result.rtrnDfctQntt;
              }
            });

            // 상세 내용에 적용함
            setDetailResultData((pre: any) => ({
              ...pre,
              eslList: tmpEslList,
            }));
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 70,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 소프트웨어 정보 추가 버튼을 클릭함
  const handleAddSoftwareButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '소프트웨어 정보 추가',
      content: (
        <ModifySoftware
          id={tmpId}
          data={null}
          callback={(result: any) => {
            let tmpNtwrList: any[] = _.cloneDeep(detailResultData.ntwrList);

            // 원본 데이터에 새 데이터를 추가함
            tmpNtwrList.push({
              id: nanoid(),
              ntwrWorkCode: result.type,
              ntwrWorkName: result.typeLabel,
              ttl: result.content,
              sortRdr: tmpNtwrList.length + 1,
            });

            // 상세 내용에 적용함
            setDetailResultData((pre: any) => ({
              ...pre,
              ntwrList: tmpNtwrList,
            }));
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 50,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 품목 추가 버튼을 클릭함
  const handleAddProductButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '품목 추가',
      content: (
        <ModifyProduct
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          data={null}
          callback={(result: any) => {
            let tmpEslList: any[] = _.cloneDeep(detailResultData.eslList);

            // 원본 데이터에 새 데이터를 추가함
            tmpEslList.push({
              id: nanoid(),
              itemNmbr: result.itemNmbr,
              itemDtls: result.itemDtls,
              qntt: '0',
              nstlQntt: '0',
              lostQntt: '0',
              rtrnNrmlQntt: '0',
              rtrnDfctQntt: '0',
            });

            // 상세 내용에 적용함
            setDetailResultData((pre: any) => ({
              ...pre,
              eslList: tmpEslList,
            }));
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 70,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 재고 이동 요청 버튼을 클릭함
  const handleMoveProductButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '재고 이동 요청',
      content: (
        <MoveProduct
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={detailData.splrId}
          teamId={detailData.teamId}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              switch (detailData.splrTypeCode) {
                // 네트워크 공사 작업 내역
                case 'NTWR_CNST':
                  // 수정을 위해 네트워크 설치 목록에 id를 추가함
                  data.ntwrList.map((item: any) => {
                    item['id'] = nanoid();
                  });
                  break;

                // ESL 설치 작업 내역
                case 'ESL_NSTL':
                  // 수정을 위해 ESL 설치 목록에 id를 추가함
                  data.eslList.map((item: any) => {
                    item['id'] = nanoid();
                  });
                  break;

                default:
                  break;
              }

              // 상세 내용에 적용함
              setDetailResultData(data);

              // 새로 변경할 작업상태 액션시트에 적용함
              setWorkStatusActionSheet((pre: IActionSheet) => ({
                ...pre,
                value: data.nstlSttsCode,
              }));
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 70,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 작업결과 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    switch (detailData.splrTypeCode) {
      // 네트워크 공사 작업 내역
      case 'NTWR_CNST':
        Dialog.confirm({
          content: '저장하시겠습니까?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: () => {
            nstlApi
              .putNstlWorkNtwr({
                dcmnDntfNmbr: dcmnDntfNmbr,
                installWorkNtwrParamList: detailResultData.ntwrList,
                nstlSttsCode: workStatusActionSheet.value,
                splrId: detailData.splrId,
                teamId: detailData.teamId,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  Toast.show({
                    position: 'bottom',
                    content: '저장하였습니다.',
                  });

                  // 부모창으로 결과를 넘김
                  callback({
                    resultType: 'refresh',
                  });

                  // 삭제할 팝업 저장소에 적용함
                  setRemovePopup(id);
                } else {
                  Toast.show({
                    position: 'bottom',
                    content: '저장에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                Toast.show({
                  position: 'bottom',
                  content: '저장에 실패하였습니다.',
                });
              });
          },
        });
        break;

      // ESL 설치 작업 내역
      case 'ESL_NSTL':
        let tmpEslList: any[] = _.cloneDeep(detailResultData.eslList);

        // 설치수량이 0인 항목이 있는지 확인함
        let chkEmpty: boolean = false;

        tmpEslList.map((item: any) => {
          item.nstlQntt = item.nstlQntt || '0';
          item.lostQntt = item.lostQntt || '0';
          item.rtrnDfctQntt = item.rtrnDfctQntt || '0';
          item.rtrnNrmlQntt = item.rtrnNrmlQntt || '0';

          if (item.nstlQntt === '0') {
            chkEmpty = true;
          }
        });

        setDetailResultData((pre: any) => ({
          ...pre,
          eslList: tmpEslList,
        }));

        Dialog.confirm({
          title: chkEmpty ? '저장하시겠습니까?' : '',
          content: chkEmpty
            ? 'ESL 설치 품목 중, 설치수량이 미입력된 품목이 있습니다.'
            : '저장하시겠습니까?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: () => {
            nstlApi
              .putNstlWorkEsl({
                dcmnDntfNmbr: dcmnDntfNmbr,
                installWorkEslParamList: detailResultData.eslList,
                nstlSttsCode: workStatusActionSheet.value,
                splrId: detailData.splrId,
                teamId: detailData.teamId,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  Toast.show({
                    position: 'bottom',
                    content: '저장하였습니다.',
                  });

                  // 부모창으로 결과를 넘김
                  callback({
                    resultType: 'refresh',
                  });

                  // 삭제할 팝업 저장소에 적용함
                  setRemovePopup(id);
                } else {
                  Toast.show({
                    position: 'bottom',
                    content: '저장에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                Toast.show({
                  position: 'bottom',
                  content: '저장에 실패하였습니다.',
                });
              });
          },
        });
        break;

      default:
        break;
    }
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 작업상태 액션시트를 초기화함
    initWorkStatusActionSheet();

    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      switch (detailData.splrTypeCode) {
        // 네트워크 공사 작업 내역
        case 'NTWR_CNST':
          // 수정을 위해 네트워크 설치 목록에 id를 추가함
          data.ntwrList.map((item: any) => {
            item['id'] = nanoid();
          });
          break;

        // ESL 설치 작업 내역
        case 'ESL_NSTL':
          // 수정을 위해 ESL 설치 목록에 id를 추가함
          data.eslList.map((item: any) => {
            item['id'] = nanoid();
          });
          break;

        default:
          break;
      }

      // 상세 내용에 적용함
      setDetailResultData(data);

      // 새로 변경할 작업상태 액션시트에 적용함
      setWorkStatusActionSheet((pre: IActionSheet) => ({
        ...pre,
        value: data.nstlSttsCode,
      }));
    });

    return () => {};
  }, []);

  return (
    <div className="">
      {detailResultData !== null && (
        <div className="">
          <div className="p-3">
            {/* 버튼 */}
            <Button
              size="large"
              shape="default"
              onClick={handleSaveButton_onClick}
              className="w-full !bg-indigo-700"
            >
              <span className="text-md text-white">작업결과 저장</span>
            </Button>
          </div>

          {/* 작업상태 리스트 */}
          <List header="작업상태 변경" className="bg-gray-100/90">
            <List.Item title="버튼을 터치하여 작업상태를 변경 후, 작업결과를 저장해야 합니다.">
              {/* 버튼 */}
              <Button
                size="middle"
                fill="outline"
                onClick={() => {
                  actionSheetHandlerRef.current = ActionSheet.show({
                    actions: workStatusActionSheet.item,
                    onAction: (action: Action) => {
                      // 작업상태 액션시트에 적용함
                      setWorkStatusActionSheet((pre: IActionSheet) => ({
                        ...pre,
                        value: action.key.toString(),
                      }));

                      // 액션시트를 닫음
                      actionSheetHandlerRef.current?.close();
                    },
                  });
                }}
                className="w-full !border-indigo-200"
              >
                <span className="text-md text-indigo-500">
                  {getActionSheetLabel(workStatusActionSheet) || '선택하세요'}
                </span>
              </Button>
            </List.Item>
          </List>

          {/* 프로젝트 정보 리스트 */}
          <List header="프로젝트 정보" className="bg-gray-100/90">
            <List.Item title="고객사명">
              {displayValue(detailResultData.bpName)}
            </List.Item>
            <List.Item title="Shop명">
              {displayValue(detailResultData.shipToCode)}
            </List.Item>
            <List.Item title="주문번호">
              {displayValue(detailResultData.dcmnDntfNmbr)}
            </List.Item>
          </List>

          {/* 작업 정보 리스트 */}
          <List header="작업 정보" className="bg-gray-100/90">
            <List.Item title="담당협력사">
              {displayValue(detailResultData.prtnName)}
            </List.Item>
            <List.Item title="작업일정">
              {`${displayValue(detailResultData.workStrtDttm)} ~ ${displayValue(
                detailResultData.workEndDttm,
              )}`}
            </List.Item>
            <List.Item title="작업상태">
              {displayValue(detailResultData.nstlSttsName)}
            </List.Item>
          </List>

          {/* 소프트웨어 정보 리스트 */}
          {detailData.splrTypeCode === 'NTWR_CNST' && (
            <List header="소프트웨어 정보" className="bg-gray-100/90">
              <Collapse accordion={false}>
                {detailResultData.ntwrList.map((item: any, index: number) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={
                      <div className="flex justify-between items-center space-x-2">
                        <Ellipsis
                          direction="end"
                          content={displayValue(item.ntwrWorkName)}
                        />
                      </div>
                    }
                  >
                    <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                      <List.Item
                        title="구분"
                        onClick={() => handleNetworkList_onClick(item)}
                        clickable
                        extra="수정"
                        className="!bg-gray-100/90"
                      >
                        {displayValue(item.ntwrWorkName)}
                      </List.Item>
                      <List.Item title="내용" className="!bg-gray-100/90">
                        {displayValue(item.ttl)}
                      </List.Item>
                    </List>
                  </Collapse.Panel>
                ))}
              </Collapse>

              <div className="p-3">
                {/* 버튼 */}
                <Button
                  size="large"
                  shape="default"
                  onClick={handleAddSoftwareButton_onClick}
                  className="w-full !bg-black"
                >
                  <span className="text-md text-white">
                    소프트웨어 정보 추가
                  </span>
                </Button>
              </div>
            </List>
          )}

          {/* ESL 설치 등록 리스트 */}
          {detailData.splrTypeCode === 'ESL_NSTL' && (
            <>
              <List header="ESL 설치 등록" className="bg-gray-100/90">
                <Collapse accordion={false}>
                  {detailResultData.eslList.map((item: any, index: number) => (
                    <Collapse.Panel
                      key={index.toString()}
                      title={
                        <div className="flex justify-between items-center space-x-2">
                          <Ellipsis
                            direction="end"
                            content={decode(displayValue(item.itemDtls))}
                          />
                        </div>
                      }
                    >
                      <List className="bg-gray-100/90 text-gray-600 border-r border-l">
                        <List.Item
                          title="품목번호"
                          onClick={() => handleEslList_onClick(item)}
                          clickable
                          extra="품목선택"
                          className="!bg-gray-100/90"
                        >
                          {displayValue(item.itemNmbr)}
                        </List.Item>
                        <List.Item title="품목명" className="!bg-gray-100/90">
                          {decode(displayValue(item.itemDtls))}
                        </List.Item>
                        <List.Item title="계획수량" className="!bg-gray-100/90">
                          {displayValue(item.qntt)}
                        </List.Item>
                        <List.Item
                          title="설치수량"
                          onClick={() => handleEslListAmount_onClick(item)}
                          clickable
                          extra="수량수정"
                          className="!bg-gray-100/90"
                        >
                          {displayValue(item.nstlQntt)}
                        </List.Item>
                        <List.Item title="분실수량" className="!bg-gray-100/90">
                          {displayValue(item.lostQntt)}
                        </List.Item>
                        <List.Item
                          title="반납예정수량(일반 / 불량)"
                          className="!bg-gray-100/90"
                        >
                          <div className="flex justify-start items-center space-x-2">
                            <span>{displayValue(item.rtrnNrmlQntt)}</span>
                            <span className="text-xs text-gray-400">/</span>
                            <span>{displayValue(item.rtrnDfctQntt)}</span>
                          </div>
                        </List.Item>
                      </List>
                    </Collapse.Panel>
                  ))}
                </Collapse>

                <div className="p-3 hidden">
                  {/* 버튼 */}
                  <Button
                    size="large"
                    shape="default"
                    onClick={handleAddProductButton_onClick}
                    className="w-full !bg-black"
                  >
                    <span className="text-md text-white">품목 추가</span>
                  </Button>
                </div>
              </List>

              <List header="재고 이동" className="bg-gray-100/90">
                <div className="p-3">
                  {/* 버튼 */}
                  <Button
                    size="large"
                    shape="default"
                    onClick={handleMoveProductButton_onClick}
                    className="w-full !bg-indigo-700"
                  >
                    <span className="text-md text-white">재고 이동 요청</span>
                  </Button>
                </div>
              </List>
            </>
          )}

          {/* 댓글 리스트 */}
          <List header="댓글" className="bg-gray-100/90">
            <List.Item title="버튼을 터치하여 댓글 목록을 조회합니다.">
              {/* 버튼 */}
              <Button
                size="large"
                shape="default"
                onClick={handleCommentButton_onClick}
                className="w-full !bg-black"
              >
                <span className="text-md text-white">댓글</span>
              </Button>
            </List.Item>
          </List>
        </div>
      )}
    </div>
  );
};

export default OngoingJobManagementDetailResult;
