import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IApiResult } from '../../interfaces/app.interface';
import * as sttnApi from '../../apis/sttn.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { List } from 'antd-mobile';

interface IPopupProps {
  id: string;
  onClick?: () => void;
}

/**
 * 현황 관리 > 재고 이동 요청 이력 > 조회
 * @param id <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const StockRequestHistoryDetail = ({
  id,
  onClick,
}: PropsWithChildren<IPopupProps>) => {
  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 항목의 값이 없을 때, 빈 값을 반환함
  const displayValue = (value: any): any => {
    return value ? value : <span className="text-gray-300">정보 없음</span>;
  };

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    sttnApi
      .getSttnNvntDetail({
        docEntry: id,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          callback(data.data);
        }
      });
  };

  /**
   * 이벤트
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 상세 내용에 적용함
      setDetailData(data);
    });

    return () => {};
  }, []);

  return (
    <div className="">
      {detailData !== null && (
        <div className="">
          {/* 정보 리스트 */}
          <List header="정보" className="bg-gray-100/90">
            <List.Item title="프로젝트명">
              {displayValue(detailData.prjcName)}
            </List.Item>
            <List.Item title="고객사명">
              {displayValue(detailData.bpName)}
            </List.Item>
            <List.Item title="Shop명">
              {displayValue(detailData.shipCode)}
            </List.Item>
            <List.Item title="협력사명">
              {displayValue(detailData.prtnName)}
            </List.Item>
            <List.Item title="요청일">
              {displayValue(detailData.rgstDate)}
            </List.Item>
          </List>
        </div>
      )}
    </div>
  );
};

export default StockRequestHistoryDetail;
