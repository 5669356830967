import { atom } from 'recoil';
import { IOverlay } from '../interfaces/app.interface';

/**
 * 화면 위 검은 화면
 */
export const overlayStore = atom({
  key: 'overlayStore',
  default: {
    visible: false,
    useLightMode: false,
    content: '',
  } as IOverlay,
});
