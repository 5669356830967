import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, Form, Input } from 'antd-mobile';
import * as appUtil from '../../utils/app.util';
import { useNavigate } from 'react-router-dom';
import {
  IApiResult,
  IComponentModal,
  ILoginUser,
  IMessageModal,
  IModal,
  IPopup,
  IToken,
} from '../../interfaces/app.interface';
import * as cmnApi from '../../apis/cmn.api';
import { useRecoilState } from 'recoil';
import { loginStore } from '../../stores/login.store';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { FindId } from '../../components/FindId';
import { FindPassword } from '../FindPassword';
import { ChangeTmpPassword } from '../ChangeTmpPassword';
import { modals } from '@mantine/modals';
import ModalManager from '../../managers/ModalManager/ModalManager.manager';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { nanoid } from 'nanoid';
import { addComponentModalStore } from '../../stores/componentModal.store';
import { ComponentModalManager } from '../../managers/ComponentModalManager';
import { PopupManager } from '../../managers/PopupManager';
import { addPopupStore } from '../../stores/popup.store';
import { Action } from 'antd-mobile/es/components/dialog';

interface ILoginProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 로그인
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Login = ({ data, onClick }: PropsWithChildren<ILoginProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 언어를 정의함
  const { t } = useTranslation();

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 사용자 로그인 정보를 정의함
  const [userId, setUserId] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');

  // 아이디 저장 체크박스를 정의함
  const [saveIdCheckBox, setSaveIdCheckBox] = useState<boolean>(false);

  // 다이얼로그가 출력되면 화면 입력을 방지함을 정의함
  const [showDialog, setShowDialog] = useState<boolean>(false);

  // 로그인 버튼을 클릭함
  const handleLoginButton_onClick = () => {
    if (showDialog === true) {
      return;
    }

    // 로그인을 처리함
    cmnApi
      .postLogin({
        lgnId: userId,
        userPw: userPassword,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 로그인 성공
          case '200':
            // 로그인한 사용자의 토큰을 정의함
            let tmpToken: IToken = {
              email: userId,
              userId: data.data.userId,
              tokenType: data.data.tokenType,
              accessToken: data.data.accessToken,
              accessTokenExpireSecond: data.data.accessTokenExpireSecond,
              refreshToken: data.data.refreshToken,
              refreshTokenExpireSecond: data.data.refreshTokenExpireSecond,
            };

            (async () => {
              // 로그인 아이디를 Local Storage에 저장함
              if (saveIdCheckBox) {
                await appUtil.setSaveId(userId);
              } else {
                await appUtil.setSaveId('');
              }

              // 로그인한 사용자 토큰을 Local Storage에 저장함
              await appUtil.setToken(tmpToken);

              if (data.data.tmprPswrUseYn === 'Y') {
                checkTmpLoginInfo();
              } else {
                // 메인 페이지로 이동함
                // navigate('/');
                window.location.href = '/';
              }
            })();
            break;

          // 사용중지(use_yn=N) 아이디
          case '202':
            // setModal({
            //   title: '알림',
            //   content: data.message,
            // });

            // 다이얼로그가 출력되면 화면 입력을 방지함
            setShowDialog(true);

            // 다이얼로그를 출력함
            Dialog.show({
              content: '사용이 중지된 아이디입니다.',
              closeOnMaskClick: true,
              closeOnAction: true,
              actions: [
                {
                  key: 'okay',
                  text: '확인',
                },
              ],
              onAction: (action: Action) => {
                if (action.key === 'okay') {
                  // 다이얼로그가 출력되면 화면 입력을 방지함을 해제함
                  setShowDialog(false);
                }
              },
            });
            break;

          // 아이디 또는 비밀번호 오류
          case '500':
            // 다이얼로그가 출력되면 화면 입력을 방지함
            setShowDialog(true);

            // 다이얼로그를 출력함
            Dialog.show({
              content: '아이디 또는 비밀번호가 올바르지 않습니다.',
              closeOnMaskClick: true,
              closeOnAction: true,
              actions: [
                {
                  key: 'okay',
                  text: '확인',
                },
              ],
              onAction: (action: Action) => {
                if (action.key === 'okay') {
                  // 다이얼로그가 출력되면 화면 입력을 방지함을 해제함
                  setShowDialog(false);
                }
              },
            });
            break;

          default:
            break;
        }
      });
  };

  // 비밀번호 찾기 버튼을 클릭함
  const handleFindPasswordButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '비밀번호 초기화',
      content: <FindPassword id={tmpId} />,
      widthSizePercent: 100,
      heightSizePercent: 50,
      position: 'bottom',
      maskClick: true,
    });

    // 메시지 모달을 출력함
    {
      /* 언어: 비밀번호 찾기 */
      /* 언어: 아이디 또는 비밀번호가 올바르지 않습니다. */
    }
    // setComponentModal({
    //   title: t('auth.findPassword') || '',
    //   content: <FindPassword />,
    //   size: 'sm',
    //   button: (
    //     <>
    //       {/* 언어: 취소 */}
    //       <Button onClick={closeComponentModal} color="gray" size="sm">
    //         {t('common.cancel')}
    //       </Button>
    //     </>
    //   ),
    // });
    // openComponentModal();
  };

  // 비밀번호 재설정
  const checkTmpLoginInfo = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '비밀번호 재설정',
      content: (
        <ChangeTmpPassword
          id={tmpId}
          callback={(data: any) => {
            // 메인 페이지로 이동함
            // navigate('/');
            window.location.href = '/';
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 60,
      position: 'bottom',
      maskClick: true,
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 로그인 아이디를 Local Storage에서 불러옴
    let saveId: string = appUtil.getSaveId() || '';
    setSaveIdCheckBox(saveId ? true : false);
    setUserId(saveId);

    return () => {};
  }, []);

  // 아이디 입력의 값을 변경함
  const handleUserIdInput_onChange = (event: any) => {
    setUserId(event);
  };

  // 비밀번호 입력의 값을 변경함
  const handleUserPasswordInput_onChange = (event: any) => {
    setUserPassword(event);
  };

  // 비밀번호 입력에서 엔터키를 입력함
  const handleUserPasswordInput_onEnterPress = (event: any) => {
    handleLoginButton_onClick();
  };

  // 아이디 저장 체크박스를 변경함
  const handleSaveUserIdCheckbox_onChange = (event: any) => {
    // 체크박스에 적용함
    setSaveIdCheckBox(event);
  };

  return (
    <>
      {/* 팝업 매니저 */}
      <PopupManager />

      {/* 로그인 */}
      <div className="space-y-5">
        <div className="space-y-2">
          <Form.Item label="아이디 또는 이메일주소">
            <div className="flex justify-center items-center space-x-2">
              {/* 텍스트 입력 */}
              <Input
                placeholder="아이디를 입력하세요."
                clearable={true}
                value={userId}
                onChange={handleUserIdInput_onChange}
                className="grow ant-m-input"
              />
            </div>
          </Form.Item>

          <Form.Item label="비밀번호">
            <div className="flex justify-center items-center space-x-2">
              {/* 텍스트 입력 */}
              <Input
                type="password"
                placeholder="비밀번호를 입력하세요."
                clearable={true}
                value={userPassword}
                onChange={handleUserPasswordInput_onChange}
                onEnterPress={handleUserPasswordInput_onEnterPress}
                className="grow ant-m-input"
              />
            </div>
          </Form.Item>
        </div>

        <div className="space-y-5">
          {/* 버튼 */}
          <div className="w-full flex justify-center items-center">
            {/* 버튼 */}
            <Button
              size="large"
              shape="default"
              onClick={handleLoginButton_onClick}
              className="w-full !bg-indigo-700"
            >
              <span className="text-md text-white">로그인</span>
            </Button>
          </div>

          {/* 기타 링크 */}
          <div className="flex justify-between items-center">
            {/* 체크박스 */}
            <Checkbox
              onChange={handleSaveUserIdCheckbox_onChange}
              checked={saveIdCheckBox}
              className="select-none"
            >
              아이디 저장
            </Checkbox>

            {/* 버튼 */}
            <Button
              size="small"
              shape="default"
              onClick={handleFindPasswordButton_onClick}
              className="!bg-gray-100"
            >
              <span className="text-gray-800">비밀번호 초기화</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
